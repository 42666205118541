export const ASSETS_CONSTANTS = [
  {
    key: 'baseImage',
    title: 'Base Image',
    parent: 'assets',
    color: '#FFD700',
  },
  {
    key: 'colorImage',
    title: 'Color Image',
    parent: 'assets',
    color: '#E9967A',
  },
  {
    key: 'poseImage',
    parent: 'assets',
    title: 'Pose Image',
    color: '#34b58a',
  },
  {
    key: 'structureImage',
    parent: 'assets',
    title: 'Structure Image',
    color: '#3461b5',
  },
  {
    key: 'softedgeImage',
    parent: 'assets',
    title: 'Sketch Image',
    color: '#b53481',
  },
];

export const ACTION_CONSTANTS = [
  {
    key: 'baseImage',
    title: 'Base',
    parent: 'assets',
    description: 'Guide the model to follow the shapes present in an image.',
    image: '/images/dashboard/settings/base.png',
    color: '#FFD700',
  },
  {
    key: 'colorImage',
    title: 'Color',
    parent: 'assets',
    description: 'Guide the model to follow the color palette of an image.',
    image: '/images/dashboard/settings/color.png',
    color: '#E9967A',
  },
  {
    key: 'poseImage',
    parent: 'assets',
    description: 'Guide the model to follow the pose of a subject.',
    image: '/images/dashboard/settings/pose.png',
    title: 'Pose',
    color: '#34b58a',
  },
  {
    key: 'structureImage',
    parent: 'assets',
    title: 'Structure',
    description:
      'Guide the model to interpret the structure or depth of an image.',
    image: '/images/dashboard/settings/structure.png',
    color: '#3461b5',
  },
  {
    key: 'softedgeImage',
    parent: 'assets',
    description: 'Guide the model to follow a sketch or linework.',
    title: 'Sketch',
    image: '/images/dashboard/settings/sketch.png',
    color: '#b53481',
  },
];

export const aspectRatio = [
  '1152x576',
  '1024x576',
  '864x576',
  '768x576',
  '576x576',
  '576x768',
  '576x860',
  '576x1048',
  '576x1152',
];

export const aspectRatioMap = {
  '1152x576': 'Landscape - 2:1',
  '1024x576': 'Landscape - 16:9',
  '864x576': 'Landscape - 3:2',
  '768x576': 'Landscape - 4:3',
  '576x576': 'Square - 1:1',
  '576x768': 'Portrait - 3:4',
  '576x860': 'Portrait - 9:16',
  '576x1048': 'Portrait - 4:3',
  '576x1152': 'Portrait - 2:1',
};

export const aspectMap = {
  '1152x576': '2:1',
  '1024x576': '16:9',
  '864x576': '3:2',
  '768x576': '4:3',
  '576x576': '1:1',
  '576x768': '3:4',
  '576x860': '9:16',
  '576x1048': '4:3',
  '576x1152': '2:1',
};

export const randomPrompt = [
  'a cinematic photograph of a futuristic cybernetic robot sitting at a table, in the style of cyberpunk, dslr, photorealistic, 4k',

  'a cinematic macro shot of a dog in an astronaut suit floating in the sky above a busy city, photorealistic, 4k',

  'shot of a cartoon hero action shot, looking at the camera with her pet dragon. aesthetic , low poly style',

  'mythological landscape , water color, painting, art, a world filled with floating islands and impossible physics. a humanoid character stands in the middle, a part organic, part cybernetic, mythological grandeur with cybernetic enhancement creates a visually intense and dynamic scene',

  'A man in a rain-soaked neon-lit cityscape, Blade Runner 2049 aesthetic, holographic billboards, cinematic lighting, extreme close-up',

  'mythical creature, photoshoot, 4k, render porcelain',

  'a cinematic photograph of an aesthetic model posing for a picture at a photoshoot, photorealistic, 4k',

  'a cinematic photograph of a boat on the water, digital illustration, 4k, photorealistic',

  'a cinematic wide landscape shot of a large kaiju bear scary, mysterious, menacing, piercing red eyes , looking at eachother , zoomed out, aesthetic, dslr, 4k photorealistic',

  'a beautiful woman silhouette dissolving into iridescent, abstract expressionist style, vibrant opal hues, ethereal lighting, inspired by Odilon Redon',
];
