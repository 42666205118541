import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CallToAction,
  Empty,
  Field,
  Flex,
  Img,
  Popover,
  Spacer,
  Text,
  capitalize,
  cleanText,
  Switch,
  getDataIcons,
  Tooltip,
} from '@nex/labs';

import dynamic from 'next/dynamic';
import Router from 'next/router';
import classNames from 'classnames';

import AngleIcon from '@nex/icons/svg/blocks/sketch/angle.svg';
import BendIcon from '@nex/icons/svg/blocks/sketch/bend.svg';
import StrokeIcon from '@nex/icons/svg/blocks/sketch/lines.svg';
import PromptIcon from '@nex/icons/svg/blocks/prompt.svg';

import { useArtboardStore } from '@/state/useStore';
import { Accordion } from '@/components/accordion';

import { modifyShape } from '../../lib/shapes';
import { WeightSlider } from '../../../misc/weight-slider';
import { ASSETS_CONSTANTS } from '../../../../utils/constants';
import { AspectSettings } from '../../../../views/prompt';
import { useArtboardGenerate } from '../../../../hooks/useArtboardGenerate';

import { useFabric } from '@/components/layout/views/artboard-layout/components/fabric-provider';
import { HasWrongCanvasSize } from '../misc/has-wrong-canvas';

import styles from './sketch-settings.module.scss';

const PREVIEW_VIEWS = {
  prompt: (block: any) => (
    <Flex.Column gap={8}>
      <Flex.Row gap={8} alignItems="center">
        <Flex
          gap={8}
          alignItems="center"
          className="border-[1px] border-solid border-[var(--border-light)] rounded-sm h-[24px] w-[24px]"
        >
          <PromptIcon className="w-[14px] h-[14px] m-auto" />
        </Flex>

        <Flex.Column gap={1}>
          <Text weight={700} casing="capitalize" className="opacity-70">
            {cleanText(block.meta)}
          </Text>
        </Flex.Column>
      </Flex.Row>
    </Flex.Column>
  ),

  assets: (block: any) => (
    <Flex.Row gap={8}>
      <Img
        src={block.data?.buffer ?? block.data.src}
        alt={block.data.name}
        className="rounded-md w-[24px] h-[24px] object-cover"
      />

      <Flex.Column gap={1}>
        <Text weight={700} casing="capitalize" className="opacity-70">
          {capitalize(
            cleanText(
              block.data?.subMetaInfo?.title || block.data.name || block?.meta
            )
          )}
        </Text>
      </Flex.Column>
    </Flex.Row>
  ),
};

export const SketchSettings = () => {
  const {
    elementAttributes,
    setElementAttributes,
    setSketch,
    sketch,
    blockInView,
    setBlock,
    isRealTime,
  } = useArtboardStore();

  const { fabric, syncShapeInStorage, activeObjectRef } = useFabric();
  const [isOpen, setIsOpen] = useState(false);
  const handleInputChange = (property: string, value: string) => {
    if (!sketch.isEditing) setSketch({ isEditing: true });

    setElementAttributes({ [property]: value });

    modifyShape({
      canvas: fabric.current!,
      property,
      value,
      activeObjectRef: activeObjectRef,
      syncShapeInStorage,
    });
  };

  const getSketchBlock = useMemo(() => {
    return blockInView?.find(
      (block: any) =>
        block.meta === 'sketch' || block?.data?.subMetaInfo?.useAs === 'sketch'
    );
  }, [blockInView]);

  useEffect(() => {
    document.body.style.setProperty(
      '--preview-width',
      `${isOpen ? '220px' : '200px'}`
    );
  }, [isOpen]);

  return (
    <Flex.Column
      gap={18}
      xl={{
        display: isOpen ? 'flex' : 'none',
      }}
      className={classNames([styles.SketchSettings])}
    >
      <Flex.Column gap={2}>
        <Text weight={700}>Sketch Settings</Text>
        <Text className="opacity-80 -mb-[12px]" fontSize="12px">
          Make Changes to the canvas
        </Text>
      </Flex.Column>

      <Spacer divider size={2} />
      <HasWrongCanvasSize />

      <>
        <Flex.Column gap={8}>
          <Flex gap={8}>
            <Field
              placeholder="0"
              disabled={!sketch.activeObject}
              type="number"
              leadingIcon={<Text>X</Text>}
              value={elementAttributes.top}
              onChange={(e: any) => handleInputChange('top', e.target.value)}
            />
            <Field
              placeholder="0"
              disabled={!sketch.activeObject}
              type="number"
              leadingIcon={<Text>Y</Text>}
              value={elementAttributes.left}
              onChange={(e: any) => handleInputChange('left', e.target.value)}
            />
          </Flex>
          <Flex gap={8}>
            <Field
              placeholder="0"
              disabled={!sketch.activeObject}
              type="number"
              leadingIcon={<Text>W</Text>}
              value={elementAttributes.width}
              onChange={(e: any) => handleInputChange('width', e.target.value)}
            />
            <Field
              placeholder="0"
              disabled={!sketch.activeObject}
              type="number"
              leadingIcon={<Text>H</Text>}
              value={elementAttributes.height}
              onChange={(e: any) => handleInputChange('height', e.target.value)}
            />
          </Flex>
          <Flex gap={8}>
            <Field
              placeholder="0"
              disabled={!sketch.activeObject}
              type="number"
              leadingIcon={<AngleIcon width="16" height="16" />}
              value={elementAttributes.angle}
              onChange={(e: any) => handleInputChange('angle', e.target.value)}
            />

            <Field
              placeholder="0"
              disabled={!sketch.activeObject}
              type="number"
              leadingIcon={<BendIcon width="16" height="16" />}
              value={
                isNaN(+elementAttributes.rx) ? 'Mixed' : elementAttributes.ry
              }
              onChange={(e: any) => {
                handleInputChange('rx', e.target.value);
                handleInputChange('ry', e.target.value);
              }}
            />
          </Flex>
        </Flex.Column>{' '}
        {sketch?.activeObject?.type !== 'image' && (
          <>
            {' '}
            <Spacer divider size={2} />
            <Flex.Column gap={8}>
              <Text weight={700}>Fill</Text>
              <Flex.Row
                gap={18}
                fullWidth
                justifyContent="space-between"
                alignItems="center"
              >
                <ColorPicker
                  value={elementAttributes.fill}
                  label
                  disabled={!sketch?.activeObject}
                  onChange={(e: any) =>
                    handleInputChange('fill', e.target.value)
                  }
                />
              </Flex.Row>
            </Flex.Column>
          </>
        )}
        <Spacer divider size={2} />
        <Flex.Column gap={4}>
          <Flex.Row gap={8} alignItems="center" justifyContent="space-between">
            <Text weight={700}>Stroke</Text>
            <Switch
              color="green"
              disabled={!sketch.activeObject}
              checked={elementAttributes.strokeWidth !== '0'}
              value={elementAttributes.strokeWidth !== '0' ? '1' : '0'}
              onClick={() => {
                if (!sketch.activeObject) return;
                if (+elementAttributes.strokeWidth > 0) {
                  handleInputChange('strokeWidth', '0');
                  return;
                }

                if (elementAttributes.strokeWidth === '0') {
                  handleInputChange('strokeWidth', '1');
                  handleInputChange('stroke', '#000');
                }
              }}
            />
          </Flex.Row>
          {elementAttributes.strokeWidth !== '0' && (
            <>
              <Flex gap={8} alignItems="center">
                <Field
                  placeholder="0"
                  type="number"
                  min={0}
                  disabled={!sketch.activeObject}
                  leadingIcon={<StrokeIcon />}
                  value={elementAttributes.strokeWidth}
                  onChange={(e: any) =>
                    handleInputChange('strokeWidth', e.target.value)
                  }
                />
              </Flex>
              <ColorPicker
                disabled={!sketch.activeObject}
                value={elementAttributes.stroke}
                label
                onChange={(e: any) =>
                  handleInputChange('stroke', e.target.value)
                }
              />
            </>
          )}
        </Flex.Column>
        <Spacer divider size={2} />
      </>

      {getSketchBlock && (
        <>
          <Flex.Column gap={2}>
            <Text weight={700}>Sketch Intensity</Text>
            <Text weight={600} fontSize="11px" className="opacity-70 mb-3">
              How much should this sketch affect the output
            </Text>

            <WeightSlider block={getSketchBlock} noLabel setBlock={setBlock} />
          </Flex.Column>
          <Spacer divider size={2} />
        </>
      )}

      {isRealTime && (
        <>
          <Flex.Column gap={8}>
            <Accordion title="Export" size="sm" bare>
              <Flex.Row gap={14} alignItems="center">
                <CallToAction.button
                  size="xs"
                  variant="secondary"
                  onClick={() => {
                    const dataURL = fabric.current?.toDataURL();
                    const a = document.createElement('a');
                    a.href = dataURL!;
                    a.download = 'sketch.png';
                    a.click();
                  }}
                >
                  <Text weight={600} fontSize="12px">
                    Download
                  </Text>
                </CallToAction.button>
                <CallToAction.a size="xs" href="/enhance?image=sketch">
                  <Text weight={600} fontSize="12px">
                    Enhance Image
                  </Text>
                </CallToAction.a>
                {/* <CallToAction.button size="xs">
                  <Text weight={600} fontSize="12px">
                    Save as Assets
                  </Text>
                </CallToAction.button> */}
              </Flex.Row>
            </Accordion>
          </Flex.Column>
          {/* <Spacer divider size={2} /> */}
          <Spacer divider size={2} />
        </>
      )}
    </Flex.Column>
  );
};

const Colorful = dynamic(
  () => import('@uiw/react-color').then((mod) => mod.Colorful),
  {
    ssr: false,
  }
);

const ColorPicker = ({
  value,
  onChange,
  label,
  className,
  disabled,
}: {
  value: string;
  onChange: (e: any) => void;
  label?: boolean;
  disabled?: boolean;
  className?: string;
}) => {
  return (
    <div className={classNames([styles.ColorPicker, className])}>
      <Flex gap={4} alignItems="center">
        <Popover hug position="bottom">
          <Popover.Trigger>
            <button
              style={{ backgroundColor: value || '#111111FF' }}
              disabled={disabled}
              className={label ? 'max-w-[20px]' : ''}
            ></button>
          </Popover.Trigger>
          <Popover.Content>
            <Flex.Column gap={12} className="p-4">
              <Colorful
                color={value || '#111111FF'}
                onChange={(color: any) => {
                  onChange({ target: { value: color.hexa } });
                }}
              />
              <Field
                disabled={disabled}
                label="Color"
                value={
                  (typeof value === 'string' &&
                    (value?.toUpperCase() || '#111111FF')) ||
                  ''
                }
                onChange={onChange}
                className="w-full"
                type="text"
                placeholder="Color"
              ></Field>
            </Flex.Column>
          </Popover.Content>
        </Popover>
        {label && (
          <Field
            disabled={disabled}
            value={
              (typeof value === 'string' && (value?.toUpperCase() || '#000')) ||
              ''
            }
            onChange={onChange}
            className="w-full"
            type="text"
            placeholder="Color"
          ></Field>
        )}
      </Flex>
    </div>
  );
};
