import type React from 'react';
import { useCallback } from 'react';
import { CallToAction, useFeedback, Text } from '@nex/labs';
import type { OrganizationInvitationResource } from '@clerk/types';
import { SelectRole } from '../../../components/select-role';

type InvitationProps = {
  invitation: OrganizationInvitationResource;
  onUpdateInvite: () => Promise<void>;
};

export const Invitation = ({ invitation, onUpdateInvite }: InvitationProps) => {
  const { createToast } = useFeedback();
  const { id, role, revoke } = invitation;

  const handleRevoke = useCallback(async () => {
    try {
      await revoke();
      await onUpdateInvite();

      createToast({
        variant: 'primary',
        message: 'Invitation has been revoked',
      });
    } catch (error) {
      console.error(error);
      createToast({
        variant: 'error',
        message: 'Failed to revoke invitation',
      });
    }
  }, [createToast, onUpdateInvite, revoke]);

  return (
    <tr className="border-b border-gray-700" key={id}>
      <td className="px-6 py-4 font-medium  whitespace-nowrap opacity-80">
        <Text>{invitation.emailAddress}</Text>
      </td>
      <td className="px-6 py-4 opacity-80">
        <SelectRole disabled defaultRole={role} />
      </td>
      <td className="px-6 py-4 opacity-80 flex justify-end">
        <CallToAction.button size="xs" variant="error" onClick={handleRevoke}>
          Revoke
        </CallToAction.button>
      </td>
    </tr>
  );
};
