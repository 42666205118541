import React, { use, useCallback, useMemo } from 'react';
import {
  CallToAction,
  Flex,
  Heading,
  humanReadableSize,
  Img,
  Text,
  getDataIcons,
  useFeedback,
  Empty,
} from '@nex/labs';

import {
  useGetUploadURLMutation,
  useUploadAssetMutation,
  useSaveAssetsMutation,
} from '@/state/query/block';
import { useArtboardStore, useGlobalStore } from '@/state/useStore';
import { useFileUpload } from '@/components/upload/useUpload';
import { usePostHog } from 'posthog-js/react';
import styles from './modals.module.scss';
import { ASSETS_CONSTANTS } from '../../utils/constants';

export const AssetsModal = () => {
  const { createDisclosure, createToast } = useFeedback();
  const { setAssets, assets, closeModal, modal } = useGlobalStore();
  const { setBlock } = useArtboardStore();
  const uploadOptions = useMemo(() => (modal?.props as any).options, [modal]);
  const { mutateAsync: createPresignedURL, isLoading: isPreparing } =
    useGetUploadURLMutation();
  const { onFileUpload } = useFileUpload();
  const posthog = usePostHog();
  const { mutateAsync: uploadAsset, isLoading: isUploading } =
    useUploadAssetMutation();
  const { mutateAsync: saveAssets, isLoading: isSaving } =
    useSaveAssetsMutation({
      onError() {
        createToast({
          message: 'Failed to upload assets. Please try again later',
          variant: 'error',
        });
      },
      onSuccess() {
        setAssets?.([]);
        closeModal?.();
      },
    });

  const handleUploadAssets = useCallback(async () => {
    let totalSize = 0;

    const _assets = await Promise.allSettled(
      Object.values(assets).map(async (asset) => {
        const { fields, url, ...rest } = await createPresignedURL(
          asset.file.type
        );

        await uploadAsset({
          data: createUploadFormData(asset.file, fields),
          url,
        });

        totalSize += asset.file.size;

        return {
          key: fields.key,
          type: asset.file.type,
        };
      })
    );

    const uploadedAssets = _assets
      .map((asset) => asset.status === 'fulfilled' && asset.value)
      .filter(Boolean);

    if (!uploadedAssets.length) {
      return createToast({
        message: 'Failed to upload assets. Please try again later',
        variant: 'error',
      });
    }

    const res = await saveAssets({
      assets: uploadedAssets,
    });

    if (uploadOptions?.autoAdd) {
      const imageURL = res?.assets[0]?.images[0]?.url;
      const imageKey = res?.assets[0]?.images[0]?.key;
      const block = uploadOptions?.block;

      setBlock('assets', {
        src: imageURL,
        key: imageKey,
        name: block,
        subMetaInfo: ASSETS_CONSTANTS.find((item) => item.key === block),
        weight: 0.5,
        subMeta: block,
      });
    }

    posthog.capture('asset_uploaded', {
      count: uploadedAssets.length,
      size: humanReadableSize(totalSize),
    });
  }, [
    assets,
    createPresignedURL,
    uploadAsset,
    saveAssets,
    createToast,
    uploadOptions,
  ]);

  const isLoading = isPreparing || isUploading || isSaving;

  return (
    <>
      <Flex.Column gap={0} className="mb-6">
        <Heading.h4 weight={600}>Your asset library</Heading.h4>
        <Flex.Row gap={8} alignItems="center" justifyContent="space-between">
          <Text className="opacity-70">
            Upload up to 10mb PNG/JPEG images to your asset library
          </Text>
          <CallToAction.input
            size="sm"
            variant="secondary"
            className="w-full"
            defaultBehavior={false}
            multiple
            disabled={isLoading}
            onFileUpload={onFileUpload}
            leadingIcon={
              <img
                src={getDataIcons('add', '#000')}
                className="w-[12px] h-[12px]"
              />
            }
          >
            Choose Another File
          </CallToAction.input>
        </Flex.Row>
      </Flex.Column>
      <Flex.Row gap={12}>
        {Object.keys(assets).length ? (
          Object.keys(assets).map((key, i) => (
            <div key={i} className={styles.AssetsCard}>
              <Img src={assets[key as any].preview as string} alt={key} />

              <CallToAction.button
                size="xs"
                className="w-full"
                variant="secondary"
                onClick={() => {
                  const newAssets = exclude(
                    assets,
                    key as keyof typeof assets
                  ) as any;
                  setAssets(newAssets);
                }}
              >
                Remove
              </CallToAction.button>
            </div>
          ))
        ) : (
          <div className="my-auto w-full">
            <Empty message="No assets uploaded" size="md" />
          </div>
        )}
      </Flex.Row>
      <Flex.Row justifyContent="flex-end" gap={12} className="mt-4">
        <CallToAction.button
          size="sm"
          variant="secondary"
          onClick={async () => {
            await createDisclosure({
              title: 'Discard All',
              message: 'Are you sure you want to discard all images?',
            });
            closeModal();
            setAssets([]);
          }}
        >
          Discard All
        </CallToAction.button>
        <CallToAction.button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleUploadAssets}
          size="sm"
        >
          Upload Images
        </CallToAction.button>
      </Flex.Row>
    </>
  );
};

function createUploadFormData(file: File, fields: Record<string, string>) {
  const formData = new FormData();

  for (const key in fields) {
    formData.append(key, fields[key]);
  }

  formData.append('file', file);

  return formData;
}

function exclude<T extends object>(obj: T, key: PropertyKey & keyof T) {
  return Object.keys(obj).reduce((acc, k) => {
    if (k !== key) {
      (acc as Record<string, unknown>)[k] = (obj as Record<string, unknown>)[k];
    }
    return acc;
  }, {} as T);
}
