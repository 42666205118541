import { useCallback, useMemo } from 'react';

// TODO: replace when we start local driven development
const fauxStorage = {
  data: {} as { [key: string]: any }, // Add index signature
  getItem: (key: string) => fauxStorage.data[key],
  setItem: (key: string, value: any) => {
    fauxStorage.data[key] = value;
    return value;
  },
};

export const useShapesMutation = (callback: any, deps: never[]) => {
  const storage = useMemo(
    () => ({
      get: (key: string) => {
        const value = fauxStorage.getItem(key);
        try {
          return JSON.parse(value as any);
        } catch (e) {
          return value;
        }
      },
      set: (key: string, value: string) => {
        if (typeof value === 'object') {
          fauxStorage.setItem(key, JSON.stringify(value));
        } else {
          fauxStorage.setItem(key, value);
        }
      },
      getCanvasObjects: () => {
        const canvasObjects = new Map(
          Object.entries(storage.get('canvasObjects') || {})
        );

        return {
          get: (key?: string) =>
            key ? canvasObjects.get(key) : Object.fromEntries(canvasObjects),
          set: async (key: string, value: unknown) => {
            if (typeof key !== 'string') return;

            canvasObjects.set(key, value);
            storage.set(
              'canvasObjects',
              Object.fromEntries(canvasObjects) as any
            );
          },
          delete: (key: string) => {
            canvasObjects.delete(key);
            storage.set(
              'canvasObjects',
              Object.fromEntries(canvasObjects) as any
            );
          },
          entries: () => [...canvasObjects.entries()],
        };
      },
    }),
    []
  );

  const cb = useCallback(callback, deps);

  const mutate = async (data: any) => {
    try {
      await cb({ storage, data });
    } catch (error) {
      console.error('Error in useShapesMutation:', error);
    }
  };

  return mutate;
};
