import {
  Avatar,
  CallToAction,
  Field,
  Flex,
  Heading,
  Text,
  useFeedback,
} from '@nex/labs';
import { type FormEvent, useCallback, useState } from 'react';
import type React from 'react';

import { useOrganization } from '@clerk/nextjs';

import styles from '../../../../settings.module.scss';

type Values = {
  photo?: string;
  name?: string;
  photoPreviewURL?: string;
};

export const General = () => {
  const { organization, isLoaded: organizationIsLoaded } = useOrganization();
  const { createToast } = useFeedback();
  const [isUpdating, setIsUpdating] = useState(false);
  const [values, setValues] = useState<Values>({});

  const handleOnChange = useCallback(
    (event: {
      target: {
        name: string;
        value: unknown;
      };
    }) => {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
    },
    []
  );

  const handleOnSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (values.name) {
        setIsUpdating(true);

        try {
          await organization?.update({
            name: values.name as string,
          });
          createToast({
            message: 'Workspace updated successfully!',
          });
        } catch (error: any) {
          createToast({
            variant: 'error',
            message:
              error.errors[0]?.message ?? 'Workspace updated successfully!',
          });
        } finally {
          setIsUpdating(false);
        }
      }
    },
    [values, organization, createToast]
  );

  return (
    <>
      <span>
        <Heading.h5 weight={700}>General</Heading.h5>
        <Text className="opacity-80 mt-2 mb-5">
          Update workspace name and logo.
        </Text>
      </span>

      <Field.Form onSubmit={handleOnSubmit}>
        <Text className=" mb-3" weight={700}>
          Logo
        </Text>
        <div className={styles.uploadImage}>
          <Flex gap={14} flexWrap="wrap">
            <Avatar
              size={60}
              alt={organization?.name}
              src={values.photoPreviewURL || organization?.imageUrl}
            />
            <span>
              <Flex gap={8} flexWrap="wrap">
                <CallToAction.input
                  size="sm"
                  maxSize={5}
                  disabled={isUpdating}
                  onFileUpload={(file, options) => {
                    handleOnChange({
                      target: {
                        name: 'photo',
                        value: file,
                      },
                    });

                    if (options?.previews?.length) {
                      handleOnChange({
                        target: {
                          name: 'photoPreviewURL',
                          value: options?.previews?.[0],
                        },
                      });
                    }
                  }}
                >
                  Choose image
                </CallToAction.input>
              </Flex>

              <Text className="opacity-70 mt-3">
                Upload png, jpeg with max size of 5MB
              </Text>
            </span>
          </Flex>
        </div>

        <Field.Input
          required
          name="name"
          type="text"
          label="Name"
          placeholder="Name"
          disabled={isUpdating}
          onChange={handleOnChange}
          value={values.name || organization?.name}
        />

        <Field.Input
          name="slug"
          type="text"
          label="Slug"
          disabled={true}
          placeholder="Slug"
          value={organization?.slug || ''}
        />
        <Text className="opacity-80 mt-0 mb-8" fontSize="12px">
          NB: You can send us an official request to update your workspace slug
        </Text>

        <CallToAction.button isLoading={isUpdating || !organizationIsLoaded}>
          Update Workspace
        </CallToAction.button>
      </Field.Form>
    </>
  );
};
