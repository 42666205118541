import React, { useEffect } from 'react';

import { Field, Flex, Text, useMediaQuery, Avatar, Popover } from '@nex/labs';
import { useAuth, UserButton } from '@clerk/nextjs';

import Link from 'next/link';
import Router, { useRouter } from 'next/router';

import Logo from '@nex/icons/svg/logo.svg';
import LifeBouy from '@nex/icons/svg/misc/life-bouy.svg';
import Settings from '@nex/icons/svg/misc/settings.svg';

import Search from '@nex/icons/svg/misc/search.svg';
import Menu from '@nex/icons/svg/misc/menu-alt.svg';

import { usePostHog } from 'posthog-js/react';

import { useUserStore } from '@/state/useStore';

import styles from '../console-layout.module.scss';

export const ConsoleNav = ({
  title,
  children,
  openSidebar,
  adjacent,
}: {
  title?: string;
  children?: React.ReactNode;
  adjacent?: React.ReactNode;
  openSidebar?: (open: boolean) => void;
}) => {
  const router = useRouter();
  const isMobile = useMediaQuery('lg', 'greaterThan');

  const [searchOpen, setSearchOpen] = React.useState(true);

  useEffect(() => {
    if (router.query.q || isMobile) {
      setSearchOpen(false);
    }
  }, [router.query.q, isMobile]);

  return (
    <nav className={styles.ConsoleLayoutMainNav}>
      <Flex alignItems="center" gap={8} className="flex lg:hidden">
        {!children ? (
          <>
            {isMobile && (
              <Flex className={styles.ConsoleLayoutAsideLogo} flex="1">
                <Link href="/">
                  <Logo width="40" height="auto" />
                </Link>
              </Flex>
            )}
            {/* <Text.span
              className="opacity-90"
              weight={800}
              fontSize="13px"
              casing="uppercase"
              style={{
                flex: '1',
              }}
            >
              {title || 'Console'}
            </Text.span> */}
          </>
        ) : (
          children
        )}
      </Flex>

      {searchOpen && (
        <Field.Input
          placeholder="Search in collections, preset or history"
          name="search"
          type="text"
          leadingIcon={<Search />}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
              Router.push(`/search?q=${e.currentTarget.value}`);
            }
          }}
        />
      )}
      <div className={styles.MainNavRight}>
        {adjacent}
        {!searchOpen && (
          <button type="button" onClick={() => setSearchOpen((prev) => !prev)}>
            <Search />
          </button>
        )}
        <Link href="/settings">
          <Settings />
        </Link>
        <Link href="/help">
          <LifeBouy />
        </Link>
        <UserButton />

        {typeof openSidebar === 'function' && (
          <button
            type="button"
            onClick={() => openSidebar(true)}
            className="block xxl:hidden"
          >
            <Menu />
          </button>
        )}
      </div>
    </nav>
  );
};

export const UserActions = () => {
  const posthog = usePostHog();
  const { signOut } = useAuth();
  const { profile, logOut } = useUserStore();

  const handleLogout = () => {
    logOut();
    signOut();
    posthog.reset();
  };

  return (
    <Popover size="sm" closeOnScroll>
      <Popover.Trigger>
        <Avatar size={40} alt={profile?.name} src={profile?.photo} />
      </Popover.Trigger>
      <Popover.Content isDropdown>
        <Link href="/">Home</Link>
        <Link href="/library">My Collections</Link>
        <Link href="/showcase">Showcase</Link>
        <Link href="/settings">Profile</Link>
        <Link href="/help">Help</Link>
        <button type="button" onClick={handleLogout}>
          Logout
        </button>
      </Popover.Content>
    </Popover>
  );
};
