import { useGetModelsQuery } from '@/state/query/block';
import { useArtboardStore } from '@/state/useStore';
import {
  CallToAction,
  Flex,
  getDataIcons,
  Tabs,
  Text,
  Tooltip,
} from '@nex/labs';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import styles from './views.module.scss';
import { ArtboardPresetsBlock } from './presets';
import { IconMap } from '@/components/misc/controls-icon';

const ModelInfo = [
  {
    title: 'Ikon-1',
    key: 'nex',
    image: '/images/dashboard/ikon.png',
    description:
      'Discover the world’s most controllable image generation model created by NEX.',
    controls: [
      'baseImage',
      'text',
      'colorImage',
      'poseImage',
      'structureImage',
      'softedgeImage',
    ],
  },
  {
    title: 'Flux 1.1',
    key: 'fal-ai',
    image: '/images/dashboard/flux.png',
    description:
      'Fast image generation with great prompt accuracy, visual quality, and output diversity.',
    controls: ['text'],
  },
];

export const ArtboardModel = () => {
  const { defaultConfig, models, setConfig } = useArtboardStore();
  const [currentTab, setCurrentTab] = React.useState('nex');
  const [info] = ModelInfo.filter((info) => info.key === currentTab);

  useEffect(() => {
    if (!defaultConfig?.model) return;
    setCurrentTab(defaultConfig.model?.platform);
  }, [defaultConfig?.model]);

  return (
    <Flex.Column gap={18}>
      <Flex.Column className="mb-1">
        <Text weight={600} className="-mb-2">
          Models
          <Tooltip content="Upload assets and add them as controls in your generations">
            <img
              src={getDataIcons('help')}
              className="inline w-[12px] -mt-[2px] ml-2"
            />
          </Tooltip>
        </Text>
      </Flex.Column>
      <Tabs
        defaultTab="nex"
        selectedKey={currentTab}
        size="sm"
        onChangeTab={(tab) => {
          const newModel = models?.find((model: any) => model.platform === tab);

          if (newModel) {
            setConfig({
              model: newModel,
            });
          }
        }}
        fullWidth
      >
        {models?.map((model: any) => (
          <Tabs.Tab
            key={model.platform}
            dataKey={model.platform}
            title={model.name}
          />
        ))}
      </Tabs>

      {info && (
        <motion.div
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className={styles.ModelInfo}
          key={info.title}
        >
          <img src={info.image} alt={info.title} />
          <Flex.Column gap={2} className={styles.ModelInfoContent}>
            <Text weight={800} casing="uppercase" fontSize="var(--font-h6)">
              {info?.title}
            </Text>
            {/* <Text fontSize="var(--font-accent)">{info.description}</Text> */}

            <Text weight={600} fontSize="var(--font-accent)" className="mt-3">
              Available Controls:
            </Text>
            <Flex gap={12}>
              {Object.keys(IconMap).map((control) => {
                const Icon = IconMap[control as keyof typeof IconMap];
                return (
                  <Icon
                    key={control}
                    className="text-white h-auto"
                    width={22}
                    style={{
                      opacity: info.controls.includes(control) ? 1 : 0.3,
                    }}
                  />
                );
              })}
            </Flex>
          </Flex.Column>
        </motion.div>
      )}

      <ArtboardPresetsBlock />
      <Flex.Column gap={18} className="mt-2">
        <div className={styles.ModelsUpsell}>
          <Text fontSize="var(--font-accent)" weight={800}>
            Customized enterprise solutions
          </Text>
          <Text fontSize="var(--font-accent)" className="opacity-70">
            Boost your team’s creativity with a custom-built AI model, designed
            and trained for your organization.
          </Text>
          <CallToAction.a
            size="sm"
            className="w-full"
            variant="secondary"
            href="https://cal.com/dariuslam/nex-enterprise-demo-30-minutes"
          >
            Schedule a call
          </CallToAction.a>
        </div>
      </Flex.Column>
    </Flex.Column>
  );
};
