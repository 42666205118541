import {
  Avatar,
  CallToAction,
  Empty,
  Flex,
  Img,
  Spinner,
  Text,
  Tooltip,
  getDataIcons,
} from '@nex/labs';

import { useOrganization } from '@clerk/nextjs';
import { formatDistance } from 'date-fns';
import Router from 'next/router';

import classNames from 'classnames';

import { useGetSketchesQuery } from '@/state/query/block';
import { useFabric } from '@/components/layout';
import { useArtboardStore, useUserStore } from '@/state/useStore';

import styles from './views.module.scss';

export const ArtboardSketch = () => {
  const { profile } = useUserStore();
  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useGetSketchesQuery({
      workspaceId: profile?.organizationId,
    });
  const { handleCanvasChange } = useFabric();
  const { isRealTime } = useArtboardStore();

  return (
    <Flex.Column gap={18}>
      <Text weight={600} className="-mb-2">
        Collages{' '}
        <Tooltip content="Create your own scene or design. Begin by clicking “New Collage">
          <img
            src={getDataIcons('help')}
            className="inline w-[12px] -mt-[2px]"
          />
        </Tooltip>
      </Text>

      {!isRealTime && (
        <CallToAction
          className="w-full"
          size="xs"
          variant="secondary"
          leadingIcon={
            <img src={getDataIcons('add')} className="w-[14px] h-[14px]" />
          }
          onClick={() => {
            handleCanvasChange('new');
          }}
        >
          New Collage
        </CallToAction>
      )}

      <Flex.Row gap={12}>
        {data?.sketches?.map((sketch: any, i: number) => (
          <button
            className={classNames([
              styles.AssetsButton,
              sketch.id === Router.query.sketchId && styles.active,
            ])}
            key={i}
            onClick={() => {
              handleCanvasChange(sketch.id);
            }}
          >
            <Img
              src={sketch?.thumbnail || sketch.state.thumbnail}
              alt={`sketch ${i}`}
              className={styles.AssetButtonImg}
            />

            <Flex.Column fullWidth className="mr-auto">
              <Flex.Row className="mt-2" gap={4}>
                <Avatar
                  src={sketch?.creator?.photo}
                  size={18}
                  alt={sketch?.creator?.name}
                />
                <Text
                  weight={600}
                  fontSize="12px"
                  noOfLines={1}
                  align="left"
                  className="flex-1"
                >
                  {sketch?.creator?.name || 'User'}
                </Text>
              </Flex.Row>
              <Text className="opacity-70 mt-1" fontSize="10px" align="left">
                {formatDistance(new Date(), new Date(sketch.updatedAt), {})} ago
              </Text>
            </Flex.Column>
          </button>
        ))}
      </Flex.Row>

      {(isLoading || isFetching) && <Spinner spinner="logo" center size={24} />}

      {hasNextPage && (
        <CallToAction.button
          size="sm"
          variant="secondary"
          className="mt-4 mx-auto basis-[100%]"
          isLoading={isLoading}
          onClick={() => fetchNextPage()}
        >
          Load More
        </CallToAction.button>
      )}

      {!isLoading && !data?.sketches?.length && (
        <Empty message="No Collages yet found" size="sm" />
      )}
    </Flex.Column>
  );
};
