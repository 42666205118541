import { createSmartApi } from '@lib/smart-query';
import queryString from 'query-string';

export const blockApiCreator = createSmartApi({
  key: 'prompt',
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      key: 'all-users',
      queryFn: ({ search, limit = 10 }) => ({
        url: queryString.stringifyUrl(
          {
            url: '/users',
            query: {
              search,
              limit,
            },
          },
          {
            skipNull: true,
            skipEmptyString: true,
          }
        ),
      }),
    }),

    generate: builder.mutation({
      key: 'generate-artboard',
      mutationFn: ({ id, data }) => {
        return {
          url: `/artboards/${id}/groups`,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          body: data,
        };
      },
      invalidatesQueries: ['generations', 'artboards'],
    }),

    addCollaborator: builder.mutation({
      key: 'add-collaborators',
      mutationFn: ({ id, data }) => ({
        url: `/artboards/${id}/collaborators`,
        method: 'POST',
        body: data,
      }),
      invalidatesQueries: ['get-artboard', 'generations', 'artboards'],
    }),

    removeCollaborator: builder.mutation({
      key: 'remove-collaborators',
      mutationFn: ({ id, userId }) => ({
        url: `/artboards/${id}/collaborators/${userId}`,
        method: 'DELETE',
      }),
      invalidatesQueries: ['generations'],
    }),

    editArtBoard: builder.mutation({
      key: 'edit-artboard',
      mutationFn: ({ id, data }) => ({
        url: `/artboards/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesQueries: ['get-artboard', 'generations', 'artboards'],
    }),

    deleteArtboard: builder.mutation({
      key: 'delete-artboard',
      mutationFn: ({ id }) => ({
        url: `/artboards/${id}`,
        method: 'DELETE',
      }),
      invalidatesQueries: ['generations'],
    }),

    getGeneration: builder.query({
      key: 'generation',
      queryFn: ({ id }) => ({
        url: `/generations/${id}`,
      }),
    }),

    getV2Generation: builder.query({
      key: 'generation',
      queryFn: ({ id }) => ({
        url: `/v2/generations/${id}`,
      }),
    }),

    getUserAssets: builder.query({
      key: 'user-assets',
      isInfinite: true,
      queryFn: ({ id: workspaceId, creator, search }) => ({
        url: queryString.stringifyUrl(
          {
            url: `/v2/workspaces/${workspaceId}/assets`,
            query: {
              creator,
              search,
            },
          },
          {
            skipEmptyString: true,
            skipNull: true,
          }
        ),
      }),
    }),

    createOrUpdateSketch: builder.mutation({
      key: 'create-or-update-sketch',
      mutationFn: ({ workspaceId, sketchId, state, thumbnail, snapshot }) => {
        const ID =
          typeof sketchId === 'string' && !!sketchId && sketchId !== 'new';

        return {
          url: `/v2/workspaces/${workspaceId}/sketches${
            ID ? `/${sketchId}` : ''
          }`,
          method: ID ? 'PUT' : 'POST',
          body: {
            state,
            thumbnail,
            snapshot,
          },
        };
      },

      invalidatesQueries: ['sketches'],
    }),

    getSketches: builder.query({
      key: 'sketches',
      isInfinite: true,
      queryFn: ({ workspaceId }) => ({
        url: `/v2/workspaces/${workspaceId}/sketches`,
      }),
    }),

    deleteSketch: builder.mutation({
      key: 'delete-sketch',
      mutationFn: ({ workspaceId, sketchId }) => ({
        url: `/v2/workspaces/${workspaceId}/sketches/${sketchId}`,
        method: 'DELETE',
      }),
      invalidatesQueries: ['sketches'],
    }),

    getSketch: builder.query({
      key: 'sketch',
      queryFn: ({ workspaceId, sketchId }) => ({
        url: `/v2/workspaces/${workspaceId}/sketches/${sketchId}`,
      }),
    }),

    getGenerations: builder.query({
      key: 'generations',
      queryFn: ({ id }) => ({
        url: `/artboards/${id}`,
      }),
    }),

    getModels: builder.query({
      key: 'models',
      queryFn: () => ({
        url: '/v2/models',
      }),
    }),

    getUploadURL: builder.mutation<
      any,
      {
        url: string;
        fields: Record<string, string>;
      }
    >({
      mutationFn: (type) => ({
        url: `/v2/assets/upload?type=${type}`,
        method: 'GET',
      }),
    }),

    createArtBoard: builder.mutation({
      key: 'create-artboard',
      mutationFn: (data) => ({
        url: '/v2/artboards',
        method: 'POST',
        body: data,
      }),
    }),

    getModelById: builder.mutation({
      key: 'model-id',
      mutationFn: ({ id }) => ({
        url: `/models/${id}`,
      }),
    }),

    getPrompt: builder.mutation({
      key: 'prompt',
      mutationFn: (data) => ({
        url: '/prompts/canvas',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    }),

    uploadAsset: builder.mutation({
      key: 'upload-asset',
      mutationFn: ({ data, url }) => ({
        url,
        method: 'POST',
        body: data,
        headers: {
          Authorization: undefined,
          'Content-Type': 'multipart/form-data',
        },
        token: '',
      }),
    }),

    saveAssets: builder.mutation({
      key: 'save-assets',
      mutationFn: (data) => ({
        url: '/v2/assets',
        method: 'POST',
        body: data,
      }),
      invalidatesQueries: ['user-assets'],
    }),

    getCompositionsInProgress: builder.query({
      key: 'compositions-in-progress',
      queryFn: (data) => ({
        url: `/v2/workspaces/${data.workspaceId}/artboards/${data.artboardId}/compositions/tasks`,
      }),
    }),

    generateFromComposition: builder.mutation({
      key: 'generate-from-composition',
      mutationFn: ({ artboardId, data, workspaceId }) => {
        return {
          url: `/v2/workspaces/${workspaceId}/artboards/${artboardId}/compositions`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesQueries: [
        'get-artboard',
        'compositions-in-progress',
        'artboards',
      ],
    }),

    getArtboard: builder.query({
      key: 'get-artboard',
      queryFn: ({ workspaceId, artboardId, limit }) => ({
        url: queryString.stringifyUrl(
          {
            url: `/v2/workspaces/${workspaceId}/artboards/${artboardId}`,
            query: {
              limit,
            },
          },
          {
            skipEmptyString: true,
            skipNull: true,
          }
        ),
      }),
      isInfinite: true,
    }),

    getComposition: builder.query({
      key: 'composition',
      queryFn: ({ workspaceId, artboardId, compositionId }) => ({
        url: `/v2/workspaces/${workspaceId}/artboards/${artboardId}/compositions/${compositionId}`,
      }),
    }),

    deleteAsset: builder.mutation({
      key: 'delete-asset',
      mutationFn: ({ workspaceId, assetId }) => ({
        url: `/v2/workspaces/${workspaceId}/assets/${assetId}`,
        method: 'DELETE',
      }),
      invalidatesQueries: ['user-assets'],
    }),
  }),
});

export const {
  useGetModelsQuery,
  useGetAllUsersQuery,
  useCreateArtBoardMutation,
  useGetGenerationQuery,
  useGenerateMutation,
  useGetPromptMutation,
  useRemoveCollaboratorMutation,
  useAddCollaboratorMutation,
  useGetModelByIdMutation,
  useDeleteSketchMutation,
  useGetUserAssetsQuery,
  useEditArtBoardMutation,
  useGetGenerationsQuery,
  useGetUploadURLMutation,
  useUploadAssetMutation,
  useSaveAssetsMutation,
  useGetSketchQuery,
  useGetV2GenerationQuery,
  useDeleteAssetMutation,
  useDeleteArtboardMutation,
  useCreateOrUpdateSketchMutation,
  useGetSketchesQuery,
  useGetCompositionsInProgressQuery,
  useGenerateFromCompositionMutation,
  useGetArtboardQuery,
  useGetCompositionQuery,
} = blockApiCreator;
