import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { useAuth, useOrganizationList } from '@clerk/nextjs';
import LogoMark from '@nex/icons/svg/logo-mark.svg';
import {
  CallToAction,
  Field,
  Flex,
  Heading,
  Modal,
  slugify,
  Text,
  useFeedback,
} from '@nex/labs';

type CreateWorkspaceProps = {
  show?: boolean;
  onClose?: () => Promise<void> | void;
};

export const CreateWorkspaceModal = () => {
  const auth = useAuth();
  const router = useRouter();
  const { createToast } = useFeedback();
  const {
    createOrganization,
    setActive,
    isLoaded: organizationListIsLoaded,
  } = useOrganizationList();

  const [isCreatingWorkspace, setCreatingWorkspace] = useState(false);
  const hasOrganization = organizationListIsLoaded && auth.orgId;

  const [values, setValues] = useState<{ name: string; slug: string }>({
    name: '',
    slug: '',
  });

  const handleCreateWorkspace = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!organizationListIsLoaded) return;
      setCreatingWorkspace(true);

      try {
        const organization = await createOrganization(values);
        await setActive({ organization: organization.id });
        router.reload();
      } catch (error: any) {
        createToast({
          variant: 'error',
          message: error.errors[0]?.message || 'Failed to create workspace',
        });
      } finally {
        setCreatingWorkspace(false);
      }
    },
    [
      values,
      createToast,
      router,
      createOrganization,
      setActive,
      organizationListIsLoaded,
    ]
  );

  return (
    <div className="p-4">
      <Flex.Column gap={4} className={hasOrganization ? '-mt-3' : 'mt-4 mb-4'}>
        <LogoMark width={32} height={32} />

        {hasOrganization ? (
          <Heading.h4 weight={700} className="mt-2 mb-2">
            Create a new workspace
          </Heading.h4>
        ) : (
          <>
            <Heading.h4 weight={700} className="mt-2 -mb-1">
              You&apos;re almost there!
            </Heading.h4>
            <Text className="opacity-80">
              You need a workspace to get started.
            </Text>
          </>
        )}
      </Flex.Column>
      <Flex.Column gap={28} className="py-2">
        <Field.Form onSubmit={handleCreateWorkspace}>
          <Flex.Column gap={10}>
            <Field.Input
              placeholder="Name"
              value={values.name}
              onChange={(event) => {
                const { value: workspaceName } = event.target;
                return setValues({
                  name: event.target.value,
                  slug: slugify(workspaceName, !hasOrganization),
                });
              }}
              name="name"
              type="text"
              required
            />

            {hasOrganization && (
              <Field.Input
                placeholder="Slug"
                value={values.slug}
                onChange={(event) => {
                  const { value: slug } = event.target;

                  setValues((currentValues) => ({
                    ...currentValues,
                    slug,
                  }));
                }}
                name="slug"
                type="text"
                required
              />
            )}

            <CallToAction.button
              isLoading={isCreatingWorkspace}
              disabled={isCreatingWorkspace}
              className="mt-2"
              size="sm"
            >
              Create workspace
            </CallToAction.button>
          </Flex.Column>
        </Field.Form>
      </Flex.Column>
      <a>
        <Text className="opacity-70 mt-3" fontSize="12px">
          By creating a workspace, you agree to our <a>Terms of Service</a> and{' '}
          <a>Privacy Policy</a>
        </Text>
      </a>
    </div>
  );
};
