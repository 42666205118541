import React, { Fragment } from 'react';
import { Accordion } from '@/components/accordion';
import { FAQ_QUESTIONS } from './help-constants';

import LogoMark from '@nex/icons/svg/logo-mark.svg';

import { CallToAction, Flex, Heading, Text } from '@nex/labs';

import styles from './get-help.module.scss';

export const GetHelp = ({ isLanding }: { isLanding?: boolean }) => {
  return (
    <div className={styles.GetHelpWrapper}>
      <Flex.Row gap={64}>
        <Flex.Row gap={18} className="mt-1">
          <LogoMark className="h-[35px] w-[35px]" />
          <Flex.Column>
            <Heading.h4
              fontSize="28px"
              className="mb-1"
              style={{
                letterSpacing: '-1px',
                lineHeight: '1.25',
              }}
              weight={700}
            >
              <span className="font-light opacity-80"> How can we help</span>{' '}
              <span className="font-bold">you?</span>
              <br />
            </Heading.h4>
            <Heading.h6
              className="opacity-80"
              style={{
                lineHeight: '1.5',
              }}
            >
              Get help quickly and easily
            </Heading.h6>
          </Flex.Column>
        </Flex.Row>
        <Flex.Column flex="1" fullWidth>
          <Accordion.Group allowMultiple>
            {FAQ_QUESTIONS?.slice(0, isLanding ? 5 : undefined).map(
              (faq, index) => (
                <Accordion
                  size="md"
                  theme="light"
                  key={index}
                  title={faq.question}
                >
                  <Text fontSize="16px" lineHeight="1.8" className="opacity-80">
                    {faq.answer?.split('\n').map((text, index) => (
                      <Fragment key={index}>
                        <Text.span className="block" inheritFont>
                          {text}
                        </Text.span>
                        <span className="mb-3 block" />
                      </Fragment>
                    ))}
                  </Text>
                </Accordion>
              )
            )}
          </Accordion.Group>

          {!isLanding && (
            <>
              <Text className="mt-10 mb-4  max-w-[500px] opacity-80">
                We’re constantly adding new ways you can control the outputs of
                your images, so be sure to check these options regularly! <br />{' '}
                <br /> Don&apos;t see what you&apos;re looking for?{' '}
              </Text>
              <CallToAction.a
                variant="secondary"
                href="https://forms.nex.art/feedback"
              >
                Contact Support
              </CallToAction.a>
            </>
          )}
        </Flex.Column>
      </Flex.Row>
    </div>
  );
};
