import { TUser } from './user';

export enum ImageReactionType {
  LIKE = 'LIKE',
  DISLIKE = 'DISLIKE',
}

export interface Generation {
  id: string;
  url: string;
  vote: 'UP' | 'DOWN' | null;
  reaction: ImageReactionType | undefined;
}

export interface Prompt {
  id: string;
  caption: string;
  batchSize: number;
  steps: number;
  sampler: string;
  guidanceScale: number;
  img2img: string;
  img2imgWeight: number;
  negativeCap: number;
  imageSize: number;
  seed: number;
  style: string;
  createdAt: string;
  updatedAt: string;
  isPreset: boolean;
  userId: string;
  presetId: string;
  creditHistoryId: string;
  promptSessionId: string;
  generations: Generation[];
  promptSession: {
    id: string;
    shared?: boolean;
  };
}

export interface PromptSession {
  id: string;
  prompts: Prompt[];
  user?: {
    name: string;
    photo: string;
  };
  createdAt: string;
  shared?: boolean;
}

export interface Pagination {
  count?: number;
  previous?: null;
  next?: null;
}

export interface Collection {
  name?: string;
  description?: string;
  id?: string;
  generations?: Generation[];
  images?: Generation[];
  createdAt?: string;
  updatedAt?: string;
}

export interface Preset {
  captionFormat?: string;
  createdAt?: string;
  description?: string;
  guidanceScale?: number;
  id?: string;
  isDefault?: boolean;
  name?: string;
  sampler?: string;
  seed?: number;
  steps?: number;
  thumbnail?: string;
  updatedAt?: string;
  user?: TUser;
  userId?: string | null;
}
