import { useArtboardStore } from '@/state/useStore';
import { SignIn, SignUp, useClerk } from '@clerk/nextjs';
import { Modal, Spinner, Tabs } from '@nex/labs';
import Router from 'next/router';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect } from 'react';

export const AuthModal = ({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) => {
  const { blockInView, defaultConfig } = useArtboardStore();
  const postHog = usePostHog();
  useEffect(() => {
    if (!show) return;

    window.sessionStorage.setItem(
      'redirectTo',
      window.location.pathname || Router.asPath
    );
    window.sessionStorage.setItem(
      'artboardSnapShot',
      JSON.stringify({
        blocks: blockInView,
        config: defaultConfig,
      })
    );

    return () => {
      onClose();
    };
  }, [blockInView, defaultConfig, show]);

  useEffect(() => {
    if (show) {
      postHog.capture('guest_auth_modal_opened', {
        hasAddedBlock: blockInView.length > 0,
      });
    }
  }, [show]);

  return (
    <Modal
      in={show}
      onClose={() => {
        postHog.capture('guest_auth_modal_closed', {
          hasAddedBlock: blockInView.length > 0,
        });

        return onClose();
      }}
      bare
      size="md"
    >
      <div className="absolute top-[50%] left-[50%] p-4 transform -translate-x-[50%] translate-y-[50%] bg-primary rounded-full">
        <Spinner center text="Loading..." color="#fff" />
      </div>
      <SignUp routing="hash" />
    </Modal>
  );
};
