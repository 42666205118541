import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

import { useAuth, useUser } from '@clerk/nextjs';
import { useRouter } from 'next/router';

export default function PostHogPageView(): null {
  const posthog = usePostHog();
  const router = useRouter();

  const { isSignedIn, userId } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    const getTref = router.query?.tref?.toString();

    if (isSignedIn && userId && user && !posthog._isIdentified()) {
      window.sessionStorage.setItem('tref', getTref!);
      posthog.identify(userId, {
        email: user.primaryEmailAddress?.emailAddress,
        username: user.username,
        tref: getTref,
      });
    }

    if (!isSignedIn && posthog._isIdentified()) {
      posthog.reset();
    }
  }, [posthog, user]);

  return null;
}
