import React, { useMemo } from 'react';

import Router from 'next/router';
import { format, formatDistanceToNow } from 'date-fns';
import {
  Img,
  Avatar,
  CallToAction,
  Flex,
  Heading,
  Spacer,
  Text,
  pluralize,
  useFeedback,
} from '@nex/labs';

import { useUserStore, useGlobalStore } from '@/state/useStore';
import { CreateCollectionModal } from '@/components/layout';
import {
  useDeleteCollectionMutation,
  useGetCollectionByIdQuery,
  useUpdateCollectionMutation,
} from '@/state/query/prompt';

import ArrowBack from '@nex/icons/svg/misc/arrow-narrow-left.svg';

import { FetchContainer } from '@/components/misc/fetch-container';
import { ViewCollectionModal } from './modals/view-collection';

import styles from './collection.module.scss';

export const Collection = ({ id }: { id: string }) => {
  const [collectionOpen, setCollectionOpen] = React.useState(false);
  const [viewCollectionOpen, setViewCollectionOpen] = React.useState(false);
  const [currentCollection, setCurrentCollection] = React.useState<any>({});

  const { profile } = useUserStore();
  const { createDisclosure, createToast } = useFeedback();
  const {
    data: _data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useGetCollectionByIdQuery(
    {
      id,
      workspaceId: profile?.organizationId,
    },
    {
      enabled: id !== undefined && !!id,
    }
  );

  const { mutate: deleteCollection, isLoading: isDeleteLoading } =
    useDeleteCollectionMutation({
      onSuccess: () => {
        createToast({
          message: 'Collection deleted successfully',
        });
      },
      onError: (err: any) => {
        createToast({
          message: err?.message ?? 'Something went wrong',
          variant: 'error',
        });
      },
    });

  const { mutate: updateCollection } = useUpdateCollectionMutation({
    onSuccess: () => {
      createToast({
        message: 'Collection updated successfully',
      });
    },
    onError: (err: any) => {
      createToast({
        message: err?.message ?? 'Something went wrong',
        variant: 'error',
      });
    },
  });

  const data = useMemo(() => _data?.collection, [_data]);

  return (
    <FetchContainer
      isLoading={isLoading || isFetching}
      shouldBeEmpty={!data?.images?.length}
      closeChildOnLoading={isLoading}
      fetchNextPage={fetchNextPage}
      hasMore={hasNextPage}
      emptyMessage="This collection is empty."
    >
      <div className={styles.Results}>
        <Flex justifyContent="space-between">
          <Flex.Column gap={2} fullWidth>
            <Flex
              gap={8}
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              fullWidth
            >
              <CallToAction.a
                href="/library"
                size="sm"
                outline
                variant="clear"
                leadingIcon={
                  <ArrowBack
                    height={12}
                    className="[&>path] fill-current text-black"
                  />
                }
              >
                Back
              </CallToAction.a>
            </Flex>

            <Spacer size={24} direction="vertical" />
            <Heading.h3 weight={800}>{data?.name}</Heading.h3>

            <Heading.h5 className="opacity-80 mt-2">
              {data?.description || 'No description'}
            </Heading.h5>

            {
              <Flex alignItems="center" gap={8} className="mt-4">
                <Avatar src={profile?.photo!} alt={profile?.name} size={30} />
                <Text weight={700}>By {profile?.name}</Text>
              </Flex>
            }

            <Flex
              gap={8}
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              fullWidth
              className="mb-5 mt-4"
            >
              {(data?.createdAt || data?.updatedAt) && (
                <Text className="opacity-70 mt-1">
                  {pluralize('generation', data?.images?.length)} | Created{' '}
                  {format(new Date(data?.updatedAt), 'd/M/yyyy')} | Updated{' '}
                  {formatDistanceToNow(new Date(data?.updatedAt))} ago
                </Text>
              )}

              <div className="flex gap-2">
                <CallToAction.button
                  size="xs"
                  onClick={() => setCollectionOpen(true)}
                >
                  Edit Collection
                </CallToAction.button>
                <CallToAction.button
                  size="xs"
                  outline
                  variant="clear"
                  isLoading={isDeleteLoading}
                  onClick={async () => {
                    await createDisclosure({
                      title: 'Delete Collection',
                      message:
                        'Are you sure you want to delete this collection?',
                      confirmText: 'Delete',
                    });

                    deleteCollection({
                      id: data?.id,
                      workspaceId: profile?.organizationId,
                    });

                    Router.push('/library');
                  }}
                >
                  Delete Collection
                </CallToAction.button>
              </div>
            </Flex>
          </Flex.Column>
        </Flex>

        <div className={styles.Mansory}>
          {data?.images?.length > 0
            ? data?.images?.map((generation: any) => (
                <button
                  type="button"
                  className={styles.Item}
                  key={generation?.id}
                  onClick={() => {
                    setViewCollectionOpen(true);
                    setCurrentCollection(generation);
                  }}
                >
                  <Img src={generation?.url} alt={data?.name} />
                </button>
              ))
            : null}
        </div>
      </div>
      <CreateCollectionModal
        data={data}
        show={collectionOpen}
        type="edit"
        onClose={() => setCollectionOpen(false)}
      />

      {viewCollectionOpen && (
        <ViewCollectionModal
          data={currentCollection}
          parent={{
            title: data?.name,
            generations: data?.images,
          }}
          show={viewCollectionOpen}
          onClose={() => {
            setViewCollectionOpen(false);
            setCurrentCollection({});
          }}
        />
      )}
    </FetchContainer>
  );
};
