import { useState, useEffect, useCallback } from 'react';
import { fabric } from 'fabric';
import { generateStaticID as uuidv4 } from '@nex/labs';
import { IImageOptions } from 'fabric/fabric-impl';

type CopiedObject = fabric.Object & { objectId: string };

export const useCopyPaste = (canvas: fabric.Canvas) => {
  const [copiedObjects, setCopiedObjects] = useState<CopiedObject[]>([]);

  const copySelectedObjects = useCallback(() => {
    if (!canvas) return;

    const activeObjects = canvas.getActiveObjects();
    if (activeObjects.length > 0) {
      const serializedObjects = activeObjects.map((obj: fabric.Object) => {
        const serialized = obj.toObject(['objectId']);
        return serialized as CopiedObject;
      });
      setCopiedObjects(serializedObjects);
    }
  }, [canvas]);

  const pasteObjects = useCallback(
    (x: number = 10, y: number = 10) => {
      if (!canvas || copiedObjects.length === 0) return;

      fabric.util.enlivenObjects(
        copiedObjects,
        (objects: fabric.Object[]) => {
          objects.forEach((obj) => {
            (obj as any).objectId = uuidv4();

            // Offset the position of the pasted object
            obj.set({
              left: (obj.left || 0) + x,
              top: (obj.top || 0) + y,
            });

            canvas.add(obj);
          });
          canvas.renderAll();
          canvas.setActiveObject(objects[objects.length - 1]);
        },
        'fabric'
      );
    },
    [canvas, copiedObjects]
  );

  const handleExternalPaste = useCallback(
    (e: ClipboardEvent) => {
      if (!canvas || !e.clipboardData) return;

      const items = e.clipboardData.items;

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.type.indexOf('image') !== -1) {
          const blob = item.getAsFile();
          if (blob) {
            const reader = new FileReader();
            reader.onload = (event) => {
              const img = new Image();
              img.onload = () => {
                const fabricImage = new fabric.Image(img, {
                  left: 100,
                  top: 100,
                  objectId: uuidv4(),
                } as IImageOptions & { objectId: string });
                canvas.add(fabricImage);
                canvas.setActiveObject(fabricImage);
                canvas.renderAll();
              };
              img.src = event.target?.result as string;
            };
            reader.readAsDataURL(blob);
          }
        }
      }
    },
    [canvas]
  );

  useEffect(() => {
    window.addEventListener('paste', handleExternalPaste);
    return () => {
      window.removeEventListener('paste', handleExternalPaste);
    };
  }, [handleExternalPaste]);

  return { copySelectedObjects, pasteObjects };
};
