import { Field, type FieldProps, capitalize } from '@nex/labs';
import { useRoles } from './use-roles';
import { useCallback, useMemo } from 'react';

type SelectRoleProps = {
  defaultRole?: string;
  onChange?: (role: string) => Promise<void> | void;
} & FieldProps;

export const SelectRole = ({
  defaultRole,
  onChange,
  ...fieldProps
}: SelectRoleProps) => {
  const roles = useRoles();

  const handleRoleChange = useCallback(
    (event: React.FormEvent<HTMLSelectElement>) => {
      onChange?.((event.target as HTMLSelectElement).value);
    },
    [onChange]
  );

  const options = useMemo(
    () => [
      {
        label: 'Select Role',
        disabled: true,
        value: '',
      },
      ...roles.map((role) => ({
        value: role,
        label: toRoleName(role),
        disabled: role === 'org:guest',
      })),
    ],
    [roles]
  );

  return (
    <Field.Select
      {...fieldProps}
      options={options}
      value={defaultRole}
      onChange={handleRoleChange}
    />
  );
};

function toRoleName(role: string) {
  return capitalize(role.split(':')[1]);
}
