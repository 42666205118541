import React from 'react';

import type { PropsWithChildren } from 'react';

import { SkipNavContent, SkipNavLink } from '@nex/labs';
import { Navbar } from '@/components/navbar';

import styles from './base-layout.module.scss';

export const Layout: React.FC<
  {
    bare?: boolean;
  } & PropsWithChildren
> = ({ children, bare }) => {
  return (
    <div className={`${styles.Layout}`}>
      <SkipNavLink focusSelector="maincontent" />

      {!bare ? (
        <>
          <header className={styles.LayoutHeader}>
            <Navbar />
          </header>

          <main className={styles.LayoutMain}>
            <SkipNavContent focusSelector="maincontent">
              {children}
            </SkipNavContent>
          </main>
        </>
      ) : (
        children
      )}
    </div>
  );
};
