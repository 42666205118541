import React, { useState } from 'react';

import { Flex, Spinner, Text, useInterval } from '@nex/labs';
import { motion } from 'framer-motion';
import { TIPS } from './generative-tip';

import styles from './generative-loading.module.scss';

export const GenerativeLoading = ({
  isResult,
  progress,
  tips = TIPS,
}: {
  isResult?: boolean;
  progress: number;
  tips?: Array<{
    title: string;
    tip: string;
  }>;
}) => {
  const [index, setIndex] = useState(0);

  const currentTip = tips?.[index];
  useInterval(() => {
    setIndex((prevIndex) => (prevIndex + 1) % tips?.length);
  }, 7000);

  return (
    <motion.div className={styles.GenerativeLoading}>
      <Spinner
        spinner="logo"
        text={
          isResult ? 'Loading your image...' : 'We are generating your image...'
        }
      />
      <progress
        value={progress}
        max="100"
        style={
          {
            '--webkitProgressPercent': `${progress}%`,
          } as React.CSSProperties
        }
      />

      {tips?.length ? (
        <motion.div
          key={index}
          className={styles.GenerativeLoadingProgress}
          animate={{
            y: [0, 10, 0],
            scale: [1, 1, 1],
            opacity: [1, 0.5, 1],
          }}
          transition={{ duration: 1 }}
        >
          <Flex.Column gap={6}>
            <Text className="opacity-60 mb-2">
              <span className={styles.avatarGradient}></span> NEX Tips
            </Text>
            <Text color="var(--primary-theme)" weight={700}>
              {currentTip.title}
            </Text>
            <Text className="opacity-80">{currentTip.tip}</Text>
          </Flex.Column>
        </motion.div>
      ) : null}
    </motion.div>
  );
};
