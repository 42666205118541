import React from 'react';

import {
  Avatar,
  CallToAction,
  extractImage,
  Flex,
  Img,
  Modal,
  Text,
  useFeedback,
} from '@nex/labs';

import { useDeleteAssetMutation } from '@/state/query/block';
import { useOrganization } from '@clerk/nextjs';
import { formatDistanceToNow } from 'date-fns';
import { type ImageDataArray, ImageData } from '@nex/types/lib/assets';
import { useUserStore } from '@/state/useStore';

export const ViewAssetModal = ({
  show,
  closeModal,
  data,
  parent,
}: {
  show: boolean;
  closeModal: () => void;
  data: ImageData;
  parent: ImageDataArray;
}) => {
  const { profile } = useUserStore();

  const { mutateAsync: deleteAsset } = useDeleteAssetMutation();
  const { createDisclosure } = useFeedback();

  return (
    <Modal title="Asset" size="xlg" in={show} onClose={closeModal}>
      <Flex.Row gap={12} justifyContent="space-between">
        <Flex.Column gap={4} className="flex-1">
          <Img src={extractImage(data)} alt={data?.caption ?? 'Assets'} />
        </Flex.Column>
        <Flex.Column className="basis-[300px] justify-between gap-8">
          <Flex.Column gap={4}>
            <Flex gap={8} alignItems="center" className="mb-1">
              <Avatar
                src={data?.creator?.photo}
                alt={data?.creator?.name}
                size={30}
              />

              <Text weight={700}>{data?.creator?.name}</Text>
            </Flex>

            <Text className="opacity-70" weight={600}>
              Uploaded{' '}
              {data?.updatedAt &&
                formatDistanceToNow(new Date(data?.updatedAt))}{' '}
              ago
            </Text>

            <Flex.Column gap={4} className="mt-4">
              <Text weight={800}>Caption</Text>
              <Text className="opacity-70" weight={600}>
                {data?.caption || 'No caption'}
              </Text>
            </Flex.Column>
          </Flex.Column>

          <Flex.Row gap={8} className="mt-auto">
            <CallToAction.a
              href={`/enhance?img=${encodeURIComponent(extractImage(data))}`}
              size="sm"
              variant="primary"
              onClick={() => closeModal()}
              data-analytics="enhance_generation"
            >
              Enhance
            </CallToAction.a>
            <CallToAction.button
              size="xs"
              outline
              variant="clear"
              onClick={async () => {
                await createDisclosure({
                  title: 'Delete Asset',
                  message: 'Are you sure you want to delete this asset?',
                  confirmText: 'Delete',
                });

                deleteAsset({
                  assetId: data?.id,
                  workspaceId: profile?.organizationId,
                  parent,
                });

                closeModal();
              }}
            >
              Delete Asset
            </CallToAction.button>
          </Flex.Row>
        </Flex.Column>
      </Flex.Row>
    </Modal>
  );
};
