import React, { useEffect } from 'react';
import { Flex, Text, Slider } from '@nex/labs';

export const WeightSlider = ({
  block,
  setBlock,
  noLabel,
}: {
  block: any;
  setBlock: any;
  noLabel?: boolean;
}) => {
  const [weight, setWeight] = React.useState<number | null>(
    (block?.data?.weight || 0.5) * 100
  );

  useEffect(() => {
    if (block?.data?.weight) {
      setWeight(block?.data?.weight * 100);
    }
  }, [block?.data?.weight]);

  return (
    <Flex.Column gap={10}>
      {!noLabel && (
        <Text className="flex-auto" weight={700}>
          Strength
        </Text>
      )}
      <Slider
        minValue={0}
        maxValue={100}
        step={10}
        onClick={(e) => {
          e.stopPropagation();
        }}
        value={weight ?? 0}
        onChange={(value) => {
          setWeight(value as number);
        }}
        onChangeEnd={() => {
          setBlock(block.meta || block?.data?.subMetaInfo?.parent, {
            ...block?.data,
            weight: weight! / 100,
          });
        }}
      />
    </Flex.Column>
  );
};
