import React from 'react';
import Router from 'next/router';

import { CallToAction, Flex, Heading } from '@nex/labs';

export const GenerateTutorial = ({
  link = 'https://www.youtube.com/embed/gU9oxLuPZTw?si=-S2hy8x5mA3kBIrs',
  title = 'How to use NEX',
}) => {
  return (
    <Flex.Column
      justifyContent="center"
      alignItems="center"
      className="text-center"
      gap={14}
    >
      <Heading.h5 weight={700}>{title}</Heading.h5>
      <iframe
        width="100%"
        height="415"
        src={link}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <Flex>
        {!Router.asPath.includes('/artboard') && (
          <CallToAction.a href="/artboard/new">Start Generating</CallToAction.a>
        )}
      </Flex>
    </Flex.Column>
  );
};
