import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Flex, Heading, Tabs, capitalize } from '@nex/labs';

import LogoMark from '@nex/icons/svg/logo-mark.svg';
import { Plans } from './views/plans';
import { Workspace } from './views/workspaces';
import { General } from './views/general';

import styles from './settings.module.scss';

export const Settings = () => {
  const [currentTab, setCurrentTab] = React.useState('general');
  const router = useRouter();

  const getHref = (tab: string) => {
    return `/settings/${tab}`;
  };

  useEffect(() => {
    const { tab } = router.query;
    if (tab) {
      setCurrentTab(tab as string);
    }
  }, [router.query]);

  return (
    <div className={styles.SettingPage}>
      <Flex.Column gap={24} className="mt-0 mb-8">
        <Heading.h4
          fontSize="28px"
          className="mb-1 flex items-center gap-2"
          weight={700}
        >
          <LogoMark className="inline w-[32px] h-[32px]" />
          <span className="font-light opacity-80">Settings/</span>
          <span className="font-bold">{capitalize(currentTab)}</span>
          <br />
        </Heading.h4>
      </Flex.Column>
      <Tabs selectedKey={currentTab} defaultTab="general" centered={false}>
        <Tabs.Tab dataKey="general" title="General" href={getHref('general')} />
        <Tabs.Tab
          dataKey="workspace"
          title="workspace"
          href={getHref('workspace')}
        />
        <Tabs.Tab dataKey="plans" title="plans" href={getHref('plans')} />
      </Tabs>

      {currentTab === 'general' && <General />}
      {currentTab === 'workspace' && <Workspace />}
      {currentTab === 'plans' && <Plans />}
    </div>
  );
};
