import React from 'react';

import {
  CallToAction,
  Flex,
  getDataIcons,
  Heading,
  useDebounce,
} from '@nex/labs';
import { useOrganization } from '@clerk/nextjs';

import { FetchContainer } from '@/components/misc/fetch-container';
import ImageIcon from '@nex/icons/svg/dashboard/image.svg';

import { useGetUserAssetsQuery } from '@/state/query/block';
import { AssetCard } from '../components/asset-card';

import { useFileUpload } from '@/components/upload/useUpload';
import { ViewAssetModal } from '../../artboard/components/modals/view-asset';
import { type ImageData } from '@nex/types/lib/assets';
import styles from './collection/collection.module.scss';

export const Assets = ({
  query,
  bare,
  isSearch,
  limit,
}: {
  query?: string;
  bare?: boolean;
  limit?: number;
  isSearch?: boolean | string;
}) => {
  const [show, setShow] = React.useState(false);
  const { onFileUpload } = useFileUpload();
  const [currentData, setCurrentData] = React.useState<ImageData | null>(null);
  const { organization } = useOrganization();
  const searchValue = useDebounce(query, 500);

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    useGetUserAssetsQuery(
      {
        search: searchValue as string,
        limit: limit || 15,
        id: organization?.publicMetadata?.externalId,
      },
      {
        enabled: !!organization,
      }
    );

  return (
    <Flex.Column gap={18}>
      {!isSearch && !bare && (
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          gap={12}
          alignItems="center"
        >
          <span>
            <Flex gap={4} alignItems="center">
              <ImageIcon width={16} className="opacity-80" />
              <Heading.h5 weight={700}>Assets</Heading.h5>
            </Flex>
          </span>

          <CallToAction.input
            size="xs"
            className="h-fit"
            variant="secondary"
            leadingIcon={<img src={getDataIcons('add', '#000')} />}
            defaultBehavior={false}
            onFileUpload={onFileUpload}
          >
            Upload Images
          </CallToAction.input>
        </Flex>
      )}
      <FetchContainer
        isLoading={isLoading || isFetching}
        shouldBeEmpty={!data?.assets?.length}
        closeChildOnLoading={isLoading}
        fetchNextPage={fetchNextPage}
        hasMore={hasNextPage}
        emptyMessage="You don't have any assets yet."
      >
        <div className={styles.Results}>
          <div className={styles.Mansory}>
            {data?.assets?.length > 0
              ? data?.assets?.map((asset: any, index: number) => (
                  <AssetCard
                    asset={asset}
                    key={index}
                    setCurrentData={setCurrentData}
                    setShow={setShow}
                  />
                ))
              : null}
          </div>
        </div>
      </FetchContainer>
      <ViewAssetModal
        show={show}
        closeModal={() => setShow(false)}
        data={currentData as ImageData}
        parent={data?.assets}
      />
    </Flex.Column>
  );
};
