import { Flex, Heading, useFeedback, CallToAction, Text } from '@nex/labs';
import { useRouter } from 'next/router';
import Link from 'next/link';
import classNames from 'classnames';
import { useFileUpload } from '@/components/upload/useUpload';

import styles from './console-actions.module.scss';

export const ConsoleSlateActions = () => {
  const router = useRouter();
  const { onFileUpload } = useFileUpload();
  const isNotConsole = router.asPath === '/';

  return (
    <div
      className={classNames([
        styles.ConsoleActionSlateButtons,
        !isNotConsole && styles.sm,
      ])}
    >
      {!isNotConsole && (
        <>
          <div
            className={classNames([
              styles.ConsoleActionSlateButton,
              styles.LightVersion,
            ])}
          >
            <img src="/images/dashboard/upload.png" alt="Assets" />
            <Flex.Column gap={4}>
              <Heading.h5 weight={700}>Upload Assets</Heading.h5>
              <Text color="#475467">
                Drag & drop or upload up to 10mb in images
              </Text>
              <CallToAction.input
                variant="secondary"
                className="mt-2"
                defaultBehavior={false}
                multiple
                onFileUpload={onFileUpload}
              >
                Upload Images
              </CallToAction.input>
            </Flex.Column>
          </div>
        </>
      )}

      <Link href="/artboard/new" className={styles.ConsoleActionSlateButton}>
        <img src="/images/dashboard/artboard.png" alt="Artboard" />
        <Flex.Column gap={4}>
          <Heading.h5 weight={700}>Artboard</Heading.h5>
          <Text>Ideate at the speed of thought</Text>
          <CallToAction className="mt-2">New Artboard</CallToAction>
        </Flex.Column>
      </Link>
      <Link href="/enhance" className={styles.ConsoleActionSlateButton}>
        <img src="/images/dashboard/enhance.png" alt="Artboard" />
        <Flex.Column gap={4}>
          <Heading.h5 weight={700}>Image Enhancer</Heading.h5>
          <Text>Creatively upscale images</Text>
          <CallToAction className="mt-2">Enhance Image</CallToAction>
        </Flex.Column>
      </Link>
      <Link
        href="/"
        className={classNames([
          styles.ConsoleActionSlateButton,
          styles.disabled,
        ])}
      >
        <img src="/images/dashboard/enhance.png" alt="Artboard" />
        <Flex.Column gap={4}>
          <Heading.h5 weight={700}>Realtime Canvas</Heading.h5>
          <Text>Coming soon...</Text>
          {/* <CallToAction className="mt-2">New Realtime</CallToAction> */}
        </Flex.Column>
      </Link>
    </div>
  );
};
