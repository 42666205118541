export const Tutorials = [
  {
    title: 'Write a prompt',
    message: 'Blocks are how you create your composition. Try adding one',
    containerId: '#action-input',
    tab: 'models',
    position: 'bottom center',
    image: '/images/dashboard/tutorial/prompt.gif',
  },
  {
    title: 'Blocks Preview',
    message: 'Select a block to view all the parameters used to create',
    containerId: '#floating-nav-bottom',
    tab: 'models',
    position: 'bottom center',
    image: '/images/dashboard/tutorial/preview-2.png',
  },
  {
    title: 'Styles',
    message: 'Easily control image style by adding a Preset.',
    containerId: '#artboard-aside',
    tab: 'models',
    position: 'center right',
    image: '/images/dashboard/tutorial/preset.png',
  },
  {
    title: 'Use Assets Conditions',
    message: 'Upload and manage assets here. Begin by clicking on Choose File.',
    containerId: '#artboard-aside',
    tab: 'assets',
    position: 'top right',
    image: '/images/dashboard/tutorial/asset.png',
  },
  {
    title: 'Sketch your composition',
    message: 'Freely draw and design or import an image create your own asset.',
    containerId: '#artboard-aside',
    tab: 'sketch',
    position: 'center right',
    image: '/images/dashboard/tutorial/sketch.gif',
  },
  {
    title: 'Artboard History',
    message: 'View your Artboard’s generation history.',
    containerId: '#floating-nav-top',
    tab: 'history',
    position: 'top center',
    image: '/images/dashboard/tutorial/history.png',
  },
];
