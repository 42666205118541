import BaseIcon from '@nex/icons/svg/blocks/action-bar/base.svg';
import SketchIcon from '@nex/icons/svg/blocks/action-bar/sketch.svg';
import StructureIcon from '@nex/icons/svg/blocks/action-bar/structure.svg';
import ColorIcon from '@nex/icons/svg/blocks/action-bar/color.svg';
import PoseIcon from '@nex/icons/svg/blocks/action-bar/pose.svg';
import TextIcon from '@nex/icons/svg/blocks/action-bar/text.svg';

export const IconMap = {
  text: TextIcon,
  baseImage: BaseIcon,
  softedgeImage: SketchIcon,
  colorImage: ColorIcon,
  structureImage: StructureIcon,
  poseImage: PoseIcon,
};
