import { Flex, Heading, Tabs, capitalize } from '@nex/labs';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { Collections } from './views/collections';
import { ConsoleSlateActions } from '@/components/layout';
import { Assets } from './views/assets';
import LogoMark from '@nex/icons/svg/logo-mark.svg';
import styles from './library.module.scss';
import { ArtBoards } from './views/artboards';

import { useFileUpload } from '@/components/upload/useUpload';
import { DragDropFileUpload } from '@/components/upload/drag-drop';

export const Library = () => {
  const [currentTab, setCurrentTab] = React.useState('artboards');
  const router = useRouter();
  const { onFileUpload } = useFileUpload();

  const getHref = (tab: string) => {
    return `/library/${tab}`;
  };

  useEffect(() => {
    const { tab } = router.query;
    if (tab) {
      setCurrentTab(tab as string);
    }
  }, [router.query]);

  return (
    <div className={styles.ConsoleWrapper}>
      <DragDropFileUpload onFileUpload={onFileUpload} />
      <Flex.Column gap={24} className="mt-4">
        <Heading.h4
          fontSize="28px"
          className="mb-1 flex items-center gap-2"
          weight={700}
        >
          <LogoMark className="inline w-[32px] h-[32px]" />
          <span className="font-light opacity-80">Library/</span>
          <span className="font-bold">{capitalize(currentTab)}</span>
          <br />
        </Heading.h4>
        <ConsoleSlateActions />
      </Flex.Column>
      <Flex.Column gap={32} className="mt-[56px]">
        <Tabs
          selectedKey={currentTab}
          defaultTab="collections"
          centered={false}
        >
          <Tabs.Tab
            dataKey="artboards"
            title="Artboards"
            href={getHref('artboards')}
          />
          <Tabs.Tab dataKey="assets" title="Assets" href={getHref('assets')} />
          <Tabs.Tab
            dataKey="collections"
            title="Collections"
            href={getHref('collections')}
          />
        </Tabs>

        {currentTab === 'collections' && <Collections />}
        {currentTab === 'assets' && <Assets />}
        {currentTab === 'artboards' && <ArtBoards />}
      </Flex.Column>
    </div>
  );
};
