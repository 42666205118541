export const TIPS = [
  {
    title: 'Be Specific with Details',
    tip: 'Include specifics like time of day, lighting, atmosphere, and distinct objects in your prompt for more focused images.',
  },
  {
    title: 'Balance Creativity and Clarity',
    tip: 'Ensure prompts are imaginative yet clear and coherent for better AI understanding and visualization.',
  },
  {
    title: 'Use Vivid Descriptions',
    tip: 'Employ vivid, sensory, emotional, and atmospheric language to enhance image quality.',
  },
  {
    title: 'Incorporate a Variety of Elements',
    tip: 'Mix elements like historical eras, technological advancements, and mythical creatures for unique images.',
  },
  {
    title: 'Play with Perspectives',
    tip: "Experiment with various perspectives like bird's-eye or worm's-eye views to add an interesting dimension.",
  },
  {
    title: 'Consider Color Schemes',
    tip: 'Mention specific color palettes or contrasts to impact the mood and aesthetic of the image.',
  },
  {
    title: 'Invoke Emotion and Mood',
    tip: 'Use words that describe the mood or emotion of the scene to guide the AI in setting the right tone.',
  },
  {
    title: 'Explore Different Genres and Themes',
    tip: 'Experiment with genres like sci-fi, fantasy, historical, to influence the output.',
  },
  {
    title: 'Leverage Artistic Styles',
    tip: 'Mention specific artistic styles or eras for a unique artistic flair in your images.',
  },
  {
    title: 'Iterate and Refine',
    tip: 'Refine prompts based on previous outputs for better results; sometimes small tweaks make a big difference.',
  },
];

export const UPSCALE_TIPS = [
  {
    title: 'Optimize Upscaling for Square Images',
    tip: "You're going to get the best results when upscaling square images, or images as close as possible to square.",
  },
  {
    title: 'Best Resolution for Upscaling',
    tip: 'For these square images, if you start upscaling a 512x512 image in particular, this will yield the best results.',
  },
  {
    title: 'Managing Creativity for Faces',
    tip: "If you're trying to upscale an image with a face in it, keep the creativity score low (<0.2).",
  },
  {
    title: 'Adjusting Creativity for Detail and Imagination',
    tip: 'Otherwise, for maximum detail and reimagination, feel free to move that creativity score up (0.6-1.).',
  },
  {
    title: 'Limits on Maximum Square Image Size',
    tip: 'Maximum square image size right now is 1024x1024, and we support generations up to 2048x2048 for square images.',
  },
  {
    title: 'Dimensions and Support for Rectangular Images',
    tip: 'Maximum rectangular image size right now is 1024 x 1536 (either way around, width and height or height and width) and we support generations up to 2048 x 3072.',
  },
];
