import React, { useState } from 'react';

import { CallToAction, Flex, Text } from '@nex/labs';
import { useArtboardStore } from '@/state/useStore';

import { useFabric } from '@/components/layout';
import { Accordion } from '@/components/accordion';

import styles from '../../sketch.module.scss';

export const HasWrongCanvasSize: React.FC = () => {
  const [show, setShow] = useState(true);
  const { defaultConfig } = useArtboardStore();
  const { hasWrongCanvasSize, forceSetCanvasSize } = useFabric();
  if (!show) return null;

  return (
    <>
      {' '}
      {hasWrongCanvasSize && show && (
        <div className={styles.WrongCanvasSize}>
          <Text>
            Your canvas size doesn&apos;t match the current artboard settings
          </Text>
          <Accordion title="What's happening?" bare>
            <Text>
              The aspect ratio of your canvas is different from the artboard
              settings. This might affect how your artwork displays.
              <ul className="list-disc list-inside my-2">
                <li>
                  Your current artboard size is set to:{' '}
                  {defaultConfig.resolution}
                </li>
                <li>Adjust your canvas size to match this artboard size.</li>
                <li>
                  Alternatively, you can change the artboard settings to match
                  your preferred canvas size.
                </li>
              </ul>
              Don&apos;t worry - your artwork is safe! This adjustment will just
              ensure everything looks perfect when you&apos;re done. Need help?
              <a
                href="mailto:outreach@nex.art"
                target="_blank"
                rel="noreferrer"
                color="var(--primary-theme)"
              >
                {' '}
                Contact us
              </a>
            </Text>
          </Accordion>
          <Flex.Row gap={4}>
            <CallToAction
              size="xs"
              onClick={() => {
                const [width, height] = defaultConfig.resolution.split('x');
                forceSetCanvasSize({
                  width: +width,
                  height: +height,
                });
              }}
            >
              Use Artboard size
            </CallToAction>

            <CallToAction
              size="xs"
              variant="secondary"
              onClick={() => {
                setShow(false);
              }}
            >
              {' '}
              Close
            </CallToAction>
          </Flex.Row>
        </div>
      )}
    </>
  );
};
