import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { fabric } from 'fabric';

import { CustomFabricObject } from './types';
import { handleDelete } from './key-events';
import { generateStaticID as uuidv4 } from '@nex/labs';
import { useFabric } from '@/components/layout';

const MAX_HISTORY_LENGTH = 50;

interface HistoryState {
  canvasState: string;
  objects: fabric.Object[];
}

export const useKeyEvents = ({
  fabricRef,
  syncShapeInStorage,
  deleteShapeFromStorage,
}: {
  fabricRef: any;
  syncShapeInStorage: (shape: fabric.Object | string) => void;
  deleteShapeFromStorage: (id: string) => void;
}) => {
  const { handleActions, undo, redo } = useFabric();

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!fabricRef.current) return;

      const canvas = fabricRef.current as fabric.Canvas;

      if ((e?.ctrlKey || e?.metaKey) && e.keyCode === 67) {
        handleActions('copy');
      }

      if ((e?.ctrlKey || e?.metaKey) && e.keyCode === 86) {
        handleActions('paste');
      }

      if (e.keyCode === 8 || e.keyCode === 46) {
        const activeElement = document.activeElement as HTMLElement;
        if (
          activeElement.tagName === 'INPUT' ||
          activeElement.tagName === 'TEXTAREA' ||
          activeElement.tagName === 'SELECT'
        ) {
          return;
        }

        handleDelete(canvas, deleteShapeFromStorage);
      }

      if ((e?.ctrlKey || e?.metaKey) && e.keyCode === 88) {
        handleActions('copy');
        handleDelete(canvas, deleteShapeFromStorage);
      }

      if ((e?.ctrlKey || e?.metaKey) && e.keyCode === 68) {
        e.preventDefault();
        handleActions('duplicate');
      }

      if ((e?.ctrlKey || e?.metaKey) && e.shiftKey && e.keyCode === 90) {
        redo();
      }

      if ((e?.ctrlKey || e?.metaKey) && e.keyCode === 83) {
        e.preventDefault();
        syncShapeInStorage('save');
      }

      if ((e?.ctrlKey || e?.metaKey) && e.keyCode === 90) {
        undo();
      }

      if ((e?.ctrlKey || e?.metaKey) && e.keyCode === 89) {
        redo();
      }

      if (e.keyCode === 191 && !e.shiftKey) {
        e.preventDefault();
      }
    },
    [
      fabricRef,
      handleActions,
      deleteShapeFromStorage,
      syncShapeInStorage,
      undo,
      redo,
    ]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
};
