import React from 'react';
import {
  CallToAction,
  Flex,
  Heading,
  Text,
  useDebounce,
  getDataIcons,
  extractImage,
  Img,
  pluralize,
} from '@nex/labs';
import Link from 'next/link';

import { formatDistanceToNow } from 'date-fns';
import { useGetCollectionQuery } from '@/state/query/prompt';
import { FetchContainer } from '@/components/misc/fetch-container';
import Bookmark from '@nex/icons/svg/misc/bookmark.svg';

import { CreateCollectionModal } from '@/components/layout';
import { useUserStore } from '@/state/useStore';

import styles from '../library.module.scss';

export const Collections = ({
  query,
  bare,
  isSearch,
  limit,
}: {
  query?: string;
  bare?: boolean;
  limit?: number;
  isSearch?: boolean | string;
}) => {
  const [createNew, setCreateNew] = React.useState(false);
  const searchValue = useDebounce(query, 500);
  const { profile } = useUserStore();

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useGetCollectionQuery({
      search: searchValue as string,
      limit: limit || 15,
      workspaceId: profile?.organizationId,
    });

  return (
    <div className={styles.ConsoleWrapper}>
      <div className={styles.ConsoleRecentWorks}>
        <FetchContainer
          isLoading={isLoading || isFetching}
          hasMore={hasNextPage}
          fetchNextPage={fetchNextPage}
          shouldBeEmpty={!data?.collections?.length}
          emptyMessage={
            isSearch
              ? 'No collections found matching your search'
              : 'You have no collections yet. Add your first collection!'
          }
        >
          {!isSearch && !bare && (
            <Flex
              flexWrap="wrap"
              justifyContent="space-between"
              gap={12}
              alignItems="center"
            >
              <span>
                <Flex gap={4}>
                  <Bookmark width={16} className="opacity-80" />{' '}
                  <Heading.h5 weight={700}>Collections</Heading.h5>
                </Flex>
              </span>

              <CallToAction.button
                size="xs"
                className="h-fit"
                variant="secondary"
                leadingIcon={<img src={getDataIcons('add', '#000')} />}
                onClick={() => setCreateNew(true)}
              >
                Create New Collection
              </CallToAction.button>
            </Flex>
          )}

          {data?.collections?.length ? (
            <div className={styles.ArtboardGrid}>
              {data?.collections
                ?.slice(0, limit || data?.collections?.length)
                ?.map((collection, index) => (
                  <Link
                    className={styles.ConsoleArtboardsArea__card}
                    key={index}
                    href={`/library/collection/${collection?.id}`}
                  >
                    <Img
                      src={extractImage(collection)}
                      alt={collection?.name}
                    />
                    <div className={styles.ConsoleArtboardsArea__card__content}>
                      <Heading.h5 weight={700}> {collection?.name}</Heading.h5>
                      {collection?.images!.length > 0 && (
                        <Text weight={700} className="opacity-70">
                          {pluralize('image', collection?.images!.length)}
                        </Text>
                      )}

                      <Text>
                        {collection?.updatedAt && (
                          <Text className="opacity-70 mt-1" fontSize="12px">
                            Updated{' '}
                            {formatDistanceToNow(
                              new Date(collection?.updatedAt)
                            )}
                            {' ago'}
                          </Text>
                        )}
                      </Text>
                    </div>
                  </Link>
                ))}
            </div>
          ) : null}
        </FetchContainer>

        <CreateCollectionModal
          show={createNew}
          onClose={() => setCreateNew(false)}
          type="create"
        />
      </div>
    </div>
  );
};
