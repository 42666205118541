import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { fabric } from 'fabric';
import {
  handleCanvasMouseDown,
  handleCanvasMouseMove,
  handleCanvasMouseUp,
  handleCanvasObjectModified,
  handleCanvasObjectMoving,
  handleCanvasObjectScaling,
  handleCanvasSelectionCreated,
  handlePathCreated,
  initializeFabric,
} from './canvas';
import { useArtboardStore } from '@/state/useStore';
import { useFabric } from '@/components/layout';

interface CanvasActionsProps {
  syncShapeInStorage: any;
  setActiveElement: (element: any) => void;
}

export const useCanvasActions = ({
  syncShapeInStorage,
  setActiveElement,
}: CanvasActionsProps) => {
  const {
    setElementAttributes,
    setSketch,
    sketch: { isEditing },
  } = useArtboardStore();
  const {
    fabric: fabricRef,
    canvasRef,
    shapeRef,
    selectedShapeRef,
    activeObjectRef,
    isDrawingRef,
    handleResize,
  } = useFabric();

  const canvas = useMemo(() => fabricRef.current, [fabricRef.current]);

  useEffect(() => {
    const canvas = initializeFabric({
      canvasRef,
      fabricRef,
    });

    if (!canvas) return;

    const eventHandlers = {
      'mouse:down': (options: fabric.IEvent) =>
        handleCanvasMouseDown({
          options,
          canvas: canvas,
          selectedShapeRef,
          isDrawing: isDrawingRef,
          shapeRef,
          activeObjectRef,
        }),
      'mouse:move': (options: fabric.IEvent) =>
        handleCanvasMouseMove({
          options,
          canvas: canvas,
          isDrawing: isDrawingRef,
          selectedShapeRef,
          shapeRef,
          syncShapeInStorage,
        }),
      'mouse:up': () =>
        handleCanvasMouseUp({
          canvas: canvas,
          isDrawing: isDrawingRef,
          shapeRef,
          activeObjectRef,
          selectedShapeRef,
          syncShapeInStorage,
          setActiveElement,
        }),
      //TODO: Uncomment this when we have the auto save feature
      // 'object:added': saveCanvas,
      // 'object:removed': saveCanvas,
      'path:created': (options: fabric.IEvent) =>
        handlePathCreated({ options, syncShapeInStorage }),
      'object:modified': (options: fabric.IEvent) => {
        const modifiedObject = options.target;
        if (modifiedObject) {
          activeObjectRef.current = modifiedObject;
        }

        handleCanvasObjectModified({
          options,
          setElementAttributes,
          syncShapeInStorage,
        });
      },
      'selection:cleared': () => {
        activeObjectRef.current = null;
        setSketch({ activeObject: null, isEditing: false });
      },
      'object:moving': (options: fabric.IEvent) =>
        handleCanvasObjectMoving({ options }),
      'selection:created': (options: fabric.IEvent) => {
        const selectedObject = (options as fabric.IEvent).selected?.[0];
        if (selectedObject) {
          activeObjectRef.current = selectedObject;
          setSketch({ activeObject: selectedObject });
        }

        handleCanvasSelectionCreated({
          options,
          isEditing: isEditing as boolean,
          setElementAttributes,
        });
      },
      'selection:updated': (options: fabric.IEvent) => {
        const selectedObject = (options as fabric.IEvent).selected?.[0];
        if (selectedObject) {
          activeObjectRef.current = selectedObject;
          setSketch({ activeObject: selectedObject });
        }

        handleCanvasSelectionCreated({
          options,
          isEditing: isEditing as boolean,
          setElementAttributes,
        });
      },
      'object:scaling': (options: fabric.IEvent) =>
        handleCanvasObjectScaling({ options, setElementAttributes }),
    };

    Object.entries(eventHandlers).forEach(([event, handler]) => {
      canvas.on(event as any, handler as any);
    });

    return () => {
      Object.entries(eventHandlers).forEach(([event, handler]) => {
        canvas.off(event as any, handler as any);
      });

      canvas.dispose();

      fabricRef.current = null;
    };
  }, [canvasRef, fabricRef]);

  useEffect(() => {
    if (!canvas) return;
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, canvas]);
};
