import React, { useState } from 'react';
import { useOrganization } from '@clerk/nextjs';
import { CallToAction, Flex, Heading, Text, getDataIcons } from '@nex/labs';
import { Member } from './components/member';
import { AddMemberModal } from './components/add-member';

import { Invitation } from './components/invitation';

export const Members = () => {
  const [isAddingMember, setIsAddingMember] = useState(false);
  const {
    memberships,
    invitations,
    isLoaded: organizationIsLoaded,
  } = useOrganization({
    memberships: {
      pageSize: 5,
      keepPreviousData: true,
    },
    invitations: {
      pageSize: 5,
      keepPreviousData: true,
    },
  });

  return (
    <>
      <AddMemberModal
        show={isAddingMember}
        onClose={() => {
          setIsAddingMember(false);
        }}
      />
      <Flex.Column gap={25}>
        <Flex flexWrap="wrap" justifyContent="space-between" gap={12}>
          <span>
            <Flex gap={4}>
              {/* <Bookmark width={16} className="opacity-80" />{" "} */}
              <Heading.h5 weight={700}>Members</Heading.h5>
            </Flex>

            <Text className="opacity-80 mt-1">
              Manage your existing members and change roles & permissions
            </Text>
          </span>

          <CallToAction.button
            size="xs"
            className="h-fit"
            variant="secondary"
            leadingIcon={<img src={getDataIcons('add', '#000')} />}
            onClick={() => setIsAddingMember(true)}
          >
            Add New Member
          </CallToAction.button>
        </Flex>

        <table className="w-full text-sm text-left rtl:text-right ">
          <thead className="text-xs uppercase font-semibold">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>
              <th scope="col" className="px-6 py-3" />
            </tr>
          </thead>
          {organizationIsLoaded && (
            <tbody>
              {memberships?.data?.map((membership) => (
                <Member
                  membership={membership}
                  key={membership.id}
                  onUpdateMember={async () => {
                    await memberships?.revalidate();
                  }}
                />
              ))}
              {invitations?.data?.map((invitation) => (
                <Invitation
                  invitation={invitation}
                  key={invitation.id}
                  onUpdateInvite={async () => {
                    await memberships?.revalidate?.();
                    await invitations?.revalidate?.();
                  }}
                />
              ))}
            </tbody>
          )}
        </table>
      </Flex.Column>
    </>
  );
};
