import React, { useCallback, useEffect, useMemo } from 'react';

import classNames from 'classnames';
import Router from 'next/router';

import {
  Avatar,
  CallToAction,
  Flex,
  Heading,
  Modal,
  Text,
  Img,
  getDataIcons,
  Popover,
  Tooltip,
  useToast,
} from '@nex/labs';
import { AnimatePresence, motion } from 'framer-motion';
import { usePostHog } from 'posthog-js/react';
import analytics from '@lib/analytics';
import { useOrganization } from '@clerk/nextjs';

import {
  type Generation,
  ImageReactionType,
  type Prompt,
} from '@nex/types/lib/prompt';

import { useGlobalStore, useUserStore } from '@/state/useStore';

import Sparkles from '@nex/icons/svg/misc/sparkles.svg';
import Thumbs from '@nex/icons/svg/misc/thumbs-up-alt.svg';
import Download from '@nex/icons/svg/misc/download-alt.svg';
import More from '@nex/icons/svg/misc/more-alt.svg';

import { useReactToImageMutation } from '@/state/query/prompt';

import { CreateCollectionModal } from './create-collection-modal';

import styles from './modals.module.scss';
import { Composition, Image } from '@nex/types/lib/artboard';

export const ResultModal = ({
  result,
  onImageClick,
  showAll,
}: {
  result?: any;
  onImageClick?: (index: number) => void;
  showAll?: boolean;
}) => {
  const { modal, closeModal } = useGlobalStore();

  const { profile } = useUserStore();
  const [showMeta, setShowMeta] = React.useState(false);
  const [collectionOpen, setCollectionOpen] = React.useState(false);
  const { data, parent, timeline, deleteFromCollection } =
    result ?? modal?.props;
  const [current, setCurrent] = React.useState(
    parent?.generations?.findIndex((d: any) => d.id === data?.id) ?? 0
  );

  const collectionPrompSession = parent?.generations[current]?.prompt;

  const move = useCallback(
    (direction: 'left' | 'right') => {
      if (direction === 'left') {
        setCurrent((prev: number) => Math.max(prev! - 1, 0));
      }

      if (direction === 'right') {
        setCurrent((prev: number) =>
          Math.min(prev! + 1, parent?.generations?.length - 1)
        );
      }
    },
    [parent?.generations?.length]
  );

  useEffect(() => {
    // use arrow left and right to navigate
    const handleKeyPress = (e: any) => {
      if (e.key === 'ArrowLeft') {
        move('left');
      }

      if (e.key === 'ArrowRight') {
        move('right');
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [move]);

  return (
    <>
      <div className={styles.Results}>
        {(parent?.caption || collectionPrompSession?.caption) && !showAll && (
          <Flex.Column alignItems="center">
            <Flex
              gap={8}
              justifyContent="center"
              alignItems="flex-start"
              className="max-w-[500px] m-auto"
            >
              <Sparkles
                width="16px"
                className="h-auto min-w-[16px] sparkles-green"
              />
              <Heading.h5 weight={800} className="text-center">
                <Text.span inheritFont>
                  &quot;
                  {parent?.caption || collectionPrompSession?.caption}
                  &quot;
                </Text.span>
              </Heading.h5>
            </Flex>

            <Flex gap={18} alignItems="center">
              {result && (
                <Flex gap={18} alignItems="center" className="mt-3 mb-2">
                  <Avatar
                    src={[timeline?.user?.photo || profile?.photo!]}
                    size={30}
                  />
                  <Text weight={600} className="opacity-70">
                    {timeline?.user?.name}
                  </Text>
                </Flex>
              )}

              {!Router?.asPath?.includes('/generate') && !result && (
                <a href={`/generate/${parent?.promptSessionId ?? parent?.id}`}>
                  <Text
                    weight={700}
                    className="opacity-80 mt-1"
                    color="var(--primary-theme)"
                  >
                    View Playground{' '}
                    <img
                      src={getDataIcons('external-link', '#30d95e')}
                      alt=""
                      width={12}
                      className="inline ml-1"
                    />
                  </Text>
                </a>
              )}
            </Flex>
          </Flex.Column>
        )}

        {!showAll && (
          <div className={styles.ResultsImageSlate}>
            <AnimatePresence initial={false} mode="wait">
              <motion.div
                key={current}
                initial={{ opacity: 0, x: -4 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 4 }}
                className={styles.ResultsImage}
              >
                <Img
                  src={parent?.generations[current]?.url}
                  alt={parent?.caption}
                />
              </motion.div>
            </AnimatePresence>

            {!result && (
              <Flex
                justifyContent="center"
                alignItems="center"
                gap={12}
                flexWrap="wrap"
                className="mt-3 mb-5"
              >
                <CallToAction.button
                  size="sm"
                  variant="secondary"
                  download={`${parent?.generations[current]?.url}`}
                >
                  <Download width={16} />
                </CallToAction.button>

                {typeof deleteFromCollection === 'function' && (
                  <CallToAction.button
                    size="sm"
                    variant="clear"
                    outline
                    onClick={() =>
                      deleteFromCollection(parent?.generations[current]?.id)
                    }
                  >
                    Delete from Collection
                  </CallToAction.button>
                )}

                {parent?.caption && parent?.generations?.length && (
                  <Popover hug>
                    <Popover.Trigger>
                      <CallToAction.button size="sm" variant="secondary">
                        <More />
                      </CallToAction.button>
                    </Popover.Trigger>
                    <Popover.Content isDropdown>
                      <button onClick={() => setShowMeta(true)}>
                        See details
                      </button>
                    </Popover.Content>
                  </Popover>
                )}
                <CallToAction.a
                  href={`/enhance?img=${parent?.generations[current]?.url}`}
                  size="sm"
                  variant="primary"
                  outline
                  onClick={() => closeModal()}
                  leadingIcon={<Sparkles height={12} />}
                  data-analytics="enhance_generation"
                >
                  Enhance
                </CallToAction.a>
                {parent?.caption && parent?.generations?.length && (
                  <>
                    <CallToAction.button
                      size="sm"
                      onClick={() => setCollectionOpen(true)}
                    >
                      Save to Collection
                    </CallToAction.button>
                    <Vote data={parent?.generations[current]} />
                  </>
                )}
              </Flex>
            )}

            <Flex
              flexWrap="wrap"
              gap={12}
              alignItems="center"
              scrollable
              className="max-w-[90%] m-auto"
              justifyContent="center"
            >
              {parent?.generations?.map((generation: Generation) => (
                <button
                  type="button"
                  key={generation?.id}
                  style={{
                    border:
                      generation?.id === parent?.generations[current]?.id
                        ? '1px solid var(--primary-theme)'
                        : '',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrent(
                      parent?.generations?.findIndex(
                        (d: Generation) => d.id === generation?.id
                      )
                    );
                  }}
                  className={styles.ResultsImageSlateButton}
                >
                  <Img src={generation?.url} alt={data?.caption} />
                </button>
              ))}
            </Flex>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              gap={12}
              flexWrap="wrap"
              className={styles.ResultsActions}
            >
              <CallToAction.button
                size="sm"
                variant="secondary"
                onClick={() => setCurrent((prev: number) => prev - 1)}
                disabled={current === 0}
                style={{
                  padding: '10px 6px',
                }}
              >
                <img
                  src={getDataIcons('arrow-thin-down')}
                  alt="arrow"
                  className="rotate-90"
                  width={16}
                />
              </CallToAction.button>

              <CallToAction.button
                size="sm"
                variant="secondary"
                onClick={() => setCurrent((prev: number) => prev + 1)}
                disabled={current === parent?.generations?.length - 1}
                style={{
                  padding: '10px 6px',
                }}
              >
                <img
                  src={getDataIcons('arrow-thin-down')}
                  alt="arrow"
                  className="-rotate-90"
                  width={16}
                />
              </CallToAction.button>
            </Flex>
          </div>
        )}
      </div>

      <CreateCollectionModal
        data={parent?.generations[current]}
        show={collectionOpen}
        onClose={() => setCollectionOpen(false)}
      />
    </>
  );
};

type WorkspaceMetadata = {
  externalId: string;
};

export const Vote = ({ data }: { data: Image }) => {
  const { createToast } = useToast();
  const posthog = usePostHog();
  const [reaction, setReaction] = React.useState<ImageReactionType | undefined>(
    data?.reaction
  );
  const { profile } = useUserStore();

  const { mutate: react, isLoading } = useReactToImageMutation({
    onSuccess: () => {
      analytics.track('User Reacted', {
        eventAction: `User Voted ${reaction}`,
        eventLabel: `User reacted to ${data?.id} - ${data?.url}`,
        eventValue: `${data?.id} - ${data?.url}`,
      });
      posthog.capture('prompt_generation_reaction', {
        reaction: data.reaction,
      });

      createToast({
        message: 'Thank you for your feedback!',
      });
    },
    onError: (err: any) => {
      createToast({
        message: err?.message ?? 'Something went wrong',
        variant: 'error',
      });
      setReaction(data?.reaction);
    },
  });

  useEffect(() => {
    setReaction(data?.reaction);
  }, [data]);

  return (
    <Tooltip
      tooltip={
        'This helps us improve our AI and provide better results for you.'
      }
    >
      <Flex gap={12} alignItems="center" className="ml-3">
        <button
          type="button"
          onClick={() => {
            if (reaction === ImageReactionType.LIKE) return;
            setReaction(ImageReactionType.LIKE);
            react({
              imageId: data?.id,
              workspaceId: profile?.organizationId!,
              reaction: ImageReactionType.LIKE,
            });
          }}
          className={classNames([
            reaction === ImageReactionType.LIKE && styles.VoteActive,
          ])}
          disabled={isLoading}
        >
          <Thumbs className="opacity-70" width={20} />
        </button>

        <button
          type="button"
          onClick={() => {
            if (reaction === ImageReactionType.DISLIKE) return;
            setReaction(ImageReactionType.DISLIKE);
            react({
              imageId: data?.id,
              workspaceId: profile?.organizationId!,
              reaction: ImageReactionType.DISLIKE,
            });
          }}
          className={classNames([
            reaction === ImageReactionType.DISLIKE && styles.VoteActiveDown,
          ])}
          disabled={isLoading}
        >
          <Thumbs className="rotate-180 opacity-70" width={20} />
        </button>
      </Flex>
    </Tooltip>
  );
};
