import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { formatDistanceToNow } from 'date-fns';

import {
  CallToAction,
  Field,
  Flex,
  Heading,
  Modal,
  Text,
  pluralize,
  useToast,
  useDebounce,
} from '@nex/labs';

import { type Collection } from '@nex/types/lib/prompt';
import analytics from '@lib/analytics';

import {
  useCreateCollectionMutation,
  useGetCollectionQuery,
  useUpdateCollectionMutation,
} from '@/state/query/prompt';

import { FetchContainer } from '@/components/misc/fetch-container';

import Sparkles from '@nex/icons/svg/misc/sparkles.svg';

import { usePostHog } from 'posthog-js/react';

import { useUserStore } from '@/state/useStore';

import styles from '../collection.module.scss';

export const CreateCollectionModal = ({
  show,
  onClose,
  type,
  data,
}: {
  show: boolean;
  type?: any;
  data?: any;
  onClose: () => void;
}) => {
  const { createToast } = useToast();
  const [search, setSearch] = React.useState('');
  const [createNew, setCreateNew] = React.useState(
    typeof type !== 'undefined' ? !!type : false
  );
  const posthog = usePostHog();
  const { profile } = useUserStore();
  const [formDetails, setFormDetails] = React.useState<any>({
    name: '',
    description: '',
  });

  const _search = useDebounce(search, 500);
  const {
    data: _data,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useGetCollectionQuery(
    {
      search: _search,
      workspaceId: profile?.organizationId,
    },
    {
      enabled: show,
    }
  );

  const [currentCollection, setCurrentCollection] = React.useState<
    Collection[]
  >([]);

  const handleClose = useCallback(() => {
    setCurrentCollection([]);
    return onClose();
  }, [onClose]);

  const { mutate: createCollection, isLoading: isCollectionLoading } =
    useCreateCollectionMutation({
      onSuccess: (_data: any) => {
        if (type) {
          handleClose();

          analytics.track('Create new collection', {
            eventAction: `Create Collection - ${_data?.collection?.name}`,
            eventLabel: `A new collection was created (${_data?.collection?.name})`,
            eventValue: `Collection was created ${_data?.collection}`,
          });

          posthog.capture('collection_created_success');
        } else {
          analytics.track('Create and add to collection', {
            eventAction: `Create and Add - ${_data?.collection?.name}`,
            eventLabel: `A new collection was created (${_data?.collection?.name})`,
            eventValue: `${setGenerations(data)} was Added`,
          });

          posthog.capture('collection_generation_created_sucess');
          posthog.capture('collection_added_generation');
        }

        createToast({
          message: 'Collection created successfully',
          variant: 'secondary',
        });

        setCurrentCollection([]);
        setFormDetails({
          name: '',
          description: '',
        });
        setCreateNew(false);
      },
      onError: (err: any) => {
        posthog.capture('collection_created_error');
        createToast({
          message: err?.message ?? 'Something went wrong, please try again.',
          variant: 'error',
        });
      },
    });

  const { mutate: updateCollection, isLoading: isUpdateCollectionLoading } =
    useUpdateCollectionMutation({
      onSuccess: (_data) => {
        if (type) {
          createToast({
            message: 'Collection updated successfully',
            variant: 'secondary',
          });
        } else {
          posthog.capture('collection_added_generation');
          analytics.track('Save to Existing collection', {
            eventAction: `Update and Add - ${_data?.collection?.name}`,
            eventLabel: `A new Image was added to collection (${_data?.collection?.name})`,
            eventValue: `${setGenerations(data)} was Added`,
          });

          createToast({
            message: 'Generation added to collection successfully',
            variant: 'secondary',
          });
        }
      },
      onError: (err: any, data: any) => {
        posthog.capture('collection_added_generation_error');
        setCurrentCollection((prev) => {
          return prev.filter((cl: any) => cl.id !== data.id);
        });

        createToast({
          message: err?.message ?? 'Something went wrong',
          variant: 'error',
        });
      },
    });

  const setCurrentCollections = (collection: any) => {
    const timeout = setTimeout(() => {
      updateCollection({
        id: collection?.id,
        type: 'add',
        images: setGenerations(data),
        workspaceId: profile?.organizationId!,
      });
    }, 500);

    setCurrentCollection((prev: any) => {
      if (prev?.find((cl: any) => cl.id === collection.id)) {
        clearTimeout(timeout);
        return prev.filter((cl: any) => cl.id !== collection.id);
      } else {
        return [...prev, collection];
      }
    });
  };

  useEffect(() => {
    if (type === 'edit') {
      setFormDetails({
        name: data?.name,
        description: data?.description,
      });
    }
  }, [type, data]);

  const setGenerations = (data: any) => {
    return [
      data?.images?.length > 0 ? data?.images?.map((d: any) => d.id) : data?.id,
    ]
      .flat()
      .filter(Boolean);
  };

  return (
    <Modal in={show} onClose={handleClose} size="md" title="Collection">
      {!createNew && !type ? (
        <>
          <Heading.h5 weight={700} className="mb-5">
            <Sparkles width={18} className="inline" /> Add generation to a
            collection
          </Heading.h5>

          {_data?.collections && _data?.collections?.length > 0 && (
            <Field.Input
              placeholder="Search in collections"
              value={search}
              wrapperClassName="mb-5"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          )}

          <FetchContainer
            isLoading={isLoading || isFetching}
            useButton
            fetchNextPage={fetchNextPage}
            hasMore={hasNextPage}
            shouldBeEmpty={
              !!_data?.collections && _data?.collections?.length === 0
            }
            emptyMessage="You have no collections yet."
          >
            <Flex.Column gap={18}>
              {_data?.collections?.map((collection: any) => (
                <button
                  className={classNames([
                    styles.CollectionItem,
                    currentCollection?.find((cl) => cl.id === collection.id)
                      ? styles.active
                      : '',
                  ])}
                  key={collection?.id}
                  onClick={() => {
                    setCurrentCollections(collection);
                  }}
                >
                  <Flex alignItems="flex-start" gap={12} flexWrap="wrap">
                    <img
                      src={collection?.images?.[0]?.url || '/images/empty.png'}
                      width={80}
                      height={80}
                      alt={collection?.name}
                    />

                    <Flex.Column gap={2} flex="1">
                      <Heading.h6 weight={800} noOfLines={1}>
                        {collection?.name}
                      </Heading.h6>
                      <Text>
                        {pluralize('generation', collection?.images?.length)} in
                        this collection
                      </Text>
                      <Text className="opacity-70" fontSize="12px">
                        Updated{' '}
                        {collection?.updatedAt &&
                          formatDistanceToNow(new Date(collection?.updatedAt))}
                        {' ago'}
                      </Text>
                    </Flex.Column>
                  </Flex>
                </button>
              ))}
            </Flex.Column>
          </FetchContainer>
          <Flex
            className="mt-10"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            gap={12}
          >
            <CallToAction.button
              size="sm"
              variant="secondary"
              onClick={(e) => {
                e.preventDefault();
                setCreateNew(true);
              }}
            >
              Create New Collection
            </CallToAction.button>
            <CallToAction.button
              size="sm"
              isLoading={isUpdateCollectionLoading}
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </CallToAction.button>
          </Flex>
        </>
      ) : (
        <>
          <Heading.h6 weight={700} className="mb-5">
            {type === 'edit' ? 'Edit' : 'Create a new'} Collection
          </Heading.h6>
          <Field.Form
            onSubmit={async (e) => {
              e.preventDefault();

              if (type === 'edit') {
                await updateCollection({
                  id: data?.id,
                  name: formDetails.name,
                  description: formDetails.description,
                  workspaceId: profile?.organizationId!,
                });

                return;
              }

              await createCollection({
                name: formDetails.name,
                description: formDetails.description,
                images: setGenerations(data),
                workspaceId: profile?.organizationId!,
              });
            }}
          >
            <Field.Input
              name="name"
              placeholder="Enter a name for your collection"
              required
              value={formDetails.name}
              onChange={(e) => {
                setFormDetails((prev: any) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
            />
            <Field.Input
              name="description"
              placeholder="Enter a description for your collection"
              value={formDetails.description}
              onChange={(e) => {
                setFormDetails((prev: any) => ({
                  ...prev,
                  description: e.target.value,
                }));
              }}
            />

            <br />
            <Flex alignItems="center" flexWrap="wrap" gap={12}>
              <CallToAction.button
                size="sm"
                isLoading={isCollectionLoading || isUpdateCollectionLoading}
                disabled={
                  !formDetails.name ||
                  (type === 'edit' &&
                    formDetails.name === data?.name &&
                    formDetails.description === data?.description)
                }
              >
                {type === 'edit'
                  ? 'Update'
                  : `${data?.id ? 'Create and Add' : 'Create'}`}
              </CallToAction.button>
              <CallToAction.button
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  if (type) {
                    handleClose();
                  } else {
                    setCreateNew(false);
                  }
                }}
                variant="secondary"
              >
                Cancel
              </CallToAction.button>
            </Flex>
          </Field.Form>
        </>
      )}
    </Modal>
  );
};
