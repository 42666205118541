import { ContextMenu } from '@nex/labs';
import { fabric } from 'fabric';

import { handleDelete } from '../../lib/key-events';
import { bringElement } from '../../lib/shapes';

export const Sketcher = ({
  canvasRef,
  fabricRef,
  handleActions,
  syncShapeInStorage,
  deleteShapeFromStorage,
}: {
  canvasRef: React.RefObject<HTMLCanvasElement> | null;
  fabricRef: React.RefObject<fabric.Canvas>;
  syncShapeInStorage: (shape: fabric.Object) => void;
  handleActions: (action: string) => void;
  deleteShapeFromStorage: (id: string) => void;
}) => {
  return (
    <main className="h-screen overflow-hidden flex justify-center items-center p-[12px]">
      <ContextMenu.Root>
        <ContextMenu.Trigger>
          <section
            className="bg-[var(--primary-white)] rounded-md relative overflow-hidden box-shadow-[-1px 0px 3px rgba(0, 0, 0, 0.05)] max-h-[98.5%]"
            id="canvas"
          >
            <canvas ref={canvasRef} />
          </section>
        </ContextMenu.Trigger>
        <ContextMenu.Content>
          <ContextMenu.Item
            data-action="bring-to-front"
            onClick={() => {
              bringElement({
                canvas: fabricRef.current as fabric.Canvas,
                direction: 'front',
                syncShapeInStorage,
              });
            }}
          >
            Bring to Front
          </ContextMenu.Item>
          <ContextMenu.Item
            data-action="bring-forward"
            onClick={() => {
              bringElement({
                canvas: fabricRef.current as fabric.Canvas,
                direction: 'back',
                syncShapeInStorage,
              });
            }}
          >
            Send to Back
          </ContextMenu.Item>

          <ContextMenu.Separator />
          <ContextMenu.Item
            data-action="copy"
            shortcut="⌘ C"
            onClick={() => {
              handleActions('copy');
            }}
          >
            Copy
          </ContextMenu.Item>

          <ContextMenu.Item
            shortcut="⌘ V"
            data-action="paste"
            onClick={() => {
              handleActions('paste');
            }}
          >
            Paste
          </ContextMenu.Item>
          <ContextMenu.Separator />
          <ContextMenu.Item
            shortcut="⌘ ⌫"
            color="red"
            data-action="delete"
            onClick={() => {
              handleDelete(
                fabricRef.current as fabric.Canvas,
                deleteShapeFromStorage
              );
            }}
          >
            Delete
          </ContextMenu.Item>
        </ContextMenu.Content>
      </ContextMenu.Root>
    </main>
  );
};
