import { type TUser } from '@nex/types';
import Cookies from 'js-cookie';
import { MyStateCreator } from './store';

export type UserSlice = {
  profile: TUser;
  isLoading: boolean;
  actions: {
    logOut: () => void;
    setProfileLoading: (loading: boolean) => void;
    setUser: ({
      user,
      update,
    }: {
      user: UserSlice['profile'];
      update?: boolean;
    }) => void;
  };
};

const initialState: UserSlice['profile'] = {
  name: '',
  email: null,
  credit: 0,
  photo: '',
  profession: null,
  organizationId: '',
  onboardingChecklist: {},
};

export const createUserSlice: MyStateCreator<UserSlice> = (set, get) => ({
  profile: initialState,
  isLoading: false,
  actions: {
    setUser: ({ user, update = false }) => {
      set((state) => {
        state.user.profile = {
          ...(update ? get().user.profile : {}),
          ...user,
        };
      });
    },

    setProfileLoading: (loading: boolean) => {
      set((state) => {
        state.user.isLoading = loading;
      });
    },
    logOut: () => {
      set((state) => {
        state.user.profile = {};
        state.global.overlayLoading = {
          loading: true,
          message: 'Please wait...',
        };
      });
      Cookies.remove('__session');
    },
  },
});
