import type React from 'react';
import { type PropsWithChildren } from 'react';

import { Seo } from '@/components/seo';
import { motion } from 'framer-motion';
import { transitions } from '@nex/labs';
import { Layout } from '../base-layout';

import styles from './auth-layout.module.scss';

export const AuthLayout: React.FC<
  Partial<
    {
      title: string;
    } & PropsWithChildren
  >
> = ({ children, title }) => {
  const getSlide = transitions.useSlide();

  return (
    <Layout>
      <Seo title={title} />
      <div className={styles.AuthLayout}>
        <motion.main {...getSlide({ y: 10, scale: 0.98 })}>
          {children}
        </motion.main>
      </div>
    </Layout>
  );
};
