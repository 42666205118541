import { merge } from 'lodash';
import { create, StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { createGlobalSlice, GlobalSlice } from './global';
import { createUserSlice, UserSlice } from './user';
import { createEnhanceSlice, EnhanceSlice } from './enhance';
import { ArtboardSlice, createArtboardSlice } from './artboard';

export type StoreType = {
  global: GlobalSlice;
  user: UserSlice;
  enhance: EnhanceSlice;
  artboard: ArtboardSlice;
};

export const storeState = {
  global: createGlobalSlice,
  user: createUserSlice,
  enhance: createEnhanceSlice,
  artboard: createArtboardSlice,
};

type SetFunction = <
  K1 extends keyof StoreType,
  K2 extends keyof StoreType[K1] = keyof StoreType[K1],
>(
  partial:
    | StoreType
    | Partial<StoreType>
    | ((state: StoreType) => void | Partial<StoreType>),
  replace?: boolean
) => void;

type GetFunction = () => StoreType;

export type MyStateCreator<T> = (
  set: SetFunction,
  get: GetFunction,
  api: {
    setState: SetFunction;
    getState: GetFunction;
  }
) => T;

export const useAppState = create<StoreType>()(
  persist(
    immer(
      // @ts-expect-error used for debugging
      devtools((...a) => {
        return Object.keys(storeState).reduce((acc, key) => {
          return {
            ...acc,
            [key]: storeState[key as keyof StoreType](...a),
          };
        }, {});
      })
    ),
    {
      name: '[nex-app]',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        user: {
          profile: state.user.profile,
        },
      }),
      merge: (persistedState, currentState) => {
        const result = merge(currentState, persistedState);
        return result;
      },
    }
  )
);

// @ts-expect-error used for debugging
if (typeof window !== 'undefined') window.getState = useAppState.getState;
