import React from 'react';
import styles from './pricing.module.scss';
import { CallToAction, Flex, Heading, Tabs, Text } from '@nex/labs';
import { PLANS, subscriptionPlans } from '../../utils';
import { usePlans } from '../plans';
import Logo from '@nex/icons/svg/logo.svg';
import classNames from 'classnames';
import { usePostHog } from 'posthog-js/react';

export const ProUpSell = () => {
  const { handleAmountButtonClick, isAnnual, setIsAnnual, isLoading } =
    usePlans();
  const postHog = usePostHog();
  const plan = subscriptionPlans(isAnnual ? 'annually' : undefined).find(
    (p) => p.key === 'PROFESSIONAL'
  )!;

  return (
    <Flex.Row justifyContent="space-between" gap={32}>
      <Flex.Column className={styles.ProPlans} alignItems="flex-start" flex="1">
        <Logo className="h-[40px] w-auto mb-[74px]" />
        <Heading.h5 weight={500} casing="capitalize" className="-mb-1">
          Upgrade to
        </Heading.h5>
        <Heading.h3 weight={800} casing="capitalize" className="-mb-1">
          {plan?.name}
        </Heading.h3>
        <Heading.h3 className="mb-4">
          {isAnnual && (
            <Text.span
              decoration="line-through"
              inheritFont
              className="opacity-70 mr-2 "
            >
              {PLANS['monthly'].PROFESSIONAL}
            </Text.span>
          )}
          {typeof plan.price === 'number' ? `$${plan.price}/mo` : plan.price}
        </Heading.h3>
        <div>
          <Tabs
            defaultTab={isAnnual ? 'yearly' : 'monthly'}
            size="sm"
            centered={false}
            onChangeTab={(tab) => {
              setIsAnnual(tab === 'yearly' ? true : false);
            }}
          >
            <Tabs.Tab dataKey="monthly" title="Monthly" />
            <Tabs.Tab dataKey="yearly" title="Yearly" />
          </Tabs>
        </div>

        <div className={styles.Plans}>
          <div className={styles.PlanBody}>
            <ul>
              {plan?.features.map((feature) => (
                <li key={feature} className="flex gap-[8px] items-start">
                  <img
                    src="data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.2283 0.391887L5.08216 10.2591L2.38974 7.36037C1.89377 6.88914 1.11439 6.86058 0.547561 7.26041C-0.00509311 7.67452 -0.16097 8.40279 0.179125 8.98825L3.36752 14.2146C3.67927 14.7001 4.21775 15 4.82709 15C5.40809 15 5.96074 14.7001 6.27249 14.2146C6.78264 13.5435 16.5179 1.84841 16.5179 1.84841C17.7932 0.534683 16.2486 -0.62197 15.2283 0.377607V0.391887Z' fill='%230C0C0C'/%3E%3C/svg%3E%0A"
                    alt=""
                    className="w-[14px] h-[14px] mt-[4px]"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <CallToAction.button
          size="sm"
          className="w-full"
          onClick={() => {
            postHog.capture('pro_upsell_upgrade_plan', { plan: plan.key });
            handleAmountButtonClick(plan.key);
          }}
          isLoading={isLoading}
        >
          Upgrade
        </CallToAction.button>
        <CallToAction.a
          size="sm"
          className="w-full"
          variant="clear"
          onClick={() => {
            postHog.capture('pro_upsell_compare_plans');
          }}
          href="/settings/plans"
        >
          Compare all plans
        </CallToAction.a>
      </Flex.Column>
      <img
        src="/images/misc/pricing/modal.png"
        className={classNames([styles.ProPlanModalImage, 'hidden lg:block'])}
      />
    </Flex.Row>
  );
};
