import { useMemo } from 'react';
import { Avatar, CallToAction, Flex, Img, Text, truncateText } from '@nex/labs';
import { useProcessingComposition } from '@/state/query/block/hooks/use-processing-composition';
import { CompositionStatus } from '@nex/types/sketch';
import { useArtboardStore } from '@/state/useStore';

import styles from '../generations/generations.module.scss';

type CompositionProps = {
  composition: Record<string, any>;
};

export const Composition = ({ composition }: CompositionProps) => {
  const isProcessing = useProcessingComposition(composition.id);
  const { setCurrentGeneration, reuseBlock } = useArtboardStore();

  const isProcessingComposition = useMemo(
    () => isProcessing && composition.status === CompositionStatus.PROCESSING,
    [isProcessing, composition.status]
  );

  return (
    <div
      className={styles.BlockPaneGeneration}
      key={`${composition.id}:grp_artboard`}
    >
      {isProcessingComposition ? null : (
        <>
          {composition.status === CompositionStatus.FAILED && (
            <Flex.Column
              gap={8}
              className="bg-[var(--primary-white)] p-3 rounded-md"
            >
              <Text weight={700}>
                Something went wrong with this generation
              </Text>
              <CallToAction
                size="xs"
                variant="secondary"
                onClick={() => {
                  reuseBlock(composition);
                }}
              >
                Reuse Block
              </CallToAction>
            </Flex.Column>
          )}
          {composition.status === CompositionStatus.COMPLETED && (
            <Flex
              gap={8}
              alignItems="center"
              className={styles.BlockPaneGenerationImages}
            >
              {composition?.images?.map((image: any, i: number) => (
                <div
                  key={`${image?.key}:img_action:${composition?.id}`}
                  className={styles.BlockPaneGenerationImageActions}
                  role="button"
                  onMouseDown={(e: any) => {
                    if (e.target.tagName !== 'IMG') return;
                    setCurrentGeneration({
                      ...image,
                      parent: composition,
                    });
                  }}
                  onKeyDown={(e: any) => {
                    if (e.target.tagName !== 'IMG') return;
                    setCurrentGeneration({
                      ...image,
                      parent: composition,
                    });
                  }}
                  tabIndex={0}
                >
                  {composition?.creator?.id && i === 0 ? (
                    <Flex alignItems="center" className={styles.BlockUser}>
                      <Avatar
                        src={composition?.creator?.photo}
                        alt={composition?.creator?.name}
                        size={20}
                        style={{ borderRadius: '50%' }}
                      />{' '}
                      <Text fontSize="12px" noOfLines={1}>
                        {truncateText(composition?.creator?.name, 30, false)}
                      </Text>
                    </Flex>
                  ) : null}

                  <Img
                    src={image.url}
                    alt={'generation image'}
                    className={styles.GenerationImg}
                  />
                </div>
              ))}
            </Flex>
          )}
        </>
      )}
    </div>
  );
};
