import { APP_URL } from '@lib/config';
import Head from 'next/head';
import { useRouter } from 'next/router';

const DEFAULT_OG_IMAGE =
  'https://cdn.prod.website-files.com/6673557cfe2f26731e896ff9/667d2588e38eb57331b7867a_OG%20image03.jpg';

interface SeoProps {
  title?: string;
  description?: string;
  siteName?: string;
  canonical?: string;
  ogImage?: string;
  ogType?: string;
  twitterHandle?: string;
  noIndex?: boolean;
  forceSEO?: boolean;
}

export function Seo({
  title = 'NEX: Controllable AI Image Generation for All',
  description = `Use our controllable and IP-consistent AI media suite for realistic, pose, and character consistent generation for free today! Our custom model development offerings are perfect for teams & enterprises looking to leverage AI to do their best work. NEX control features are higher fidelity than ControlNets or other control features.`,
  siteName = 'NEX',
  canonical = APP_URL,
  ogImage = DEFAULT_OG_IMAGE,
  ogType = 'website',
  twitterHandle = 'NEXOutreach',
  noIndex = false,
}: SeoProps) {
  const router = useRouter();
  const isHome = router.pathname === '/';

  return (
    <Head>
      <>
        <title>{`${title ? `${title} |` : ''} ${siteName}`}</title>
        <meta name="description" content={description} />
        <meta key="og_type" property="og:type" content={ogType} />
        <meta key="og_title" property="og:title" content={title} />
        <meta
          key="og_description"
          property="og:description"
          content={description}
        />
        <meta key="og_locale" property="og:locale" content="en_IE" />
        <meta key="og_site_name" property="og:site_name" content={siteName} />
        <meta key="og_url" property="og:url" content={canonical ?? APP_URL} />
        <meta key="og_site_name" property="og:site_name" content={siteName} />
        <meta
          key="og_image"
          property="og:image"
          content={ogImage ?? DEFAULT_OG_IMAGE}
        />
        <meta
          key="og_image:alt"
          property="og:image:alt"
          content={`${title} | ${siteName}`}
        />
        <meta key="og_image:width" property="og:image:width" content="1200" />
        <meta key="og_image:height" property="og:image:height" content="630" />

        <meta name="robots" content="index,follow" />
        <meta name="apple-mobile-web-app-title" content="Nex" />
        <meta name="keywords" content="Nex" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
        <meta content="#171717" name="theme-color" />
        <meta content="#171717" name="msapplication-TileColor" />

        <meta
          key="twitter:card"
          name="twitter:card"
          content="summary_large_image"
        />
        <meta key="twitter:site" name="twitter:site" content={twitterHandle} />
        <meta
          key="twitter:creator"
          name="twitter:creator"
          content={twitterHandle}
        />
        <meta key="twitter:title" property="twitter:title" content={title} />
        <meta
          key="twitter:description"
          property="twitter:description"
          content={description}
        />

        <meta key="twitter:domain" name="twitter:domain" content={APP_URL} />

        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/favicon.ico" />

        <link
          href="/opensearch.xml"
          rel="search"
          title={`Search on NEX`}
          type="application/opensearchdescription+xml"
        />

        {noIndex && <meta name="robots" content="noindex,follow" />}
        {isHome && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `
                ${JSON.stringify({
                  name: 'NEX',
                  url: APP_URL,
                  sameAs: [
                    'https://twitter.com/NEXOutreach',
                    'https://www.instagram.com/nexaiofficial/',
                    'https://discord.gg/vWn4Vug2f6',
                    'https://www.youtube.com/@nexgenart',
                  ],
                  '@type': 'WebSite',
                  publisher: {
                    '@type': 'Organization',
                    logo: {
                      '@type': 'ImageObject',
                      url: `${APP_URL}/og-image.png`,
                    },
                  },
                  '@context': 'https://schema.org',
                })}
                `,
            }}
          />
        )}
      </>

      <link rel="canonical" href={canonical ?? APP_URL} />
    </Head>
  );
}
