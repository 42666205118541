import {
  Avatar,
  CallToAction,
  Field,
  Flex,
  Heading,
  Modal,
  Spacer,
  Text,
  Tooltip,
  isPremiumUser,
  useFeedback,
} from '@nex/labs';

import { useCopyToClipboard } from '@nex/labs';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import FetchContainer from '@/components/misc/fetch-container';

import Money from '@nex/icons/svg/misc/money.svg';

import { APP_URL } from '@lib/config';

import {
  useGetReferralQuery,
  useManageBillingMutation,
  useReferFriendMutation,
  useUpdateProfileMutation,
} from '@/state/query/user';
import { useUserStore } from '@/state/useStore';

import { ConsoleNexBar } from '@/components/layout';

import { format, formatDistanceToNow } from 'date-fns';
import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';

import { UserProfile } from '@clerk/nextjs';

import styles from '../settings.module.scss';

type FormDetails = {
  name: string;
  email: string;
  profession: string;
  password: string;
  photo: string;
  photoBlob: any;
};

export const General = () => {
  const router = useRouter();
  const { profile, setUser, logOut } = useUserStore();
  const { createToast } = useFeedback();

  const [currentPage, setCurrentPage] = React.useState('account');
  const [formDetails, setFormDetails] = React.useState<FormDetails>({
    name: profile?.name || '',
    email: profile?.email || '',
    profession: profile?.profession || '',
    password: '',
    photo: profile?.photo || '',
    photoBlob: null,
  });

  const handleOnChange = useCallback((e: any) => {
    setFormDetails((prev: FormDetails) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  useEffect(() => {
    if (router.query?.page) {
      setCurrentPage(router.query?.page as string);
    }
  }, [router.query?.page]);

  const { mutate: updateProfile, isLoading } = useUpdateProfileMutation({
    onSuccess: (data) => {
      setUser({ user: data?.profile });

      createToast({
        message: 'Profile updated successfully',
        variant: 'secondary',
      });
    },
    onError: (err: any) => {
      createToast({
        message: err?.message ?? 'Something went wrong',
        variant: 'error',
      });
    },
  });

  return (
    <>
      <div className={styles.GeneralSettings}>
        {currentPage === 'account' && (
          <AccountSettings
            formDetails={formDetails}
            profile={profile}
            handleOnChange={handleOnChange}
            updateProfile={updateProfile}
            isLoading={isLoading}
          />
        )}

        {currentPage === 'referral' && <ReferralSettings profile={profile} />}

        {currentPage === 'plan' && <PlanSettings profile={profile} />}
      </div>
    </>
  );
};

const AccountSettings = ({}: any) => {
  return <UserProfile routing="hash" />;
};

const PlanSettings = ({ profile }: any) => {
  const { createToast } = useFeedback();

  const { mutate: manageBilling } = useManageBillingMutation({
    onSuccess: async (data) => {
      if (!data?.billing?.url) return;

      await new Promise((resolve, reject) => {
        setTimeout(() => {
          window.location.href = data.billing?.url;
          resolve(true);
        }, 500);
      });
    },
    onError: (err: any) => {
      createToast({
        message: err?.message ?? 'Something went wrong. Please try again',
        variant: 'error',
      });
    },
  });

  return (
    <div className={styles.GeneralSettingsContent}>
      <span>
        <Heading.h5 weight={700}>Manage Plan</Heading.h5>
        <Text className="opacity-80 mt-2 mb-5">
          Manage your subscription and billing.
        </Text>
      </span>

      <Text className=" mb-3" weight={700} fontSize="16px">
        Current Plan
      </Text>

      <Flex className="mb-5" gap={12}>
        <Money width={28} />

        <Flex.Column>
          <Text weight={700} fontSize="16px">
            {profile.subscription?.plan}
          </Text>
          <Text className="opacity-80 mt-1 mb-1">
            <span className="uppercase">
              {profile?.subscription?.currency ?? '$'}{' '}
            </span>
            {Number.parseInt(`${profile?.subscription?.price}`) / 100 || '0'}{' '}
            monthly
          </Text>
          <Text className="opacity-80 mb-4">
            Next billing date:{' '}
            {(profile?.subscription?.renewal &&
              format(
                new Date(profile?.subscription?.renewal!),
                'dd/MM/yyyy'
              )) ??
              'N/A'}
          </Text>

          {isPremiumUser(profile) && (
            <CallToAction
              size="sm"
              variant="secondary"
              onClick={() =>
                manageBilling({
                  returnUrl: `${window.location.origin}/console/settings/general`,
                })
              }
            >
              Manage Subscription
            </CallToAction>
          )}
        </Flex.Column>
      </Flex>

      <Text className="mb-1" weight={700} fontSize="16px">
        Your NEX Credit Bar
      </Text>

      <ConsoleNexBar profile={profile} />
      <Spacer divider size={24} direction="horizontal" />
      <Field.Form>
        <Text className="" weight={700} fontSize="16px">
          Cancel Subscription
        </Text>

        <CallToAction.button variant="error">
          Cancel Subscription
        </CallToAction.button>
      </Field.Form>
    </div>
  );
};

const ReferralSettings = ({}: any) => {
  const statusMap = {
    PENDING: 'User has received the invite but not registered',
    VERIFIED: 'User has registered but not verified',
    ACTIVATED:
      'User has verified their email and you have received the credits',
  };
  const { data: referrals, isLoading, isFetching } = useGetReferralQuery({});
  return (
    <div className={styles.GeneralSettingsContent}>
      <Heading.h5 weight={700} className="mb-4">
        Referral Settings
      </Heading.h5>
      <ReferralUpsell isSettings />

      <FetchContainer
        isLoading={isLoading || isFetching}
        shouldBeEmpty={!referrals?.referrals?.length}
        emptyMessage="You have no referral history"
      >
        <Flex.Column gap={4} className="mt-8">
          <Heading.h6 weight={700} className="mb-2">
            Referral History
          </Heading.h6>
        </Flex.Column>
        {referrals?.referrals?.length > 0 && !isLoading && (
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right ">
              <thead className="text-xs uppercase bg-[#2a2a2a]">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Referral
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Referral Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                </tr>
              </thead>
              {referrals?.referrals?.map((referral: any, index: number) => (
                <tbody key={index}>
                  <tr className="border-b bg-[#ffffff0a] border-gray-700">
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium  whitespace-nowrap text-white opacity-80"
                    >
                      {referral.email}
                    </td>
                    <td className="px-6 py-4 opacity-80">
                      {formatDistanceToNow(new Date(referral.createdAt), {
                        addSuffix: true,
                      })}
                    </td>
                    <td className="px-6 py-4 opacity-80">
                      <Tooltip
                        tooltip={
                          statusMap[referral.status as keyof typeof statusMap]
                        }
                      >
                        <span
                          className={classNames([
                            referral.status === 'ACTIVATED'
                              ? 'text-green-500'
                              : referral.status === 'VERIFIED'
                                ? 'text-yellow-500'
                                : 'text-red-500',
                          ])}
                        >
                          {referral.status}
                        </span>
                      </Tooltip>
                    </td>
                  </tr>
                </tbody>
              ))}{' '}
            </table>
          </div>
        )}
      </FetchContainer>

      <Flex.Column gap={4} className="mt-8">
        <Flex
          justifyContent="space-between"
          gap={8}
          flexWrap="wrap"
          alignItems="center"
        >
          <Heading.h6 weight={700}>How it works</Heading.h6>
          <Link href="/help">
            <Text color="var(--primary-theme)" weight={700}>
              Learn more
            </Text>
          </Link>
        </Flex>
        <ul className="list-disc list-outside">
          <Flex.Column gap={8}>
            <li>
              <Text className="opacity-80">
                Share your referral link with your friends
              </Text>
            </li>
            <li>
              <Text className="opacity-80">
                Your friend signs up using your referral link
              </Text>
            </li>
            <li>
              <Text className="opacity-80">
                Your friend verifies their email
              </Text>
            </li>
            <li>
              <Text className="opacity-80">
                You get up to 250 NEX credits for free
              </Text>
            </li>
          </Flex.Column>
        </ul>
      </Flex.Column>
    </div>
  );
};
export const ReferralUpsell = ({
  isSettings,
  text = 'Refer a friend to get up to 250 NEX credits for free',
}: {
  isSettings?: boolean;
  text?: string;
}) => {
  const { profile } = useUserStore();
  const posthog = usePostHog();
  const trackCTA = (name: string) => posthog.capture(`console_${name}_clicked`);

  const [copy, copiedText] = useCopyToClipboard({
    toast: true,
  });
  const { createToast } = useFeedback();
  const [showModal, setShowModal] = useState(false);
  const { mutate: referFriend, isLoading } = useReferFriendMutation({
    onError: (err: any) => {
      createToast({
        message: err?.message ?? 'Something went wrong',
        variant: 'error',
      });
    },
    onSuccess: (data) => {
      createToast({
        message: 'Invite sent successfully',
        variant: 'secondary',
      });
    },
  });
  const Outset = isSettings ? 'div' : Modal;

  return (
    <div>
      {!isSettings && profile?.referralCode && (
        <Flex.Column gap={4} className={styles.ReferralUpsell}>
          <Heading.h6 weight={700}>{text}</Heading.h6>
          <Text className="opacity-80">
            Share your referral link and earn up to 250 NEX credits for free
          </Text>
          <Flex flexWrap="wrap" gap={12}>
            <CallToAction.button
              onClick={() => {
                copy(`${APP_URL}/create-account?ref=${profile?.referralCode}`);
                trackCTA('referral_button');
              }}
              size="sm"
              className={'mt-4'}
            >
              {copiedText ? copiedText : 'Get Referral Link'}
            </CallToAction.button>
            <CallToAction.button
              size="sm"
              variant="secondary"
              className={'mt-4'}
              onClick={() => {
                setShowModal(true);
              }}
            >
              Send an invite
            </CallToAction.button>
          </Flex>
        </Flex.Column>
      )}

      <Outset in={showModal} onClose={() => setShowModal(false)}>
        <Flex.Column gap={4} className="p-4 bg-[#00000016] rounded-[8px]">
          <Heading.h6 weight={700}>
            Refer a friend to get up to 250 NEX credits for free
          </Heading.h6>
          <Flex.Column gap={12}>
            <Text className="opacity-80">
              Your referral link: {APP_URL}/create-account?ref=
              {profile?.referralCode}
            </Text>
            <CallToAction.button
              onClick={() => {
                copy(`${APP_URL}/create-account?ref=${profile?.referralCode}`);
                trackCTA('referral_button');
              }}
              variant="secondary"
              size="sm"
              className={'mt-2'}
            >
              {copiedText ? copiedText : 'Share Referral Link'}
            </CallToAction.button>
          </Flex.Column>
        </Flex.Column>
        <div className="mt-4">
          <Text className="opacity-80 mb-1">
            Or send an invite to your friends email
          </Text>
          <Field.Form
            onSubmit={async (e) => {
              e.preventDefault();
              const email = (
                e.target as EventTarget & {
                  email: { value: string };
                }
              ).email.value;

              if (!email) return;
              try {
                const res = (await referFriend({
                  email: email,
                })) as any;

                if (!!res) {
                  setShowModal(false);
                  (e.target as any).reset();
                }
              } catch (e) {
                // console.log(e);
              }
            }}
          >
            <Flex.Column gap={16}>
              <Field.Input
                name="email"
                placeholder="Enter your friend's email"
                type="email"
                required
              />
              <CallToAction.button size="sm" isLoading={isLoading}>
                Send Invite
              </CallToAction.button>
            </Flex.Column>
          </Field.Form>
        </div>
      </Outset>
    </div>
  );
};
