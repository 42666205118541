import { fabric } from 'fabric';
import { generateStaticID as uuidv4 } from '@nex/labs';

import {
  CustomFabricObject,
  ElementDirection,
  ImageUpload,
  ModifyShape,
} from '@nex/types/sketch';
import axios from 'axios';

export const createRectangle = (pointer: PointerEvent) => {
  const rect = new fabric.Rect({
    left: pointer.x,
    top: pointer.y,
    width: 100,
    height: 100,
    fill: '#D9D9D9',
    strokeWidth: 0,
    objectId: uuidv4(),
  } as CustomFabricObject<fabric.Rect>);

  return rect;
};

export const createTriangle = (pointer: PointerEvent) => {
  return new fabric.Triangle({
    left: pointer.x,
    top: pointer.y,
    width: 100,
    height: 100,
    strokeWidth: 0,
    fill: '#D9D9D9',
    objectId: uuidv4(),
  } as CustomFabricObject<fabric.Triangle>);
};

export const createCircle = (pointer: PointerEvent) => {
  return new fabric.Circle({
    left: pointer.x,
    top: pointer.y,
    strokeWidth: 0,
    radius: 100,
    fill: '#D9D9D9',
    objectId: uuidv4(),
  } as any);
};

export const createLine = (pointer: PointerEvent) => {
  return new fabric.Line(
    [pointer.x, pointer.y, pointer.x + 100, pointer.y + 100],
    {
      stroke: '#D9D9D9',
      strokeWidth: 2,
      objectId: uuidv4(),
    } as CustomFabricObject<fabric.Line>
  );
};

export const createText = (pointer: PointerEvent, text: string) => {
  return new fabric.IText(text, {
    left: pointer.x,
    top: pointer.y,
    fill: '#D9D9D9',
    fontFamily: 'Helvetica',
    fontSize: 36,
    fontWeight: '400',
    objectId: uuidv4(),
  } as fabric.ITextOptions);
};

export const createSpecificShape = (
  shapeType: string,
  pointer: PointerEvent
) => {
  switch (shapeType) {
    case 'rectangle':
      return createRectangle(pointer);

    case 'triangle':
      return createTriangle(pointer);

    case 'circle':
      return createCircle(pointer);

    case 'line':
      return createLine(pointer);

    case 'text':
      return createText(pointer, 'Tap to Type');

    default:
      return null;
  }
};
const requestQueue: any[] = [];
export const toDataUrl = async (url: string, callback: any) => {
  try {
    // only one request at a time
    if (requestQueue.length) {
      requestQueue.push({ url, callback });
      return;
    }

    // check if its data url
    if (url.startsWith('data:image')) {
      callback(url);
      return;
    }

    const response = await axios.get(
      `/api/s3/?url=${encodeURIComponent(url)}`,
      {
        responseType: 'blob',
      }
    );

    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };

    reader.readAsDataURL(response.data);
  } catch (e) {
    console.error(e);
  } finally {
    if (requestQueue.length) {
      const { url, callback } = requestQueue.shift();
      toDataUrl(url, callback);
      return;
    }
  }
};

export const handleImageUpload = ({
  file,
  canvas,
  imageKey,
  shapeRef,
  syncShapeInStorage,
}: ImageUpload) => {
  const readImage = async (file: File | string) => {
    const renderCanvas = (loadedImage: any) => {
      loadedImage.scaleToHeight(250);
      loadedImage.scaleToWidth(250);
      loadedImage.set('left', 0);
      loadedImage.set('top', 0);
      loadedImage.set('crossOrigin', 'Anonymous');
      if (imageKey) loadedImage.set('imageKey', imageKey);

      if (loadedImage) {
        canvas.current.add(loadedImage);
        shapeRef.current = loadedImage;

        syncShapeInStorage(loadedImage);
        canvas.current.requestRenderAll();
      }
    };

    try {
      const imageHost = process.env.NEXT_PUBLIC_CANVAS_HOST;
      const img = new Image();
      const src = imageKey ? `${imageHost}${imageKey}` : file;

      img.src = src as string;
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        const loadedImage = new fabric.Image(img, {
          objectId: uuidv4(),
          imageKey,
          left: 0,
          top: 0,
          crossOrigin: 'Anonymous',
        } as any);

        renderCanvas(loadedImage);
      };
    } catch (e) {
      console.error(e);
    }
  };

  if (!file) return;

  if (typeof file === 'string') {
    return readImage(file);
  }

  const reader = new FileReader();

  reader.onload = () => {
    readImage(reader.result as string);
  };

  reader.readAsDataURL(file);
};

export const createShape = (
  canvas: fabric.Canvas,
  pointer: PointerEvent,
  shapeType: string
) => {
  if (shapeType === 'freeform') {
    canvas.isDrawingMode = true;
    return null;
  } else {
    canvas.isDrawingMode = false;
  }

  return createSpecificShape(shapeType, pointer);
};

export const modifyShape = ({
  canvas,
  property,
  value,
  activeObjectRef,
  syncShapeInStorage,
}: ModifyShape) => {
  const selectedElement = canvas.getActiveObject();

  if (!selectedElement || selectedElement?.type === 'activeSelection') return;
  if (!isNaN(Number(value))) {
    value = Number(value);
  }
  // if  property is width or height, set the scale of the selected element
  if (property === 'width') {
    selectedElement.set('scaleX', 1);
    selectedElement.set('width', value);
  } else if (property === 'height') {
    selectedElement.set('scaleY', 1);
    selectedElement.set('height', value);
  } else {
    if (selectedElement[property as keyof object] === value) return;

    //check if the string value is a number

    selectedElement.set(property as keyof object, value);
  }

  if (selectedElement) {
    activeObjectRef.current = selectedElement;
    canvas.renderAll();
    syncShapeInStorage(selectedElement);
  }
  // set selectedElement to activeObjectRef
};

export const bringElement = ({
  canvas,
  direction,
  syncShapeInStorage,
}: ElementDirection) => {
  if (!canvas) return;

  // get the selected element. If there is no selected element or there are more than one selected element, return
  const selectedElement = canvas.getActiveObject();

  if (!selectedElement || selectedElement?.type === 'activeSelection') return;

  // bring the selected element to the front
  if (direction === 'front') {
    canvas.bringToFront(selectedElement);
  } else if (direction === 'back') {
    canvas.sendToBack(selectedElement);
  }

  canvas.renderAll();

  (canvas as any)
    .getObjects()
    .sort((a: any, b: any) => a.get('zIndex') - b.get('zIndex'));
  syncShapeInStorage(selectedElement);

  canvas.renderAll();
  // re-render all objects on the canvas
};
