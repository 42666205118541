import { createSmartApi } from '@lib/smart-query';

export const workspaceApiCreator = createSmartApi({
  key: 'workspace',
  endpoints: (builder) => ({
    getInvoices: builder.query({
      key: 'get-invoices',
      queryFn: ({ workspaceId }) => ({
        url: `/v2/workspaces/${workspaceId}/invoices`,
        method: 'GET',
      }),
    }),
    getInvoice: builder.query({
      key: 'get-invoice',
      queryFn: ({ workspaceId, invoiceId }) => ({
        url: `/v2/workspaces/${workspaceId}/invoices/${invoiceId}`,
        method: 'GET',
      }),
    }),
    cancelSubscriptions: builder.mutation({
      key: 'cancel-sub',
      mutationFn: ({ workspaceId }) => ({
        url: `/v2/workspaces/${workspaceId}/subscriptions`,
        method: 'DELETE',
      }),
    }),
    createSubscriptions: builder.mutation<
      {
        workspaceId: string;
        tier: 'PROFESSIONAL' | 'ORGANIZATION' | 'ENTERPRISE';
        interval: 'monthly' | 'yearly';
        seats?: number;
      },
      any
    >({
      key: 'get-invoice',
      mutationFn: ({ workspaceId, tier, interval, seats }) => ({
        url: `/v2/workspaces/${workspaceId}/subscriptions`,
        method: 'POST',
        body: {
          tier,
          seats,
          interval: interval?.toUpperCase(),
        },
      }),
    }),
  }),
});

export const {
  useGetInvoicesQuery,
  useGetInvoiceQuery,
  useCancelSubscriptionsMutation,
  useCreateSubscriptionsMutation,
} = workspaceApiCreator;
