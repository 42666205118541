import {
  CallToAction,
  Field,
  Flex,
  Heading,
  Spacer,
  Text,
  useFeedback,
} from '@nex/labs';
import { Table } from '@radix-ui/themes';

import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import { format } from 'date-fns';

import Security from '@nex/icons/svg/misc/lock.svg';
import Money from '@nex/icons/svg/misc/money.svg';
import Account from '@nex/icons/svg/misc/user-settings.svg';

import { useManageBillingMutation } from '@/state/query/user';

import { useUserStore } from '@/state/useStore';

import { ConsoleNexBar } from '@/components/layout';

import { Members } from './pages/members/index';
import { General } from './pages/general/index';

import {
  useCancelSubscriptionsMutation,
  useGetInvoicesQuery,
} from '@/state/query/workspace';

import styles from '../../settings.module.scss';

const PAGES = [
  {
    name: 'General',
    key: 'general',
    Icon: Account,
    description: 'Update workspace name and photo.',
  },
  {
    name: 'Members',
    key: 'members',
    Icon: Security,
    description: 'Manage workspace members.',
  },
  {
    name: 'Billing',
    key: 'billing',
    Icon: Money,
    description: 'Manage workspace billing.',
  },
] as const;

type PageKey = (typeof PAGES)[number]['key'];

export const Workspace = () => {
  const router = useRouter();
  const { profile } = useUserStore();
  const [currentPage, setCurrentPage] = React.useState<PageKey>('general');

  useEffect(() => {
    if (router.query?.page) {
      setCurrentPage(router.query?.page as PageKey);
    }
  }, [router.query?.page]);

  const getButtonProps = useCallback(
    (key: PageKey) => {
      const isActive = currentPage === key;
      return {
        className: classNames([
          styles.Link,
          {
            [styles.active]: isActive,
          },
        ]),
        onClick: () => {
          setCurrentPage(key);
          router.push({
            pathname: '/settings/workspace',
            query: {
              page: key,
            },
          });
        },
      };
    },
    [currentPage, router]
  );

  return (
    <>
      <div className={styles.GeneralSettings}>
        <div
          className={classNames([
            styles.GeneralSettingsNavigation,
            'w-full md:w-fit gap-[4px] md:gap-[16px]',
          ])}
        >
          {PAGES.map((page) => (
            <button {...getButtonProps(page.key)} key={page.key}>
              <Flex gap={16} flexWrap="wrap" alignItems="center">
                <span className={styles.Icon}>
                  <page.Icon />
                </span>
                <span>
                  <Heading.h6 weight={700}>{page.name}</Heading.h6>
                  <Text className="opacity-80 md:block hidden">
                    {page.description}
                  </Text>
                </span>
              </Flex>
            </button>
          ))}
        </div>
        <div className={styles.GeneralSettingsContent}>
          {currentPage === 'general' && <General />}
          {currentPage === 'members' && <Members />}
          {currentPage === 'billing' && <PlanSettings profile={profile} />}
        </div>
      </div>
    </>
  );
};

const PlanSettings = ({ profile }: any) => {
  const { createToast, createDisclosure } = useFeedback();
  const {
    profile: { organizationId },
  } = useUserStore();
  const { mutate: manageBilling } = useManageBillingMutation({
    onSuccess: async (data) => {
      if (!data?.billing?.url) return;

      await new Promise((resolve, reject) => {
        setTimeout(() => {
          window.location.href = data.billing?.url;
          resolve(true);
        }, 500);
      });
    },
    onError: (error: any) => {
      createToast({
        message: error?.message ?? 'Something went wrong. Please try again',
        variant: 'error',
      });
    },
  });
  const { mutateAsync: cancelSubscription, isLoading: isCanceling } =
    useCancelSubscriptionsMutation();

  const { data, isLoading } = useGetInvoicesQuery(
    {
      workspaceId: organizationId,
    },
    {
      enabled: !!organizationId,
    }
  );

  return (
    <>
      <span>
        <Heading.h5 weight={700}>Manage Plan</Heading.h5>
        <Text className="opacity-80 mt-2 ">
          Manage your subscription and billing.
        </Text>
      </span>
      <Spacer divider size={24} direction="horizontal" />
      <Text className=" mb-3" weight={700} fontSize="16px">
        Current Plan
      </Text>

      <Flex className={styles.PlanCard} gap={12}>
        <Flex.Column gap={8} className="px-4 py-5" fullWidth>
          <Flex fullWidth justifyContent="space-between">
            <Flex.Column>
              {/* <Money width={28} /> */}
              <Text weight={700} fontSize="16px">
                {/* {profile.subscription?.plan} */}{' '}
                {profile?.subscription?.tier || 'Basic'} Plan
              </Text>

              <Text className="opacity-80 mb-4">
                Next billing date:{' '}
                {(profile?.subscription?.renewal &&
                  format(
                    new Date(profile?.subscription?.renewal!),
                    'dd/MM/yyyy'
                  )) ??
                  'N/A'}
              </Text>
            </Flex.Column>
            <Heading.h2 className="opacity-80 mt-1 mb-1 tracking-[-5px]">
              <span className="uppercase">
                {profile?.subscription?.currency ?? '$'}{' '}
              </span>
              {Number.parseInt(`${profile?.subscription?.price}`) / 100 || '0'}{' '}
              <Text.span fontSize="14px" weight={700}>
                monthly
              </Text.span>
            </Heading.h2>
          </Flex>

          <ConsoleNexBar isSettings profile={profile} />
        </Flex.Column>
        <div className={styles.PlanCardAction}>
          <CallToAction
            size="xs"
            variant="clear"
            onClick={() => Router.push('/settings/plans')}
          >
            Upgrade Plan
          </CallToAction>
        </div>
      </Flex>

      <Flex.Column gap={12} className="mt-6">
        <Heading.h5 weight={700}>Billing and invoicing</Heading.h5>
        <Table.Root variant="surface">
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Invoice</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Billing Date</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Amount</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Plan</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data?.invoices?.length ? (
              data?.invoices?.map((invoice: any, index: number) => (
                <Table.Row key={invoice.id}>
                  <Table.RowHeaderCell>
                    <Text fontSize="16px" weight={700}>
                      Invoice {invoice?.number || index + 1}
                    </Text>
                  </Table.RowHeaderCell>
                  <Table.Cell>
                    <Text className="opacity-70">
                      {(invoice.billingDate &&
                        format(new Date(invoice.billingDate), 'dd/MM/yyyy')) ||
                        'N/A'}
                    </Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text color="var(--primary-theme)" casing="capitalize">
                      {invoice.status}
                    </Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text className="opacity-70">{invoice.amount}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text className="opacity-70">{invoice.tier}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>Download</Text>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6}>
                  <Text className="opacity-70">No invoices found</Text>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table.Root>
      </Flex.Column>
      <Spacer divider size={24} direction="horizontal" />
      <Field.Form
        onSubmit={(e) => {
          e.preventDefault();
          createDisclosure({
            title: 'Cancel Subscription',
            message: 'Are you sure you want to cancel your subscription?',
          });

          cancelSubscription({
            workspaceId: organizationId,
          });
        }}
      >
        <Text className="" weight={700} fontSize="16px">
          Cancel Your Subscription
        </Text>

        <CallToAction.button variant="error" isLoading={isCanceling}>
          Cancel Subscription
        </CallToAction.button>
      </Field.Form>
    </>
  );
};
