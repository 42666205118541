import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { fabric } from 'fabric';
import classNames from 'classnames';
import Router, { useRouter } from 'next/router';
import { useOrganization } from '@clerk/nextjs';

import { Flex, Spinner, Text, useLocalStorage } from '@nex/labs';
import { useGetSketchQuery } from '@/state/query/block';
import { useArtboardStore } from '@/state/useStore';

import { renderCanvas } from './lib/canvas';
import { handleDelete } from './lib/key-events';
import { ActiveElement } from '@nex/types/sketch';
import { handleImageUpload } from './lib/shapes';
import { defaultNavElement } from './lib/utils';
import { useKeyEvents } from './lib/useKeyEvents';

import { FloatingNav } from './components/floating-nav';
import { Sketcher } from './components/sketcher';
import { useFabric } from '@/components/layout';
import { useCanvasActions } from './lib/useCanvasActions';

import styles from './sketch.module.scss';

export const ArtboardSketch: React.FC = () => {
  const { isRealTime, setSketch } = useArtboardStore();
  const [rootStorage] = useLocalStorage('canvasObjects', {});
  const router = useRouter();
  const { organization } = useOrganization();

  const {
    data: sketchData,
    isLoading,
    isFetching,
  } = useGetSketchQuery(
    {
      sketchId: router.query.sketchId as string,
      workspaceId: organization?.publicMetadata?.externalId,
    },
    {
      enabled: router.query.sketchId !== 'new' && !!router.query.sketchId,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const rootCanvasObjects = useMemo(() => {
    const apiObjects = sketchData?.sketch?.state?.objects || {};
    return apiObjects ? Object.values(apiObjects) : rootStorage;
  }, [sketchData?.sketch?.state?.objects, rootStorage]);

  const isDrawing = useRef(false);

  const imageInputRef = useRef<HTMLInputElement>(null);

  const [activeElement, setActiveElement] = useState<ActiveElement>({
    value: '',
  });

  const {
    fabric: fabricRef,
    deleteShapeFromStorage,
    deleteAllShapes,
    syncShapeInStorage,
    isSavingCanvas,
    canvasRef,
    selectedShapeRef,
    shapeRef,
    activeObjectRef,
    handleActions,
    undo,
    redo,
    canUndo,
    canRedo,
  } = useFabric();

  useCanvasActions({
    setActiveElement,
    syncShapeInStorage,
  });

  useKeyEvents({
    fabricRef,
    syncShapeInStorage,
    deleteShapeFromStorage,
  });

  useEffect(() => {
    setSketch({
      sketchSize: {
        width: sketchData?.sketch?.state?.canvasInstance?.width,
        height: sketchData?.sketch?.state?.canvasInstance?.height,
        aspectRatio: sketchData?.sketch?.state?.canvasInstance?.aspectRatio,
      },
    });
  }, [sketchData?.sketch?.state?.canvasInstance]);

  const handleCanvasBackground = (
    elem: ActiveElement,
    canvas: Required<fabric.Canvas>
  ) => {
    if (!canvas) return;

    const color = elem?.attributes?.color;
    const image = elem?.attributes?.image;

    if (image) {
      fabric.Image.fromURL(image, function (img: any) {
        const canvasAspect = canvas.width / canvas.height;
        const imgAspect = img.width / img.height;
        const scaleFactor = Math.max(
          (canvas.width / img.width) * Math.min(canvasAspect / imgAspect, 1.5),
          canvas.height / img.height
        );

        img.set({
          left: canvas.width! / 2,
          top: canvas.height! / 2,
          scaleX: scaleFactor,
          scaleY: scaleFactor,
          originX: 'center',
          originY: 'center',
          crossOrigin: 'Anonymous',
        });

        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
          originX: 'center',
          originY: 'center',
        });
      });
    } else if (color) {
      canvas.setBackgroundImage(null as any, canvas.renderAll.bind(canvas));
      canvas.backgroundColor = color;
      canvas.renderAll();
    }
  };

  const handleActiveElement = useCallback(
    (elem: ActiveElement) => {
      setActiveElement(elem);
      if (elem?.value !== 'freeform' && fabricRef.current) {
        fabricRef.current.isDrawingMode = false;
        isDrawing.current = false;
      }

      switch (elem?.value) {
        case 'reset':
          deleteAllShapes?.({});
          fabricRef.current?.clear();
          setActiveElement(defaultNavElement);
          break;
        case 'delete':
          handleDelete(fabricRef.current as any, deleteShapeFromStorage as any);
          setActiveElement(defaultNavElement);
          break;
        case 'canvasBg':
          handleCanvasBackground(
            elem,
            fabricRef.current as Required<fabric.Canvas>
          );
          break;
        case 'undo':
          setActiveElement(defaultNavElement);
          undo();
          break;
        case 'redo':
          setActiveElement(defaultNavElement);
          redo();
          break;
        case 'image':
          if (typeof elem?.image !== 'string') {
            imageInputRef.current?.click();
          }
          break;
        case 'comments':
          break;
        default:
          (selectedShapeRef as any).current = {
            value: elem?.value,
            attributes: elem?.attributes,
          };
          break;
      }
    },
    [deleteAllShapes, deleteShapeFromStorage, undo, redo]
  );

  useEffect(() => {
    renderCanvas({
      fabricRef,
      canvasInstance: sketchData?.sketch?.state?.canvasInstance,
      handleActiveElement,
      canvasObjects: rootCanvasObjects,
      activeObjectRef,
    });
  }, [rootCanvasObjects]);

  const isLoadingSketch =
    Router?.query?.sketchId !== 'new' && isFetching && isLoading;

  return (
    <>
      <FloatingNav
        imageInputRef={imageInputRef}
        activeElement={activeElement}
        hasHistory={canUndo}
        hasRedoHistory={canRedo}
        saveCanvas={() => syncShapeInStorage('save')}
        handleImageUpload={async (e: any, key?: string) => {
          e.stopPropagation();
          await handleImageUpload({
            file: e.target.files[0],
            canvas: fabricRef as any,
            shapeRef,
            imageKey: key,
            syncShapeInStorage,
          });
        }}
        handleActiveElement={handleActiveElement}
      />
      {isSavingCanvas && (
        <div className={styles.Saving}>
          <Spinner size={16} /> <Text> Saving...</Text>
        </div>
      )}

      <Flex
        className={classNames([isRealTime && styles.SketchWrapper])}
        gap={12}
      >
        {isLoadingSketch && <LoadingOverlay />}
        <SketchArea
          isRealTime={isRealTime}
          isLoadingSketch={isLoadingSketch}
          canvasRef={canvasRef}
          fabricRef={fabricRef}
          handleActions={handleActions}
          syncShapeInStorage={syncShapeInStorage}
          deleteShapeFromStorage={deleteShapeFromStorage as any}
        />
        {/* <RealtimeSection
          fabricRef={fabricRef}
          dataURL={dataURL}
          setDataURL={setDataURL}
          isRealTime={isRealTime}
        /> */}
      </Flex>
    </>
  );
};

const LoadingOverlay: React.FC = () => (
  <div className="absolute inset-0 z-50 opacity-1">
    <Spinner
      center
      text="Loading your sketch. Please wait a moment."
      spinner="logo"
      style={{ margin: '92px auto' }}
    />
  </div>
);

const SketchArea: React.FC<{
  isRealTime: boolean;
  isLoadingSketch: boolean;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  fabricRef: React.RefObject<fabric.Canvas>;
  handleActions: any;
  syncShapeInStorage: any;
  deleteShapeFromStorage: any;
}> = ({
  isRealTime,
  isLoadingSketch,
  canvasRef,
  fabricRef,
  handleActions,
  syncShapeInStorage,
  deleteShapeFromStorage,
}) => (
  <div
    className={classNames([
      isRealTime && 'max-w-[50%] w-full h-full overflow-hidden',
      isRealTime && styles.Wrapper,
      !isRealTime && 'w-full h-full',
      isLoadingSketch && 'opacity-0',
    ])}
  >
    <Sketcher
      canvasRef={canvasRef}
      fabricRef={fabricRef}
      handleActions={handleActions}
      syncShapeInStorage={syncShapeInStorage}
      deleteShapeFromStorage={deleteShapeFromStorage}
    />
  </div>
);
