import React, { Fragment, useCallback, useEffect, useMemo } from 'react';

import {
  CallToAction,
  Flex,
  Heading,
  Img,
  Modal,
  Text,
  getDataIcons,
  useFeedback,
} from '@nex/labs';
import { AnimatePresence, motion } from 'framer-motion';

import { type Generation } from '@nex/types/lib/prompt';

import { useGlobalStore, useUserStore } from '@/state/useStore';

import Sparkles from '@nex/icons/svg/misc/sparkles.svg';
import Download from '@nex/icons/svg/misc/download-alt.svg';

import styles from '../collection.module.scss';
import { Vote } from '@/components/layout';
import { useUpdateCollectionMutation } from '@/state/query/prompt';
import classNames from 'classnames';

export const ViewCollectionModal = ({
  show,
  data: gen,
  parent,
  onClose,
}: {
  onClose: () => void;
  show: boolean;
  data?: Generation;
  parent: any;
  showAll?: boolean;
}) => {
  const { createDisclosure, createToast } = useFeedback();
  const [current, setCurrent] = React.useState(
    parent?.generations?.findIndex((d: any) => d.id === gen?.id) || 0
  );
  const generationRef = React.useRef(null);

  const data = useMemo(
    () => parent?.generations?.[current],
    [parent?.generations, current]
  );

  const { mutate: updateCollection } = useUpdateCollectionMutation({
    onSuccess: () => {
      createToast({
        message: 'Collection updated successfully',
      });
    },
    onError: (err: any) => {
      createToast({
        message: err?.message ?? 'Something went wrong',
        variant: 'error',
      });
    },
  });

  const deleteFromCollection = async (id: string) => {
    await createDisclosure({
      title: 'Delete Generation',
      message: 'Are you sure you want to delete this generation?',
      confirmText: 'Delete',
    });

    updateCollection({
      id: data?.id,
      type: 'remove',
      images: [id],
      workspaceId: profile?.organizationId!,
    });

    onClose();
  };

  const { profile } = useUserStore();

  const move = useCallback(
    (direction: 'left' | 'right') => {
      if (direction === 'left') {
        setCurrent((prev: number) => Math.max(prev! - 1, 0));
      }

      if (direction === 'right') {
        setCurrent((prev: number) =>
          Math.min(prev! + 1, parent?.generations?.length - 1)
        );
      }
    },
    [parent?.generations?.length]
  );

  useEffect(() => {
    // use arrow left and right to navigate
    const handleKeyPress = (e: any) => {
      if (e.key === 'ArrowLeft') {
        move('left');
      }

      if (e.key === 'ArrowRight') {
        move('right');
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [move]);

  return (
    <Modal size="xxlg" in={show} onClose={onClose}>
      <Flex gap={24} flexWrap="wrap">
        <Flex.Column gap={14} className={styles.GenerationImages}>
          <div className="relative">
            <AnimatePresence initial={false} mode="wait">
              <motion.div
                key={current}
                initial={{ opacity: 0, x: -4 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 4 }}
                className={styles.GenerationImage}
              >
                <Img
                  src={data?.url}
                  alt={parent?.caption}
                  className="w-full h-full max-h-[800px] max-w-[100%] object-contain mx-auto rounded-md"
                />
              </motion.div>
            </AnimatePresence>
            <div className={styles.VoteOverlay}>
              <Vote data={data} />
            </div>
          </div>
          <div className={styles.GenerationActionPreviewBar}>
            <Flex
              flexWrap="wrap"
              gap={8}
              alignItems="center"
              scrollable
              className="max-w-[80%] m-auto relative z-10 lg:max-w-[90%]"
              justifyContent="flex-start"
              ref={generationRef}
            >
              {parent?.generations?.map((generation: any) => (
                <Fragment key={generation?.id}>
                  <button
                    key={gen?.id}
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrent(
                        parent?.generations?.findIndex(
                          (d: Generation) => d.id === generation?.id
                        )
                      );
                    }}
                    data-generation-active={generation?.id === data?.id}
                    className={classNames([
                      styles.ResultsImageSlateButton,
                      generation?.id === data?.id && styles.active,
                      'w-[40px] h-[40px] flex-none',
                    ])}
                  >
                    <Img
                      style={{
                        border:
                          gen?.id === parent?.[current as any]?.id
                            ? '2px solid var(--primary-theme)'
                            : '',
                      }}
                      src={generation?.url}
                      className="w-full h-full rounded-[6px] object-cover"
                    />
                  </button>
                </Fragment>
              ))}
            </Flex>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              gap={12}
              flexWrap="wrap"
              className={styles.ResultsActions}
            >
              <CallToAction.button
                size="sm"
                variant={
                  current! === 0 && current > 0 ? 'primary' : 'secondary'
                }
                onClick={() => {
                  return move('left');
                }}
                disabled={current === 0 && current === 0}
                style={{
                  padding: '10px 6px',
                }}
              >
                <img
                  src={getDataIcons('arrow-thin-down', '#000')}
                  alt="arrow"
                  className="rotate-90"
                  width={16}
                />
              </CallToAction.button>

              <CallToAction.button
                size="sm"
                variant={
                  current! < parent?.length! - 1 ||
                  current === parent.generations.length - 1
                    ? 'secondary'
                    : 'primary'
                }
                onClick={() => move('right')}
                disabled={
                  current === parent?.length! - 1 &&
                  current === parent.generations.length - 1
                }
                style={{
                  padding: '10px 6px',
                }}
              >
                <img
                  src={getDataIcons('arrow-thin-down', '#000')}
                  alt="arrow"
                  className="-rotate-90"
                  width={16}
                />
              </CallToAction.button>
            </Flex>
          </div>
        </Flex.Column>

        <>
          <Flex.Column gap={8} className="mt-0 min-h-[450px] flex-1">
            <Flex.Column className="mb-4">
              <Flex alignItems="center" gap={8} justifyContent="space-between">
                <Flex.Column gap={4}>
                  <Heading.h6 weight={800}>Collection Info</Heading.h6>

                  <Text className="opacity-80 flex gap-[8px] flex-wrap">
                    Curated from
                    <Text weight={600} className="opacity-100">
                      {parent?.title}
                    </Text>
                  </Text>
                </Flex.Column>
              </Flex>
            </Flex.Column>
            <Flex.Column className="mt-auto relative" gap={8}>
              <Flex.Row gap={12} fullWidth justifyContent="space-between">
                <CallToAction.a
                  href={`/enhance?img=${data?.url}`}
                  size="sm"
                  variant="primary"
                  outline
                  onClick={() => onClose()}
                  leadingIcon={<Sparkles height={12} />}
                  data-analytics="enhance_generation"
                >
                  Enhance
                </CallToAction.a>

                <CallToAction.button
                  size="sm"
                  variant="secondary"
                  download={`${data?.url}`}
                  leadingIcon={<Download width={14} />}
                >
                  Download
                </CallToAction.button>
              </Flex.Row>
              <CallToAction.button
                size="sm"
                variant="clear"
                className="w-full"
                outline
                onClick={() => deleteFromCollection(data?.id)}
              >
                Delete from Collection
              </CallToAction.button>
            </Flex.Column>
          </Flex.Column>
        </>
      </Flex>
    </Modal>
  );
};
