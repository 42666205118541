import React, { useCallback } from 'react';
import Router from 'next/router';

import {
  CallToAction,
  Field,
  Flex,
  Heading,
  Modal,
  Tabs,
  Text,
  isPremiumUser,
  useFeedback,
} from '@nex/labs';

import { usePostHog } from 'posthog-js/react';
import { useGlobalStore, useUserStore } from '@/state/useStore';
import { useCreateSubscriptionsMutation } from '@/state/query/workspace';
import { PLANS, subscriptionPlans } from '../utils';

import styles from '../settings.module.scss';

export const Plans = ({ isLanding }: { isLanding?: boolean }) => {
  const {
    handleAmountButtonClick,
    showSeatsModal,
    setShowSeatsModal,
    seatsData,
    isAnnual,
    setIsAnnual,
  } = usePlans();

  const isFreePlan = !useUserStore().profile?.subscription?.tier;
  const { profile } = useUserStore();

  return (
    <div>
      {!isLanding && (
        <div className={styles.PlansHeader}>
          <Heading.h2 weight={800}>Pricing</Heading.h2>
        </div>
      )}

      <div className={styles.Plans}>
        <Tabs
          defaultTab={isAnnual ? 'yearly' : 'monthly'}
          size="sm"
          onChangeTab={(tab) => {
            setIsAnnual(tab === 'yearly' ? true : false);
          }}
        >
          <Tabs.Tab dataKey="monthly" title="Monthly" />
          <Tabs.Tab dataKey="yearly" title="Yearly" />
        </Tabs>
      </div>
      <div className={styles.Plans}>
        {subscriptionPlans(isAnnual ? 'annually' : undefined).map((plan) => {
          const isCurrentPlan =
            (isFreePlan && plan.key === 'BASIC') ||
            isPremiumUser(profile, plan?.key);
          return (
            <div
              className={`${styles.Plan} ${
                isPremiumUser(profile, plan?.key) ? 'opacity-70' : ''
              }`}
              key={plan?.key}
            >
              <div className={styles.PlanHeader}>
                <Heading.h3 weight={800} casing="capitalize" className="mb-2">
                  {plan?.name}
                </Heading.h3>
                <Heading.h3 className="-mb-1">
                  {typeof plan.price === 'number'
                    ? `$${plan.price}/month`
                    : plan.price}
                </Heading.h3>

                {plan.billing && (
                  <Text weight={600}>
                    {plan?.extra && (
                      <Text.span weight={600}>
                        {plan?.extra}
                        {', '}
                      </Text.span>
                    )}
                    Billed {isAnnual ? 'Annually' : 'Monthly'}
                  </Text>
                )}

                <CallToAction.button
                  onClick={() => {
                    return handleAmountButtonClick(plan?.key);
                  }}
                  variant={isCurrentPlan ? 'secondary' : 'primary'}
                  disabled={isCurrentPlan}
                  size="block"
                  className="mt-2 mb-6"
                >
                  {isCurrentPlan ? 'Current Plan' : plan.action}
                </CallToAction.button>
              </div>

              <div className={styles.PlanBody}>
                <ul>
                  {plan?.features.map((feature) => (
                    <li key={feature} className="flex gap-[8px] items-start">
                      <img
                        src="data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.2283 0.391887L5.08216 10.2591L2.38974 7.36037C1.89377 6.88914 1.11439 6.86058 0.547561 7.26041C-0.00509311 7.67452 -0.16097 8.40279 0.179125 8.98825L3.36752 14.2146C3.67927 14.7001 4.21775 15 4.82709 15C5.40809 15 5.96074 14.7001 6.27249 14.2146C6.78264 13.5435 16.5179 1.84841 16.5179 1.84841C17.7932 0.534683 16.2486 -0.62197 15.2283 0.377607V0.391887Z' fill='%230C0C0C'/%3E%3C/svg%3E%0A"
                        alt=""
                        className="w-[14px] h-[14px] mt-[4px]"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          );
        })}
      </div>

      <SeatsModal
        show={showSeatsModal}
        closeModal={() => setShowSeatsModal(false)}
        data={seatsData}
        handleAmountButtonClick={handleAmountButtonClick}
      />
    </div>
  );
};

export const usePlans = () => {
  const { createToast } = useFeedback();
  const [isAnnual, setIsAnnual] = React.useState(true);
  const [showSeatsModal, setShowSeatsModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [seatsData, setSeatsData] = React.useState<any>(null);
  const { profile } = useUserStore();
  const { setOverlayLoading } = useGlobalStore();
  const posthog = usePostHog();

  const { mutate: createSubscription, data } = useCreateSubscriptionsMutation({
    onSuccess: async (data) => {
      if (!data?.subscription?.checkoutURL) return;
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          setOverlayLoading(false);
          setIsLoading(false);
          window.location.href = data?.subscription?.checkoutURL;
          resolve(true);
        }, 500);
      });
    },
    onError: (err: any) => {
      setOverlayLoading(false);
      setIsLoading(false);
      createToast({
        message: err?.message ?? 'Something went wrong. Please try again',
        variant: 'error',
      });
    },
  });

  const handleAmountButtonClick = useCallback(
    async (
      key: string | Record<string, unknown>,
      options?: { type: 'monthly' | 'yearly'; seats: number }
    ) => {
      if (key === 'ORGANIZATION' && !showSeatsModal) {
        setSeatsData(key);
        return setShowSeatsModal(true);
      }

      setIsLoading(true);

      setOverlayLoading({
        loading: true,
        message: 'Creating payment playground...',
      });

      if (key === 'ENTERPRISE') {
        posthog.capture('subscription_upgrade_enterprise', {
          from: profile?.subscription?.tier,
          to: key,
        });
        window.location.href =
          'https://cal.com/dariuslam/nex-enterprise-demo-30-minutes';

        return;
      }

      posthog.capture('subscription_upgrade', {
        from: profile?.subscription?.tier,
        to: key,
      });

      createSubscription({
        tier: key as any,
        workspaceId: profile?.organizationId!,
        seats: Number(options?.seats) || 1,
        interval: options?.type || (isAnnual ? 'yearly' : 'monthly'),
      });
    },
    [profile, createSubscription, setOverlayLoading, isAnnual, showSeatsModal]
  );

  return {
    handleAmountButtonClick,
    showSeatsModal,
    setShowSeatsModal,
    seatsData,
    isLoading,
    setSeatsData,
    isAnnual,
    setIsAnnual,
  };
};

const SeatsModal = ({
  show,
  closeModal,
  data,
  handleAmountButtonClick,
}: {
  show: boolean;
  closeModal: () => void;
  data?: any;
  handleAmountButtonClick?: any;
}) => {
  type SeatType = 'monthly' | 'annually';

  const [seats, setSeats] = React.useState(1);
  const [type, setType] = React.useState<SeatType>('annually');

  const prices = {
    monthly: (PLANS as any)['monthly']?.[data],
    annually: (PLANS as any)['annually']?.[data],
  };

  const calculatePrice = (type: 'monthly' | 'annually') => {
    if (type === 'monthly') {
      return seats * prices[type];
    }

    return seats * prices[type] * 12;
  };

  return (
    <Modal title="Seats" size="md" in={show} onClose={closeModal}>
      <Heading.h4 weight={700}>{data} Plan</Heading.h4>
      <Text className="mb-6 mt-1" weight={700}>
        Maximum of 10 seats
      </Text>
      <Flex.Column gap={12}>
        <Field
          label="Number of seats"
          value={`${seats}`}
          onChange={(e: any) => setSeats(e.target.value)}
          type="number"
          max={10}
          min={1}
        />

        <Flex.Column gap={4}>
          <Text weight={700} className="opacity-70">
            Billing frequency (per seat)
          </Text>
          {[
            {
              title: 'Monthly',
              key: 'monthly',
              year: calculatePrice('monthly') * 12,
              month: calculatePrice('monthly'),
            },
            {
              title: 'Yearly',
              key: 'annually',
              year: calculatePrice('annually'),
              month: calculatePrice('annually') / 12,
            },
          ].map((item) => (
            <button
              className={styles.SeatsCalc}
              onClick={() => setType(item.key as SeatType)}
              style={{
                background: type === item.key ? '#f5f5f5' : 'transparent',
                borderWidth: type === item.key ? '2px' : '1px',
                borderColor:
                  type === item.key
                    ? 'var(--primary-theme)'
                    : 'var(--border-light)',
              }}
              key={item.title}
            >
              <Text weight={800}>
                {item.title}

                {item.key === 'annually' && (
                  <Text.span fontSize="12px" className="bg-green-300 p-1 ml-1">
                    Saves you 25%
                  </Text.span>
                )}
              </Text>
              <Text>${item.month} /month</Text>
              <Text>${item.year} /yearly</Text>
            </button>
          ))}

          <CallToAction.button
            variant="primary"
            size="block"
            className="mt-4"
            onClick={() => {
              handleAmountButtonClick(data, {
                type:
                  (type?.toLowerCase() as SeatType) === 'annually'
                    ? 'yearly'
                    : 'monthly',
                seats,
              });

              setType('monthly');
              setSeats(1);
              closeModal();
            }}
          >
            Pay ${calculatePrice(type)}/{type === 'monthly' ? 'month' : 'year'}
          </CallToAction.button>
        </Flex.Column>
      </Flex.Column>
    </Modal>
  );
};
