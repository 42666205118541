import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';

import { useUserStore } from '@/state/useStore';

import NexLogo from '@nex/icons/svg/logo.svg';
import NexMark from '@nex/icons/svg/logo-mark.svg';

import { CallToAction, Avatar, Flex, Text, createQueryString } from '@nex/labs';

import styles from './navbar.module.scss';

export const Navbar = () => {
  const [isScrolled, setIsScrolled] = React.useState(false);
  const { profile } = useUserStore();

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav
      className={classNames([styles.Nav, isScrolled && styles.NavbarScrolled])}
    >
      <div className={styles.Navbar}>
        <Flex className={styles.NavbarLeftItems}>
          <Link href="/" aria-label="Go home">
            <NexLogo />
            <NexMark width={100} />
          </Link>
        </Flex>

        <Flex className={styles.NavbarCenterItems}></Flex>

        <Flex className={styles.NavbarRightItems}>
          <Flex alignItems="center" gap={12}>
            {!profile?.id ? (
              <>
                <Link href={createQueryString('/login')}>
                  <CallToAction variant="clear" size="md">
                    Log In
                  </CallToAction>
                </Link>
                <Link
                  href={createQueryString('/create-account')}
                  aria-label="Go home"
                >
                  <CallToAction variant="primary" size="md">
                    Sign Up
                  </CallToAction>
                </Link>
              </>
            ) : (
              <Link href="/" aria-label="Go home">
                <Flex alignItems="center" gap={12}>
                  <Avatar src={profile?.photo} size={40} alt={profile?.name} />
                  <Text
                    fontSize="14px"
                    weight={700}
                    className="hidden md:block"
                  >
                    My Console
                  </Text>
                </Flex>
              </Link>
            )}
          </Flex>
        </Flex>
      </div>
    </nav>
  );
};
