export const PLANS = {
  monthly: {
    BASIC: 0,
    PROFESSIONAL: 30,
    ORGANIZATION: 60,
    ORGANIZATION_PER_USER: 60,
    ENTERPRISE: 'Contact Us',
    ENTERPRISE_PER_USER: 'Contact Us',
  },
  annually: {
    BASIC: 0,
    PROFESSIONAL: 25,
    ORGANIZATION: 50,
    ORGANIZATION_PER_USER: 50,
    ENTERPRISE: 'Contact Us',
    ENTERPRISE_PER_USER: 'Contact Us',
  },
};

export const subscriptionPlans = (type: 'monthly' | 'annually' = 'monthly') => [
  {
    name: 'Free',
    price: null,
    billing: null,
    key: 'BASIC',
    features: [
      'Full access to NEX models and controllable block system',
      '600 Free image generations per month, refreshing daily',
      'Maximum of 2 concurrent generation at a time',
      '100 MB asset storage',
    ],
    action: 'Upgrade',
  },
  {
    name: 'Professional',
    price: PLANS[type].PROFESSIONAL,
    billing: `Billed ${type}`,
    key: 'PROFESSIONAL',
    features: [
      'Full access to NEX models and controllable block system',
      'Up to 5,000 image generations per month',
      'All generations are private ',
      'Maximum of 8 concurrent generations at a time',
      '100 GB asset storage',
    ],
    action: 'Upgrade',
  },
  {
    name: 'Business',
    price: PLANS[type].ORGANIZATION,
    extra: `+ $${PLANS[type].ORGANIZATION_PER_USER}/per additional seat`,
    billing: `Billed ${type}`,
    key: 'ORGANIZATION',
    features: [
      'All features in Professional',
      'Up to 10,000 image generations per month, per user. Credits are shared across an organization',
      '1TB asset storage',
      'Access to real-time collaboration features',
      'Workspace management and administrative dashboard',
      'Priority Customer Support',
      'Access to faster GPU options ',
    ],
    action: 'Upgrade',
  },
  {
    name: 'Enterprise',
    price: PLANS[type].ENTERPRISE,
    key: 'ENTERPRISE',
    billing: null,
    features: [
      'All features in Business',
      'Unlimited image generations for entire enterprise',
      '10TB+ asset storage.',
      'Unlimited users per workspace',
      'Dedicated customer support',
      'Custom model development for specific use-cases',
      'Custom model is useable in all generative tools',
      'Pay as more models are needed',
      'Access to fastest GPU options',
    ],
    action: 'Schedule a call',
  },
];
