import { Avatar, Flex, Img, Text, extractFirstName } from "@nex/labs";
import classNames from "classnames";

import styles from "./asset-card.module.scss";

export const AssetCard = ({
	setCurrentData,
	setShow,
	asset,
}: {
	asset: any;
	setCurrentData: any;
	setShow: any;
}) => {
	return (
		<Flex.Column gap={8} className={styles.Generation}>
			<button
				type="button"
				onClick={() => {
					if (setCurrentData) setCurrentData(asset);
					setShow(true);
				}}
				className="h-full"
			>
				<div
					className={classNames([styles.MetaImage])}
					style={
						{
							["--img-bg"]: `url(${asset?.images[0].url})`,
						} as React.CSSProperties
					}
				>
					<Img src={asset?.images[0].url} alt={`generation ${asset?.id}`} />

					{asset?.caption && (
						<div className={styles.Generations__Meta}>
							<Text weight={600} noOfLines={2}>
								{asset.caption}
							</Text>
						</div>
					)}
				</div>
			</button>
			<Flex
				gap={8}
				className={styles.Generations__Meta}
				fullWidth
				justifyContent="space-between"
			>
				<Flex gap={4} alignItems="center">
					<Avatar
						src={asset?.creator?.photo}
						alt={extractFirstName(asset?.creator?.name)}
						size={24}
					/>

					<Text weight={600}>{extractFirstName(asset?.creator?.name)}</Text>
				</Flex>
			</Flex>
		</Flex.Column>
	);
};
