import React, { useMemo } from 'react';

import Router from 'next/router';

import classNames from 'classnames';
import {
  Avatar,
  CallToAction,
  Flex,
  Popover,
  Spacer,
  Spinner,
  Text,
  isEmail,
  useDebounce,
  useFeedback,
  useCopyToClipboard,
} from '@nex/labs';

import { useArtboardStore } from '@/state/useStore';

import { usePostHog } from 'posthog-js/react';
import {
  useAddCollaboratorMutation,
  useGetAllUsersQuery,
  useRemoveCollaboratorMutation,
} from '@/state/query/block';

import styles from '../artboard.module.scss';

export const CollaborateModule = () => {
  const { artboard } = useArtboardStore();

  const [copy] = useCopyToClipboard({
    toast: true,
  });
  const [data, setData] = React.useState({
    email: '',
    id: '',
    name: '',
  });
  const posthog = usePostHog();
  const [listOpen, setListOpen] = React.useState(false);
  const searchValue = useDebounce(data?.email, 1000);
  const { mutateAsync: addCollaborator, isLoading } =
    useAddCollaboratorMutation({});
  const { mutate: removeUser, isLoading: isRemoving } =
    useRemoveCollaboratorMutation({});

  const { createToast } = useFeedback();
  const {
    data: users,
    isLoading: _isLoadingUser,
    isFetching,
  } = useGetAllUsersQuery(
    {
      search: searchValue,
    },
    {
      enabled: !!searchValue,
    }
  );

  const rolesMap = {
    EDITOR: 'Edit',
    VIEWER: 'View',
  };

  const isLoadingUser = useMemo(() => {
    return isFetching || _isLoadingUser;
  }, [isFetching, _isLoadingUser]);

  const ownsArtboard = useMemo(() => {
    return artboard?.user?.id;
  }, [artboard?.user?.id]);

  if (!ownsArtboard) return null;

  return (
    <>
      <Popover size="md">
        <Popover.Trigger>
          <button>Collaborate</button>
        </Popover.Trigger>
        <Popover.Content>
          <div className={styles.Collaborators}>
            {ownsArtboard && (
              <>
                <Text>Invite others to collaborate on this artboard</Text>
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    try {
                      // Refactor later! Temporary workaround for demo
                      const role = 'EDITOR';
                      const maybeEmail = (e.target as any)?.[0].value;
                      const payload: Record<string, string> = { role };

                      if (data?.id) payload.userId = data?.id;
                      else if (isEmail(maybeEmail)) payload.email = maybeEmail;

                      await addCollaborator({
                        id: Router.query.id,
                        data: payload,
                      });

                      posthog.capture('collaboration_initiated', {
                        artboard_id: Router.query.id,
                        user_id: data?.email || data?.id,
                      });

                      setData({
                        email: '',
                        id: '',
                        name: '',
                      });

                      createToast({
                        message: 'Invitation sent',
                      });
                    } catch (error) {
                      console.log(error);
                      createToast({
                        message: 'Error sending invitation',
                        variant: 'error',
                      });
                    }
                  }}
                >
                  <Flex.Column gap={4}>
                    <div className="relative">
                      <input
                        placeholder="Search by name or email"
                        className="w-full"
                        value={data?.name || data?.email || ''}
                        onChange={(e) => {
                          setData({
                            ...data,
                            name: e.target.value,
                            email: e.target.value,
                          });

                          if (users?.users?.length === 0) setListOpen(false);
                          setListOpen(true);
                        }}
                        onFocus={() => setListOpen(true)}
                        onBlur={() => setTimeout(() => setListOpen(false), 200)}
                      />

                      {listOpen && (data?.email || data?.name) && (
                        <div
                          className={classNames([
                            styles.CollaboratorsList,
                            'fixed max-w-[350px] w-full bg-[#eaeaea] text-[#000]  mt-[8px] rounded-md shadow-md max-h-[300px] overflow-auto flex flex-col gap-[8px] p-2 z-10',
                          ])}
                        >
                          {isLoadingUser && <Spinner center size={20} />}
                          {users?.users?.length > 0 && !isLoadingUser ? (
                            <>
                              {' '}
                              {users?.users?.map((user: any) => (
                                <button
                                  key={`${user.id}:user_block`}
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setData((prev) => ({
                                      ...prev,
                                      email: user.email,
                                      id: user.id,
                                      name: user.name,
                                    }));
                                    setListOpen(false);
                                  }}
                                  className={`w-full hover:bg-[#f5f5f5] text-[#000] rounded-md p-[2px] transition-colors duration-200 ${
                                    user.id === data?.id ? 'bg-[#2e2e2e]' : ''
                                  }`}
                                >
                                  <Flex
                                    gap={8}
                                    key={user.id}
                                    alignItems="center"
                                  >
                                    <Avatar
                                      src={user.photo}
                                      alt={user.name}
                                      size={30}
                                    />
                                    <Text>{user.name || 'Nex User'}</Text>
                                  </Flex>
                                </button>
                              ))}
                            </>
                          ) : null}
                        </div>
                      )}
                    </div>

                    <Flex
                      className="ml-auto mt-1 w-full"
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      alignItems="flex-end"
                    >
                      <CallToAction.button
                        size="xs"
                        className="h-fit"
                        type="submit"
                        isLoading={isLoading}
                      >
                        Invite
                      </CallToAction.button>
                    </Flex>
                  </Flex.Column>
                </form>
              </>
            )}

            <Spacer divider size={1} />
            <Flex
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              gap={8}
            >
              {' '}
              <Text>Collaborators</Text>{' '}
              <button
                onClick={() => {
                  copy(window.location.href);
                }}
              >
                <Text color="var(--primary-theme)">Copy share link</Text>
              </button>
            </Flex>
            {artboard?.collaborators?.length > 0 && (
              <>
                {artboard?.collaborators?.map(
                  (collaborator: any, index: number) => (
                    <Flex
                      gap={8}
                      key={`${collaborator?.name ?? index}:collaborators`}
                      alignItems="center"
                      className="mb-2"
                      justifyContent="space-between"
                    >
                      <Flex gap={8} key={collaborator.id} alignItems="center">
                        <Avatar
                          src={collaborator.user.photo}
                          alt={collaborator.user.name}
                          size={30}
                        />

                        <Text>{collaborator.user.name} </Text>
                      </Flex>
                      <Popover hug>
                        <Popover.Trigger>
                          <Text className="opacity-70">
                            {' '}
                            can{' '}
                            {rolesMap[
                              collaborator.role as keyof typeof rolesMap
                            ].toLowerCase()}
                          </Text>
                        </Popover.Trigger>
                        <Popover.Content isDropdown>
                          <button
                            onClick={() => {
                              removeUser({
                                id: Router.query.id,
                                userId: collaborator.user?.id,
                              });
                            }}
                          >
                            {isRemoving ? 'Loading...' : 'Remove'}
                          </button>
                        </Popover.Content>
                      </Popover>
                    </Flex>
                  )
                )}
              </>
            )}
          </div>
        </Popover.Content>
      </Popover>
    </>
  );
};
