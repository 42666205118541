import type React from 'react';
import { useCallback } from 'react';
import { useUser } from '@clerk/nextjs';
import { Avatar, CallToAction, Flex, useFeedback, Text } from '@nex/labs';
import type { OrganizationMembershipResource } from '@clerk/types';
import { SelectRole } from '../../../components/select-role';

type MemberProps = {
  membership: OrganizationMembershipResource;
  onUpdateMember: () => Promise<void>;
};

export const Member = ({ membership, onUpdateMember }: MemberProps) => {
  const { user } = useUser();
  const { createToast } = useFeedback();
  const { publicUserData, id, role } = membership;

  const isYou = user?.id === publicUserData.userId;
  const isGuest = role === 'org:guest';

  const handleUpdateRole = useCallback(
    async (role: string) => {
      try {
        await membership.update({ role });
        await onUpdateMember();

        createToast({
          variant: 'primary',
          message: `Role updated for ${publicUserData.firstName}`,
        });
      } catch {
        createToast({
          variant: 'error',
          message: `Failed to update role for ${publicUserData.firstName}`,
        });
      }
    },
    [createToast, onUpdateMember, membership, publicUserData]
  );

  const handleDeleteMember = useCallback(async () => {
    try {
      await membership.destroy();
      await onUpdateMember();

      createToast({
        variant: 'primary',
        message: `${publicUserData.lastName} has been deleted`,
      });
    } catch (error) {
      console.error(error);
      createToast({
        variant: 'error',
        message: `Failed to delete ${publicUserData.firstName}`,
      });
    }
  }, [createToast, onUpdateMember, membership, publicUserData]);

  return (
    <tr className="border-b border-gray-700" key={id}>
      <td className="px-6 py-4 font-medium  whitespace-nowrap opacity-80">
        <Flex.Row alignItems="center" gap={8}>
          <span>
            <Avatar
              size={35}
              alt={publicUserData.firstName || 'User'}
              src={publicUserData.imageUrl}
              rounded={false}
            />
          </span>
          <Text>
            {!isYou
              ? `${publicUserData.firstName} ${publicUserData.lastName}`
              : 'You'}
          </Text>
        </Flex.Row>
      </td>
      <td className="px-6 py-4 opacity-80">
        <SelectRole
          disabled={isYou}
          defaultRole={role}
          onChange={handleUpdateRole}
        />
      </td>
      <td className="px-6 py-4 opacity-80 flex justify-end">
        {!isYou && (
          <Flex.Row gap={8}>
            {isGuest && (
              <CallToAction.button
                size="xs"
                variant="secondary"
                onClick={() => {
                  createToast({
                    message: 'List resource accessible to guests',
                  });
                }}
              >
                Access
              </CallToAction.button>
            )}

            <CallToAction.button
              size="xs"
              variant="error"
              onClick={handleDeleteMember}
            >
              Remove
            </CallToAction.button>
          </Flex.Row>
        )}
      </td>
    </tr>
  );
};
