import { useOrganization } from '@clerk/nextjs';
import { useQuery } from '@tanstack/react-query';

export const useRoles = () => {
  const { organization, isLoaded } = useOrganization();

  const { data } = useQuery({
    queryKey: ['roles'],
    queryFn: async () => organization?.getRoles({ pageSize: 20 }),
    enabled: Boolean(isLoaded && organization),
  });

  return data?.data.map((role) => role.key) || [];
};
