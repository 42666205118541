import { useEffect, useState } from 'react';

import {
  Flex,
  Field,
  Text,
  CallToAction,
  Popover,
  getDataIcons,
  useFeedback,
  Spacer,
  Switch,
  Tooltip,
} from '@nex/labs';
import classNames from 'classnames';
import Router from 'next/router';
import { isBlockInView } from '@/utils';

import { useArtboardStore } from '@/state/useStore';
import {
  useDeleteArtboardMutation,
  useEditArtBoardMutation,
  useGetModelsQuery,
} from '@/state/query/block';

import { aspectRatio, aspectRatioMap } from '../utils/constants';

import styles from './views.module.scss';

export const ArtboardPrompt = () => {
  const {
    setBlock,

    defaultConfig,
    removeBlock,
    setConfig,
    artboard,
  } = useArtboardStore();
  const [isNegativePrompt, setNegativePrompt] = useState(false);
  const { createToast, createDisclosure } = useFeedback();
  const KEY = 'prompt';

  const { data: modelData, refetch } = useGetModelsQuery(
    {},
    {
      select: (data) => {
        return {
          models: data.models.map((model: any) => ({
            ...model,
            blocks: model.blocks
              .filter((blck: any) => blck.name !== 'preset')
              .map((block: any) => block.name),
          })),
        };
      },
    }
  );

  useEffect(() => {
    if (!modelData) refetch();
  }, [modelData]);

  useEffect(() => {
    if (modelData) {
      if (!defaultConfig.model?.id) {
        setConfig({
          model: modelData?.models.find((model: any) => !!model.default),
        });
      }
    }
  }, [defaultConfig.model?.id, modelData]);

  const hasNegativePrompt =
    isNegativePrompt || isBlockInView('negative_prompt')[0]?.data;
  const { mutateAsync: editArtboard } = useEditArtBoardMutation({});
  const { mutateAsync: deleteArtboard } = useDeleteArtboardMutation({});

  return (
    <Flex.Column gap={18}>
      <Flex.Column className="mb-1">
        {artboard?.id ? (
          <Popover size="md">
            <Popover.Trigger>
              <Flex gap={6}>
                <Text className="opacity-80" weight={700}>
                  {artboard?.title}{' '}
                </Text>
                <img
                  src={getDataIcons('arrow-thin-down', '#000')}
                  alt="close"
                  aria-hidden="false"
                  width={10}
                  height={10}
                />
              </Flex>
            </Popover.Trigger>
            <Popover.Content isDropdown>
              <form
                className={styles.FieldArtboardActionInput}
                onSubmit={async (e) => {
                  e.preventDefault();
                  try {
                    if (!(e.target as any)?.[0].value) return;

                    await editArtboard({
                      id: Router.query.id,
                      data: {
                        title: (e.target as any)?.[0].value,
                      },
                    });

                    createToast({
                      message: 'Artboard name updated',
                    });
                  } catch (error) {
                    createToast({
                      message: 'Error updating artboard name',
                      variant: 'error',
                    });
                  }
                }}
              >
                <Flex gap={8} alignItems="center">
                  <input
                    placeholder="Rename your artboard"
                    defaultValue={artboard?.title}
                  />
                  <button type="submit" className="h-fit">
                    Save
                  </button>
                </Flex>
              </form>

              <Spacer divider size={1} />
              <button
                onClick={async () => {
                  try {
                    await createDisclosure({
                      title: 'Delete Artboard',
                      message: 'Are you sure you want to delete this artboard?',
                      confirmText: 'Delete',
                    });

                    await deleteArtboard({
                      id: Router.query.id,
                    });

                    createToast({
                      message: 'Artboard deleted',
                    });

                    Router.push('/artboard');
                  } catch (error) {
                    createToast({
                      message: 'Error deleting artboard',
                      variant: 'error',
                    });
                  }
                }}
              >
                Delete Artboard
              </button>
            </Popover.Content>
          </Popover>
        ) : (
          <Text
            className="opacity-80"
            fontSize="13px"
            weight={700}
            noOfLines={1}
          >
            {artboard?.title || 'ARTBOARD'}{' '}
          </Text>
        )}
      </Flex.Column>

      <Field.Select
        label="Models"
        value={defaultConfig.model?.name || 'Loading…'}
        onChange={(e: any) => {
          setConfig({
            model: modelData?.models.find(
              (model: any) => model.name === e.target.value
            ),
          });
        }}
        options={modelData?.models.map((model: any) => ({
          value: model.name,
          label: model.name,
        }))}
      />
      <Flex.Column gap={12}>
        <Flex.Column>
          <div className="mb-2">
            <Text weight={600}>
              Prompt{' '}
              <Tooltip content="Type in your text prompt here. Be specific and describe exactly what you want to see.">
                <img
                  src={getDataIcons('help')}
                  className="inline w-[12px] -mt-[2px]"
                />
              </Tooltip>
            </Text>

            <Text className="opacity-80">Enter your text prompt here</Text>
          </div>

          <Field.Textarea
            rows={6}
            value={isBlockInView(KEY)[0]?.data || ''}
            onChange={(e: any) => setBlock(KEY, e.target.value)}
            placeholder="Add your prompt"
          ></Field.Textarea>
        </Flex.Column>
        {(!!isNegativePrompt ||
          !!isBlockInView('negative_prompt')[0]?.data) && (
          <Field.Textarea
            rows={4}
            label="Negative Prompt"
            value={isBlockInView('negative_prompt')[0]?.data || ''}
            onChange={(e: any) => setBlock('negative_prompt', e.target.value)}
            placeholder="Add your Negative Prompt"
          ></Field.Textarea>
        )}
        <CallToAction.button
          size="xs"
          variant="secondary"
          className="w-full"
          onClick={() => {
            setNegativePrompt(!isNegativePrompt);
            removeBlock('negative_prompt');
          }}
          leadingIcon={
            !hasNegativePrompt ? (
              <img src={getDataIcons('add', '#000')} height={14} />
            ) : (
              <> </>
            )
          }
        >
          {hasNegativePrompt && 'Remove'} Negative Prompt
        </CallToAction.button>
      </Flex.Column>

      <Flex.Column gap={6}>
        <Text weight={600}>
          Number of Generations{' '}
          <Tooltip content="Click on the number of generations you want">
            <img
              src={getDataIcons('help')}
              className="inline w-[12px] -mt-[2px]"
            />
          </Tooltip>
        </Text>

        <div className={styles.ButtonPills}>
          {Array.from({ length: 4 }).map((_, index) => (
            <button
              key={index}
              onClick={() => setConfig({ size: index + 1 })}
              className={classNames([
                styles.ButtonPill,
                index + 1 === defaultConfig.size && styles.active,
              ])}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </Flex.Column>

      <AspectSettings />
    </Flex.Column>
  );
};

export const AspectSettings = ({ rawSetting: raw = true }) => {
  const { defaultConfig, setConfig } = useArtboardStore();
  return (
    <>
      <Popover outSet="block" closeOnInteraction>
        <Popover.Trigger>
          <Flex.Column gap={8}>
            <Text weight={600}>
              Aspect Ratio{' '}
              <Tooltip content="Click the drop down below to select your desired aspect ratio. Tip: Matching the aspect ratio to the aspect ratio of your input image yields the best results">
                <img
                  src={getDataIcons('help')}
                  className="inline w-[12px] -mt-[2px]"
                />
              </Tooltip>
            </Text>
            <div className={styles.ButtonAspectWrap}>
              <button className={styles.ButtonAspect}>
                <div
                  className={styles.AspectRatioElement}
                  data-aspect={defaultConfig.resolution}
                  style={
                    {
                      '--width': defaultConfig.resolution?.split('x')[0] + 'px',
                      '--height':
                        defaultConfig.resolution?.split('x')[1] + 'px',
                    } as React.CSSProperties
                  }
                ></div>
                <Flex
                  justifyContent="space-between"
                  gap={4}
                  fullWidth
                  alignItems="center"
                  className="flex-1"
                >
                  {(aspectRatioMap as any)[defaultConfig.resolution as any]}{' '}
                  <Text.span className="opacity-70">
                    {defaultConfig.resolution}
                  </Text.span>
                </Flex>
              </button>
            </div>
          </Flex.Column>
        </Popover.Trigger>
        <Popover.Content isDropdown>
          {aspectRatio.map((ratio) => (
            <button
              key={ratio}
              onClick={() => {
                setConfig({ resolution: ratio });
              }}
              className={classNames([
                styles.ButtonAspect,
                ratio === defaultConfig.resolution && styles.active,
              ])}
            >
              <div
                className={styles.AspectRatioElement}
                data-aspect={ratio}
                style={
                  {
                    '--width': ratio?.split('x')[0] + 'px',
                    '--height': ratio?.split('x')[1] + 'px',
                  } as React.CSSProperties
                }
              />{' '}
              <Flex
                justifyContent="space-between"
                gap={4}
                fullWidth
                className="flex-1"
              >
                {(aspectRatioMap as any)[ratio]}{' '}
                <Text.span className="opacity-70">{ratio}</Text.span>
              </Flex>
            </button>
          ))}
        </Popover.Content>
      </Popover>

      {raw && (
        <Flex.Row justifyContent="space-between">
          <label>
            <Text.span weight={600}>
              Auto Prompt Optimization{' '}
              <Tooltip content="Turn on Raw Mode for improved prompt coherence">
                <img
                  src={getDataIcons('help')}
                  className="inline w-[12px] -mt-[2px]"
                />
              </Tooltip>
            </Text.span>
          </label>

          <Switch
            isChecked={defaultConfig?.raw}
            onChange={() => {
              setConfig({
                raw: !defaultConfig.raw,
              });
            }}
          />
        </Flex.Row>
      )}
    </>
  );
};
