import { ArtboardAssetsBlock } from './assets';
import { ArtboardModel } from './model';
import { ArtboardPresetsBlock } from './presets';
import { ArtboardPrompt } from './prompt';
import { ArtboardSketch } from './sketch';

export const INNER_PAGES = [
  {
    key: 'prompt',
    el: ArtboardModel,
  },
  {
    key: 'preset',
    el: ArtboardPresetsBlock,
  },
  {
    key: 'assets',
    el: ArtboardAssetsBlock,
  },
  {
    key: 'sketch',
    el: ArtboardSketch,
  },
  {
    key: 'realtime',
    el: null,
  },
];
