import { fabric } from 'fabric';
import { generateStaticID as uuidv4 } from '@nex/labs';

import {
  CanvasMouseDown,
  CanvasMouseMove,
  CanvasMouseUp,
  CanvasObjectModified,
  CanvasObjectScaling,
  CanvasPathCreated,
  CanvasSelectionCreated,
  RenderCanvas,
} from './types';

import { createSpecificShape } from './shapes';
import { defaultNavElement } from './utils';

export const initializeFabric = ({
  canvasRef,
  fabricRef,
}: {
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>;
  fabricRef: React.MutableRefObject<fabric.Canvas | null>;
}) => {
  const canvasElement = document.getElementById('canvas');

  const canvas = new fabric.Canvas(canvasRef.current, {
    width: canvasElement?.clientWidth,
    height: canvasElement?.clientHeight,
  });

  fabric.Object.prototype.setControlVisible('mt', false);

  const cursorUrl = `url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_5032_6366)'%3E%3Cpath d='M4.66921 0.696113C4.90746 0.541395 5.21268 0.534555 5.45761 0.678464L21.8168 10.2898C22.0951 10.4533 22.2376 10.7766 22.1705 11.0923C22.1034 11.4081 21.8417 11.6455 21.5209 11.6817L12.4665 12.7028L7.85082 20.5592C7.68731 20.8375 7.364 20.98 7.04824 20.9128C6.73247 20.8457 6.49508 20.584 6.45889 20.2633L4.33242 1.40917C4.30058 1.12688 4.43097 0.85083 4.66921 0.696113Z' fill='black'/%3E%3Cpath d='M5.20432 1.10956L5.20433 1.10957L21.5635 10.7209C21.6563 10.7754 21.7038 10.8831 21.6814 10.9884C21.659 11.0936 21.5718 11.1728 21.4649 11.1848C21.4649 11.1848 21.4649 11.1848 21.4649 11.1848L12.4104 12.206L12.162 12.234L12.0354 12.4496L7.41972 20.3059C7.41972 20.3059 7.41972 20.3059 7.41971 20.3059C7.36521 20.3986 7.25743 20.4461 7.15219 20.4238C7.04695 20.4014 6.9678 20.3141 6.95574 20.2073L4.82927 1.35313L4.82927 1.35312C4.81866 1.25904 4.86211 1.16702 4.94153 1.11545C5.02095 1.06387 5.12269 1.0616 5.20432 1.10956Z' stroke='%23FAFAFA'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_5032_6366' x='0.328125' y='0.575195' width='25.8594' height='28.3545' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='4'/%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_5032_6366'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_5032_6366' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A"), auto`;
  canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
  canvas.skipOffscreen = false;
  canvas.selectionColor = '#138fe924';
  canvas.selectionBorderColor = '#138fe9';
  canvas.defaultCursor = cursorUrl;
  canvas.hoverCursor = cursorUrl;
  canvas.moveCursor = cursorUrl;

  const setTarget = (e: any) => {
    e.target.set({
      borderColor: '#138FE9',
      cornerColor: '#f0efef',
      cornerSize: 10,
      transparentCorners: false,
      cornerStyle: 'rect',
      cornerStrokeColor: '#138FE9',
      cornerStrokeWidth: 10,
    });
  };

  canvas.on('object:added', setTarget);
  canvas.on('object:modified', setTarget);
  canvas.on('object:selected', setTarget);

  fabricRef.current = canvas;

  return canvas;
};

const disableCanvasObjects = (canvas: fabric.Canvas) => {
  canvas.discardActiveObject();
  canvas.requestRenderAll();
  canvas.getObjects().forEach((obj) => {
    obj.selectable = false;
    obj.evented = false;
  });
};

// Re-enable other objects on the canvas
const enableCanvasObjects = (canvas: fabric.Canvas) => {
  canvas.getObjects().forEach((obj) => {
    obj.selectable = true;
    obj.evented = true;
  });

  canvas.renderAll();
};

export const handleCanvasMouseDown = ({
  options,
  canvas,
  selectedShapeRef,
  isDrawing,
  shapeRef,
  activeObjectRef,
}: CanvasMouseDown) => {
  const pointer = canvas.getPointer(options.e);

  if (canvas.getActiveObject() && selectedShapeRef.current) {
    activeObjectRef.current = null;
  }

  /**
   * get target object i.e., the object that is clicked
   * findtarget() returns the object that is clicked
   *
   * findTarget: http://fabricjs.com/docs/fabric.Canvas.html#findTarget
   */
  const target = canvas.findTarget(options.e, false);

  canvas.isDrawingMode = false;

  if (selectedShapeRef.current?.value === 'freeform') {
    const attributes = selectedShapeRef.current?.attributes;
    isDrawing.current = true;
    canvas.isDrawingMode = true;

    if (attributes?.type) {
      canvas.freeDrawingBrush = new (fabric as any)[attributes?.type + 'Brush'](
        canvas
      );
    }
    canvas.freeDrawingBrush.width = attributes?.strokeWidth || 5;
    canvas.freeDrawingBrush.color = attributes?.color || '#000000';
    return;
  }

  canvas.isDrawingMode = false;

  if (
    target &&
    (target.type === selectedShapeRef.current?.value ||
      target.type === 'activeSelection')
  ) {
    isDrawing.current = false;

    canvas.setActiveObject(target);

    /**
     * setCoords() is used to update the controls of the object
     * setCoords: http://fabricjs.com/docs/fabric.Object.html#setCoords
     */
    target.setCoords();
  } else {
    isDrawing.current = true;

    shapeRef.current = createSpecificShape(
      selectedShapeRef.current?.value,
      pointer as any
    );

    if (shapeRef.current) {
      disableCanvasObjects(canvas);
      canvas.add(shapeRef.current);
    }
  }
};

// handle mouse move event on canvas to draw shapes with different dimensions
export const handleCanvasMouseMove = ({
  options,
  canvas,
  isDrawing,
  selectedShapeRef,
  shapeRef,
  syncShapeInStorage,
}: CanvasMouseMove) => {
  if (!isDrawing.current) return;
  if (selectedShapeRef.current?.value === 'freeform') return;

  canvas.isDrawingMode = false;

  const pointer = canvas.getPointer(options.e);

  switch (selectedShapeRef?.current?.value) {
    case 'rectangle':
      shapeRef.current?.set({
        width: pointer.x - (shapeRef.current?.left || 0),
        height: pointer.y - (shapeRef.current?.top || 0),
        originX: 'left',
        originY: 'top',
      });

      break;

    case 'circle':
      shapeRef.current.set({
        radius: Math.abs(pointer.x - (shapeRef.current?.left || 0)) / 2,
      });
      break;

    case 'triangle':
      shapeRef.current?.set({
        width: pointer.x - (shapeRef.current?.left || 0),
        height: pointer.y - (shapeRef.current?.top || 0),
      });
      break;

    case 'line':
      shapeRef.current?.set({
        x2: pointer.x,
        y2: pointer.y,
      });
      break;

    case 'image':
      shapeRef.current?.set({
        width: pointer.x - (shapeRef.current?.left || 0),
        height: pointer.y - (shapeRef.current?.top || 0),
      });

    default:
      break;
  }

  canvas.renderAll();

  if (shapeRef.current?.objectId) {
    syncShapeInStorage(shapeRef.current);
  }
};

// handle mouse up event on canvas to stop drawing shapes
export const handleCanvasMouseUp = ({
  canvas,
  isDrawing,
  shapeRef,
  activeObjectRef,
  selectedShapeRef,
  syncShapeInStorage,
  setActiveElement,
}: CanvasMouseUp) => {
  isDrawing.current = false;

  enableCanvasObjects(canvas);
  if (selectedShapeRef.current?.value === 'freeform') return;

  if (shapeRef.current) {
    syncShapeInStorage(shapeRef.current);
  }

  shapeRef.current = null;
  activeObjectRef.current = null;
  selectedShapeRef.current = null;

  if (!canvas.isDrawingMode) {
    setTimeout(() => {
      setActiveElement(defaultNavElement);
    }, 700);
  }
};

// update shape in storage when object is modified
export const handleCanvasObjectModified = ({
  options,
  setElementAttributes,
  syncShapeInStorage,
}: CanvasObjectModified) => {
  const target = options.target as fabric.Object & {
    rx?: number;
    ry?: number;
  };
  if (!target) return;

  if (target?.type == 'activeSelection') {
  } else {
    syncShapeInStorage(options.target!);
  }

  const scaledWidth = target?.scaleX
    ? target?.width! * target?.scaleX
    : target?.width;

  const scaledHeight = target?.scaleY
    ? target?.height! * target?.scaleY
    : target?.height;

  setElementAttributes({
    left: target.left?.toFixed(0).toString() || '',
    top: target.top?.toFixed(0).toString() || '',
    width: scaledWidth?.toFixed(0).toString() || '',
    height: scaledHeight?.toFixed(0).toString() || '',
    angle: Number(target.angle)?.toFixed(0).toString() || '',
    rx: Number(target.rx)?.toFixed(0).toString() || '',
    ry: Number(target.ry)?.toFixed(0).toString() || '',
    fill: target.fill?.toString() || '',
    stroke: target.stroke?.toString() || '',
    strokeWidth: Number(target.strokeWidth)?.toFixed(0).toString() || '',
  });
};

// update shape in storage when path is created when in freeform mode
export const handlePathCreated = ({
  options,
  syncShapeInStorage,
}: CanvasPathCreated) => {
  const path = options.path;
  if (!path) return;

  path.set({
    objectId: uuidv4(),
  });

  syncShapeInStorage(path);
};

// check how object is moving on canvas and restrict it to canvas boundaries
export const handleCanvasObjectMoving = ({
  options,
}: {
  options: fabric.IEvent;
}) => {
  const target = options.target as fabric.Object;
  const canvas = target.canvas as fabric.Canvas;

  target.setCoords();

  const objectWidth = target.getScaledWidth() || target.width || 0;
  const objectHeight = target.getScaledHeight() || target.height || 0;

  // Calculate the minimum visible portion (10% of the object's dimensions)
  const minVisibleWidth = objectWidth * 0.1;
  const minVisibleHeight = objectHeight * 0.1;

  // Restrict movement to keep at least 10% of the object within the canvas
  if (target.left !== undefined) {
    target.left = Math.max(target.left, minVisibleWidth - objectWidth);
    target.left = Math.min(target.left, (canvas.width || 0) - minVisibleWidth);
  }

  if (target.top !== undefined) {
    target.top = Math.max(target.top, minVisibleHeight - objectHeight);
    target.top = Math.min(target.top, (canvas.height || 0) - minVisibleHeight);
  }
};

// set element attributes when element is selected
export const handleCanvasSelectionCreated = ({
  options,
  isEditing,
  setElementAttributes,
}: CanvasSelectionCreated) => {
  if (isEditing) return;
  if (!options?.selected) return;

  const selectedElement = options?.selected[0] as fabric.Object & {
    rx?: number;
    ry?: number;
  };

  if (selectedElement && options.selected.length === 1) {
    const scaledWidth = selectedElement?.scaleX
      ? selectedElement?.width! * selectedElement?.scaleX
      : selectedElement?.width;

    const scaledHeight = selectedElement?.scaleY
      ? selectedElement?.height! * selectedElement?.scaleY
      : selectedElement?.height;

    setElementAttributes({
      width: scaledWidth?.toFixed(0).toString() || '',
      height: scaledHeight?.toFixed(0).toString() || '',
      fill: selectedElement?.fill?.toString() || '',
      stroke: selectedElement?.stroke || '',
      left: selectedElement?.left?.toFixed(0).toString() || '',
      top: selectedElement?.top?.toFixed(0).toString() || '',
      angle: Number(selectedElement?.angle)?.toFixed(0).toString() || '',
      rx: Number(selectedElement?.rx)?.toFixed(0).toString() || '',
      ry: Number(selectedElement?.ry)?.toFixed(0).toString() || '',
      strokeWidth:
        Number(selectedElement?.strokeWidth)?.toFixed(0).toString() || '',
    });
  }
};

// update element attributes when element is scaled
export const handleCanvasObjectScaling = ({
  options,
  setElementAttributes,
}: CanvasObjectScaling) => {
  const selectedElement = options.target;
  const scaledWidth = selectedElement?.scaleX
    ? selectedElement?.width! * selectedElement?.scaleX
    : selectedElement?.width;
  const scaledHeight = selectedElement?.scaleY
    ? selectedElement?.height! * selectedElement?.scaleY
    : selectedElement?.height;

  setElementAttributes({
    width: scaledWidth?.toFixed(0).toString() || '',
    height: scaledHeight?.toFixed(0).toString() || '',
  });
};

// render canvas objects coming from storage on canvas
export const renderCanvas = ({
  fabricRef,
  canvasObjects,
  canvasInstance,
  handleActiveElement,
  activeObjectRef,
}: RenderCanvas & {
  handleActiveElement: (canvasInstance: any) => void;
}) => {
  const canvas = fabricRef.current;

  if (!canvas) return;
  canvas?.clear();

  if (!canvasObjects) return;

  Object.entries(canvasObjects).forEach(([objectId, objectData]) => {
    fabric.util.enlivenObjects(
      [objectData],
      (enlivenedObjects: fabric.Object[]) => {
        enlivenedObjects.forEach((enlivenedObj: any) => {
          const imageKey = enlivenedObj?.imageKey;
          const objectId = enlivenedObj?.objectId || uuidv4();
          // Check if it's an image object

          if (enlivenedObj.type === 'image' && imageKey) {
            // Replace the image source with the placeholder
            enlivenedObj.set({
              src: `${process.env.NEXT_PUBLIC_CANVAS_HOST}${imageKey}`,
              objectId,
            });

            canvas?.renderAll();
          }

          if (activeObjectRef.current?.objectId === objectId) {
            fabricRef.current?.setActiveObject(enlivenedObj);
          }
          enlivenedObj.set({
            objectId,
          } as unknown);

          fabricRef.current?.add(enlivenedObj);
        });
      },
      'fabric'
    );
  });

  if (canvas) {
    canvas.backgroundColor = canvasInstance?.backgroundColor || '#ffffff';
    if (canvasInstance) {
      handleActiveElement({
        value: 'canvasBg',
        attributes: {
          image: (canvasInstance.backgroundImage as any)?.src,
        },
      });
    }

    fabricRef.current?.renderAll();
  }
};
// resize canvas dimensions on window resize

// zoom canvas on mouse scroll
export const handleCanvasZoom = ({
  options,
  canvas,
}: {
  options: fabric.IEvent & { e: WheelEvent };
  canvas: fabric.Canvas;
}) => {
  const delta = options.e?.deltaY;
  let zoom = canvas.getZoom();

  const minZoom = 0.5;
  const maxZoom = 1;
  const zoomStep = 0.001;

  zoom = Math.min(Math.max(minZoom, zoom - delta * zoomStep), maxZoom);

  // Resize the canvas based on the zoom level
  const section = document.getElementById('canvas');
  if (section) {
    canvas.setHeight(section.clientHeight / zoom);
    canvas.setWidth(section.clientWidth / zoom);
  }

  canvas.zoomToPoint({ x: options.e.offsetX, y: options.e.offsetY }, zoom);

  options.e.preventDefault();
  options.e.stopPropagation();
};
