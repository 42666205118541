import { Text, useStyles } from '@nex/labs';
import React, { memo, useCallback, useState } from 'react';

interface ReadMoreProps {
  text: string;
  limit?: number;
}
export const ReadMore = memo(({ text, limit = 100 }: ReadMoreProps) => {
  const [expanded, setExpanded] = useState(false);

  const _styles = useStyles({
    color: 'var(--primary-theme)',
  });
  const toggleExpand = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const truncated =
    text && `${text}`.length <= limit
      ? text
      : `${text}`?.slice(0, limit)?.trim() + '...';
  const shouldShowReadMore = text?.length > limit && !expanded;

  return (
    <span aria-expanded={expanded} aria-label={text}>
      <Text>{expanded ? text : truncated}</Text>{' '}
      {(shouldShowReadMore || expanded) && (
        <button
          className="read-more-link"
          onClick={toggleExpand}
          style={_styles}
        >
          <Text inheritFont weight={600}>
            Read {expanded ? 'less' : 'more'}
          </Text>
        </button>
      )}
    </span>
  );
});

ReadMore.displayName = 'ReadMore';

export default ReadMore;
