import type React from 'react';
import { Modal, Field, Flex, CallToAction, useFeedback } from '@nex/labs';
import { useCallback, useState } from 'react';
import { SelectRole } from '../../../components/select-role';
import { useOrganization } from '@clerk/nextjs';

type AddMemberModalProps = {
  show: boolean;
  onClose(): void;
};

export const AddMemberModal = ({ show, onClose }: AddMemberModalProps) => {
  const {
    organization,
    isLoaded: isOrganizationLoaded,
    invitations,
    memberships,
  } = useOrganization();
  const { createToast } = useFeedback();

  const [values, setValues] = useState<{ email: string; role: string }>({
    email: '',
    role: 'org:member',
  });

  const handleAddMember = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      if (!isOrganizationLoaded) return;

      try {
        await organization?.inviteMember({
          emailAddress: values.email,
          role: values.role,
        });

        await Promise.all([
          invitations?.revalidate?.(),
          memberships?.revalidate?.(),
        ]);

        onClose();

        createToast({ message: 'Invite sent' });
      } catch (error: any) {
        createToast({
          variant: 'error',
          message: error.errors[0]?.message ?? 'Failed to send invite',
        });
      }
    },
    [
      isOrganizationLoaded,
      createToast,
      organization,
      memberships,
      invitations,
      values,
      onClose,
    ]
  );

  return (
    <Modal in={show} onClose={onClose} size="md" title="Add Member">
      <Field.Form onSubmit={handleAddMember}>
        <Field.Input
          name="email"
          type="email"
          required={true}
          placeholder="Enter email address"
          label="Email Address"
          onChange={(event) => {
            setValues((currentValues) => ({
              ...currentValues,
              email: (event.target as HTMLInputElement).value,
            }));
          }}
        />
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          className="mt-5"
          gap={12}
        >
          <span>
            <SelectRole
              defaultRole={values.role}
              onChange={(role) => {
                setValues((currentValues) => ({
                  ...currentValues,
                  role,
                }));
              }}
            />
          </span>
          <CallToAction.button size="sm" type="submit">
            Add Member
          </CallToAction.button>
        </Flex>
      </Field.Form>
    </Modal>
  );
};
