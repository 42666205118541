/* eslint-disable react-hooks/rules-of-hooks */
import { storeState, StoreType, useAppState } from './store';

type StoreNames = keyof StoreType;
type State = StoreType;
type StoreState<T extends StoreNames> = State[T] & State[T]['actions'];

type HookName<T extends StoreNames> = `use${Capitalize<T>}Store`;

type Hooks = {
  [K in StoreNames as HookName<K>]: () => StoreState<K>;
};

export const useStore = (): Hooks => {
  const stores: StoreNames[] = Object.keys(storeState) as StoreNames[];

  return stores.reduce((acc, store) => {
    const hookName =
      `use${store.charAt(0).toUpperCase()}${store.slice(1)}Store` as HookName<
        typeof store
      >;
    const hook = () =>
      useAppState((state) => ({
        ...state[store],
        ...state[store].actions,
      }));

    return {
      ...acc,
      [hookName]: hook,
    };
  }, {} as Hooks);
};

export const {
  useGlobalStore,
  useUserStore,
  useArtboardStore,
  useEnhanceStore,
} = useStore();
