import Link from 'next/link';
import React from 'react';
import { useUser } from '@clerk/nextjs';
import {
  CallToAction,
  Flex,
  Heading,
  Tabs,
  Text,
  memoWrapper,
} from '@nex/labs';
import { Collections, ContentLoader } from '..';
import { usePostHog } from 'posthog-js/react';

import LogoMark from '@nex/icons/svg/logo-mark.svg';

import { ArtBoards } from '../library/views/artboards';
import classNames from 'classnames';
import { ConsoleSlateActions } from '@/components/layout';
import { useUserStore } from '@/state/useStore';
import styles from './console-home.module.scss';
import { SetupChecklist } from './components/setup-checklist';

export const Console = () => {
  const { user, isLoaded } = useUser();
  const posthog = usePostHog();
  const trackCTA = (name: string) => posthog.capture(`console_${name}_clicked`);
  const [currentTab, setCurrentTab] = React.useState('artboards');
  const { profile } = useUserStore();

  return (
    <div className={styles.ConsoleWrapper}>
      <div className={styles.ConsoleHero}>
        <div className={styles.ConsoleIntroSlateBG} />
        <div className={styles.ConsoleIntroSlate}>
          <Flex.Row gap={18} className="mt-1">
            <LogoMark className="h-[35px] w-[35px]" />
            <Flex.Column>
              <Heading.h4
                fontSize="28px"
                className="mb-1"
                style={{
                  letterSpacing: '-1px',
                  lineHeight: '1.25',
                }}
                weight={700}
              >
                <span className="font-light opacity-80">
                  Welcome{isLoaded && ','}{' '}
                </span>
                <span className="font-bold">{user?.firstName}</span>
                {(!isLoaded || !user?.firstName) && (
                  <ContentLoader className="w-[140px] h-[30px] relative top-[8px] left-[2px] inline-block" />
                )}

                <br />
              </Heading.h4>
              <Heading.h6
                className="opacity-80"
                style={{
                  lineHeight: '1.5',
                }}
              >
                Lets create something amazing!
              </Heading.h6>
            </Flex.Column>
          </Flex.Row>
        </div>
      </div>
      <SetupChecklist onboardingChecklist={profile?.onboardingChecklist} />
      <div className={styles.ConsoleActionSlate}>
        <ConsoleSlateActions />
      </div>

      <Flex.Column gap={48} className={classNames([styles.ConsoleRecentWorks])}>
        <Tabs defaultTab="artboards" centered={false}>
          <Tabs.Tab
            dataKey="artboards"
            title="Artboards"
            onClick={() => setCurrentTab('artboards')}
          />
          <Tabs.Tab
            dataKey="collections"
            title="Collections"
            onClick={() => setCurrentTab('collections')}
          />
        </Tabs>
        {currentTab === 'artboards' && (
          <Flex.Column>
            <Flex
              flexWrap="wrap"
              justifyContent="space-between"
              alignItems="center"
            >
              <span>
                <Heading.h5 weight={700}>Recent Artboards</Heading.h5>
              </span>

              <Link href={'/library/artboards'}>
                <Text color="var(--primary-theme)" weight={700}>
                  View Artboards
                </Text>
              </Link>
            </Flex>

            <ArtBoards bare limit={4} />
          </Flex.Column>
        )}

        {currentTab === 'collections' && (
          <Flex.Column>
            <Flex
              flexWrap="wrap"
              justifyContent="space-between"
              alignItems="center"
              className="mb-5"
            >
              <span>
                <Heading.h5 weight={700}>Recent Collections</Heading.h5>
              </span>

              <Link href={'/library'}>
                <Text color="var(--primary-theme)" weight={700}>
                  View Collections
                </Text>
              </Link>
            </Flex>

            <Collections bare limit={6} />
          </Flex.Column>
        )}

        <div className={styles.ConsoleBannerArea}>
          <Flex.Column gap={4}>
            <span className={styles.ConsoleBannerArea__badge}>
              <span>NEW</span>
              Collaborate
            </span>
            <Heading.h4 weight={700} className="mt-4">
              Collaborate workspaces for your team.
            </Heading.h4>
            <Text weight={600} className="max-w-[390px]">
              Collaboration made easy with your team on NEX Artboard. Learn how
              nex can help your team.
            </Text>
            <CallToAction.a
              variant="secondary"
              className="mt-4"
              outline
              href="/artboard/new"
            >
              Learn More
            </CallToAction.a>
          </Flex.Column>

          <img src="/images/dashboard/collaborate.png" alt="Hero" />
        </div>
      </Flex.Column>
    </div>
  );
};
