import { MyStateCreator } from './store';

type EnhanceData = {
  prompt?: string;
  negativeprompt?: string;
  creativity?: number;
  image?: {
    file: File | null;
    preview: string;
  };
  upscale?: number;
};

export type EnhanceSlice = {
  enhanceData: EnhanceData;
  actions?: {
    setEnhanceData: (data: EnhanceData) => void;
  };
};

export const createEnhanceSlice: MyStateCreator<EnhanceSlice> = (set, get) => ({
  enhanceData: {
    prompt: '',
    negativeprompt: '',
    creativity: 50,
    image: {
      file: null,
      preview: '',
    },
    upscale: 1,
  },
  actions: {
    setEnhanceData: (data: any) => {
      const prevData = get().enhance.enhanceData;
      set((state) => {
        state.enhance.enhanceData = {
          ...prevData,
          ...data,
        };
      });
    },
  },
});
