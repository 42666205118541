import {
  useContext,
  createContext,
  useRef,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from 'react';
import queryString from 'query-string';
import { fabric } from 'fabric';
import { usePostHog } from 'posthog-js/react';
import { useOrganization } from '@clerk/nextjs';
import Router, { useRouter } from 'next/router';
import _ from 'lodash';
import { convertDataURIToBuffer, isEmpty, useFeedback } from '@nex/labs';

import { useShapesMutation } from '@/features/console/artboard/components/sketch/lib/useShapesMutation';

import { useCreateOrUpdateSketchMutation } from '@/state/query/block';
import { useArtboardStore, useUserStore } from '@/state/useStore';
import { aspectRatio } from '@/features/console/artboard/utils/constants';
import { useCopyPaste } from './useCopyFabric';
import { initializeFabric } from '@/features/console/artboard/components/sketch/lib';

interface FabricContextType {
  /**
   * Fabric canvas instance
   * @type {React.MutableRefObject<fabric.Canvas | null>}
   * @default null
   */
  fabric: React.MutableRefObject<fabric.Canvas | null>;
  /**
   * Sync shape in storage
   * @type {(data: any) => Promise<void>}
   */
  syncShapeInStorage: (data: any) => Promise<void>;
  /**
   * Save canvas
   * @type {() => void}
   */
  saveCanvas: () => void;
  /**
   * Delete shape from storage
   * @type {(data: any) => Promise<void>}
   * @default null
   */
  deleteShapeFromStorage: (data: any) => Promise<void>;
  /**
   * Delete all shapes from storage
   * @type {(data: any) => Promise<void>}
   * @default null
   */
  deleteAllShapes: (data: any) => Promise<void>;
  isSavingCanvas: boolean;
  handleCanvasChange: (id: string, options?: { hasSketch: boolean }) => void;
  isSketch: boolean;
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>;
  selectedShapeRef: React.MutableRefObject<{
    value?: unknown;
    attributes?: unknown;
  } | null>;
  isDrawingRef: React.MutableRefObject<boolean>;
  isEditingRef: React.MutableRefObject<boolean>;
  shapeRef: React.MutableRefObject<fabric.Object | null>;
  activeObjectRef: React.MutableRefObject<any>;
  hasWrongCanvasSize: boolean | string;
  handleResize: (e?: Window | any) => void;
  /**
   * Force set canvas size
   * @type {(data: CanvasSize) => void}
   * @default null
   * @param {CanvasSize} data
   * @returns {void}
   *
   */
  forceSetCanvasSize: (data: CanvasSize) => void;
  handleActions: (action: string, position?: any) => void;
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
}

export interface CanvasSize {
  width: number;
  height: number;
  scale?: number;
}

interface HistoryState {
  canvasState: string;
  objects: fabric.Object[];
}

const MAX_HISTORY_LENGTH = 50;
export const FabricContext = createContext<FabricContextType>({
  fabric: { current: null },
  isSketch: false,
  isSavingCanvas: false,
  syncShapeInStorage: async () => {},
  handleCanvasChange: () => {},
  saveCanvas: () => {},
  deleteShapeFromStorage: async () => {},
  deleteAllShapes: async () => {},
  canvasRef: { current: null },
  selectedShapeRef: { current: {} },
  shapeRef: { current: null },
  activeObjectRef: { current: null },
  isDrawingRef: { current: false },
  isEditingRef: { current: false },
  hasWrongCanvasSize: false,
  forceSetCanvasSize: () => {},
  handleActions: () => {},
  undo: () => {},
  redo: () => {},
  canUndo: false,
  canRedo: false,
  handleResize: () => {},
});

export function useFabric() {
  return useContext(FabricContext);
}

export const FabricProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { createToast, createDisclosure } = useFeedback();
  const router = useRouter();
  const posthog = usePostHog();
  const {
    activeTab,
    defaultConfig,
    sketch: { sketchSize },
  } = useArtboardStore();

  const { profile } = useUserStore();

  const cachedDefaultConfig = useRef(defaultConfig.resolution);
  const [canvasSize, setCanvasSize] = useState({
    width: sketchSize?.width || defaultConfig?.resolution?.split('x')[0],
    height: sketchSize?.height || defaultConfig?.resolution?.split('x')[1],
  });
  const [history, setHistory] = useState<HistoryState[]>([]);
  const [currentStateIndex, setCurrentStateIndex] = useState(-1);

  const { mutateAsync: createOrUpdateSketch, isLoading: isSaving } =
    useCreateOrUpdateSketchMutation();

  const fabricRef = useRef<fabric.Canvas | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const isDrawingRef = useRef<boolean>(false);
  const isEditingRef = useRef<boolean>(false);
  const shapeRef = useRef<fabric.Object | null>(null);
  const activeObjectRef = useRef<any>(null);
  const selectedShapeRef = useRef<{
    value?: unknown;
    attributes?: unknown;
  } | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const canvas = useMemo(
    () => fabricRef.current,
    [fabricRef.current]
  ) as fabric.Canvas;

  const { copySelectedObjects, pasteObjects } = useCopyPaste(
    fabricRef.current!
  );

  const isSketch = useMemo(() => {
    if (typeof router?.query?.sketchId === 'string') {
      return true;
    }

    if (activeTab === 'sketch' && typeof router?.query?.sketchId === 'string') {
      return true;
    }

    return false;
  }, [router?.query?.sketchId, activeTab]);

  const handleActions = useCallback(
    (action: string, position?: any) => {
      switch (action) {
        case 'copy':
          copySelectedObjects();
          break;

        case 'paste':
          pasteObjects(position);
          break;

        case 'duplicate':
          const executeCopyPaste = async () => {
            handleActions('copy');
            await new Promise((resolve) => setTimeout(resolve, 0)); // Ensures the state update is completed before pasting
            handleActions('paste');
          };
          executeCopyPaste();
          break;
      }
    },
    [copySelectedObjects, pasteObjects]
  );

  const handleResize = useCallback(
    async (e?: Window | any) => {
      if (typeof window === 'undefined') return;

      const getSize = () => {
        const { width, height } = canvasSize;
        const asideWidth = document
          .querySelector('#artboard-aside')
          ?.getBoundingClientRect();
        const previewWidth = document
          .querySelector('#artboard-preview')
          ?.getBoundingClientRect();

        const asidesWidth = asideWidth!.width + previewWidth!.width;
        const finalWindowWidth =
          (!e ? window.innerWidth : e.target.innerWidth) - asidesWidth - 24;
        let scale = 1.1;

        if (+width > +finalWindowWidth) {
          scale = Math.min(finalWindowWidth / +width, 1);
        }

        return {
          width: parseInt(`${width}`),
          height: parseInt(`${height}`),
          scale,
        };
      };

      const {
        width: originalWidth,
        height: originalHeight,
        scale,
      } = getSize() as Required<CanvasSize>;

      const canvasElement = document.getElementById('canvas');

      if (!canvasElement) return;

      canvasElement.style.width = `${parseInt(`${originalWidth}`) * scale}px`;
      canvasElement.style.height = `${parseInt(`${originalHeight}`) * scale}px`;

      if (canvas) {
        try {
          await Promise.all([
            canvas.setWidth(canvasElement.clientWidth),
            canvas.setHeight(canvasElement.clientHeight),
            canvas.setZoom(scale),

            canvas.renderAll(),
          ]);
        } catch (error) {
          console.error('Error in handleCanvasChange:', error);
        }
      }
    },
    [canvasSize, canvas]
  );

  /* ------------------------------ state events ------------------------------ */
  const saveState = useCallback(() => {
    const json = JSON.stringify(canvas.toJSON(['objectId']));
    const objects = canvas.getObjects();

    setHistory((prevHistory) => {
      if (JSON.parse(json).objects.length === 0) return prevHistory;

      const newHistory = [
        ...prevHistory.slice(0, currentStateIndex + 1),
        { canvasState: json, objects },
      ];
      if (newHistory.length > MAX_HISTORY_LENGTH) {
        newHistory.shift();
      }
      return newHistory;
    });

    setCurrentStateIndex((prevIndex) =>
      Math.min(prevIndex + 1, MAX_HISTORY_LENGTH - 1)
    );
  }, [canvas, currentStateIndex]);

  const undo = useCallback(() => {
    if (currentStateIndex > 0) {
      const newIndex = currentStateIndex - 1;
      const prevState = history[newIndex];

      canvas.loadFromJSON(prevState?.canvasState, () => {
        canvas.renderAll();
        setCurrentStateIndex(newIndex);
      });
    }
  }, [canvas, currentStateIndex, history]);

  const redo = useCallback(() => {
    if (currentStateIndex < history.length - 1) {
      const newIndex = currentStateIndex + 1;
      const nextState = history[newIndex];

      canvas.loadFromJSON(nextState?.canvasState, () => {
        canvas.renderAll();
        setCurrentStateIndex(newIndex);
      });
    }
  }, [canvas, currentStateIndex, history]);
  /* ------------------------------ state events ------------------------------ */

  const forceSetCanvasSize = useCallback(
    ({ width, height }: CanvasSize) => {
      setCanvasSize({ width, height });
      handleResize();
    },
    [handleResize]
  );

  const deleteShapeFromStorage = useShapesMutation(
    ({
      storage,
      data,
    }: {
      storage?: {
        getCanvasObjects: () => Map<string, any>;
      };
      data: string;
    }) => {
      const canvasObjects = storage!.getCanvasObjects();
      canvasObjects.delete(data);
    },
    []
  );

  const deleteAllShapes = useShapesMutation(
    ({
      storage,
    }: {
      storage?: {
        getCanvasObjects: () => Map<string, any>;
      };
    }) => {
      const canvasObjects = storage!.getCanvasObjects();
      if (!canvasObjects || canvasObjects.size === 0) return true;
      for (const [key] of canvasObjects.entries()) {
        canvasObjects.delete(key);
      }
      return canvasObjects.size === 0;
    },
    []
  );

  const syncShapeInStorage = useShapesMutation(
    ({
      storage,
      data: object,
    }: {
      storage?: {
        getCanvasObjects: () => Map<string, any>;
      };
      data: { objectId: string; toJSON: () => any } | string;
    }) => {
      setHasUnsavedChanges(true);
      if (object === 'save' && saveCanvas) {
        saveCanvas();
        return;
      }
      const canvasObjects = storage!.getCanvasObjects();

      if (typeof object === 'object') {
        const { objectId } = object;
        const shapeData = { ...object.toJSON(), objectId };
        canvasObjects.set(objectId, shapeData);
      }
    },
    []
  );

  const handleCanvasChange = useCallback(
    async (
      id: string,
      options?: {
        hasSketch: boolean;
      }
    ) => {
      if (hasUnsavedChanges) {
        try {
          await createDisclosure({
            message:
              'You have unsaved changes. Are you sure you want to leave?',
            title: 'Unsaved Changes',
            confirmText: 'Save Changes',
            cancelText: 'Discard Changes',
          });

          saveCanvas();

          return;
        } catch (error) {}
      }

      deleteAllShapes({});
      setHistory([]);
      setCurrentStateIndex(-1);

      let routeMethod = Router.replace;

      const finalUrl = queryString.stringifyUrl(
        {
          url: Router.asPath,

          query: {
            sketchId: id,
          },
        },
        {
          skipEmptyString: true,
          skipNull: true,
        }
      );

      if (!options?.hasSketch) {
        routeMethod = Router.push;

        if (canvas) {
          try {
            await canvas?.clear();
          } catch (error) {
            console.error('Error in handleCanvasChange:', error);
          }
        }
      }

      if (router.asPath !== finalUrl) {
        routeMethod(finalUrl, finalUrl, {
          shallow: true,
        });
      }

      setHasUnsavedChanges(false);
    },
    [deleteAllShapes, canvas, hasUnsavedChanges]
  );

  const saveCanvas = useCallback(async () => {
    if (!fabricRef.current) return;
    setHasUnsavedChanges(false);
    const getCanvasMode = (size: 'sm' | 'lg') => {
      const dataURL = fabricRef.current?.toDataURL({
        format: 'jpeg',
        quality: size === 'sm' ? 0.2 : 1,
        multiplier: size === 'sm' ? 0.5 : 1,
      });
      return convertDataURIToBuffer(dataURL!);
    };

    const canvasObjects = fabricRef.current
      .getObjects()
      .reduce((acc: any, obj: any) => {
        const { id, ...rest } = obj.toJSON();
        const objectId = id || obj.objectId;
        if (!objectId) return acc;
        return { ...acc, [objectId]: { ...rest, imageKey: obj.imageKey } };
      }, {});

    if (
      isEmpty(canvasObjects) &&
      (!fabricRef.current.backgroundImage ||
        fabricRef.current.backgroundColor === '#ffffff')
    ) {
      createToast({
        message: 'Please add some shapes to save the sketch.',
        variant: 'primary',
      });
      return;
    }

    const thumbnail = getCanvasMode('sm');
    const snapshot = getCanvasMode('lg');

    try {
      const res = await createOrUpdateSketch({
        state: {
          objects: canvasObjects,
          canvasInstance: {
            width: canvasSize.width,
            height: canvasSize.height,
            backgroundImage: fabricRef.current.backgroundImage,
            backgroundColor: fabricRef.current.backgroundColor,
          },
        },
        thumbnail,
        snapshot,
        sketchId: Router.query.sketchId as string,
        workspaceId: profile?.organizationId,
      });

      if (res?.sketch?.id && res?.sketch?.id !== Router.query.sketchId) {
        posthog.capture('sketch_created', { sketchId: res?.sketch?.id });
        handleCanvasChange(res?.sketch?.id, { hasSketch: true });
      }
    } catch (error) {
      createToast({
        message: 'Failed to save the sketch.',
        variant: 'error',
      });
      console.error('Error in useShapesMutation:', error);
    }
  }, [
    fabricRef,
    profile?.organizationId,
    createOrUpdateSketch,
    handleCanvasChange,
    createToast,
    posthog,
    canvasSize,
  ]);

  const findNearestAspectRatio = (width: string, height: string) => {
    const targetRatio = +width / +height;
    let nearest = aspectRatio[0];
    let minDiff = Infinity;

    aspectRatio.forEach((resolution) => {
      const [w, h] = resolution.split('x').map(Number);
      const ratio = w / h;
      const diff = Math.abs(targetRatio - ratio);
      if (diff < minDiff) {
        minDiff = diff;
        nearest = resolution;
      }
    });

    return nearest;
  };

  function checkAspectRatio(defaultResolution: string): boolean {
    const nearestRatio = findNearestAspectRatio(
      canvasSize.width as string,
      canvasSize.height as string
    );
    return nearestRatio !== defaultResolution;
  }

  const hasWrongCanvasSize = useMemo(() => {
    if (sketchSize?.aspectRatio)
      return defaultConfig?.resolution !== sketchSize?.aspectRatio;

    return checkAspectRatio(defaultConfig?.resolution);
  }, [
    defaultConfig.resolution,
    sketchSize?.aspectRatio,
    canvasSize.width,
    canvasSize.height,
  ]);

  /* --------------------------------- Effects -------------------------------- */

  useEffect(() => {
    if (cachedDefaultConfig.current !== defaultConfig.resolution) {
      cachedDefaultConfig.current = defaultConfig.resolution;
      setCanvasSize({
        width: parseInt(defaultConfig.resolution.split('x')[0]),
        height: parseInt(defaultConfig.resolution.split('x')[1]),
      });
    } else {
      setCanvasSize({
        width:
          sketchSize?.width ||
          parseInt(defaultConfig?.resolution?.split('x')[0]),
        height:
          sketchSize?.height ||
          parseInt(defaultConfig?.resolution?.split('x')[1]),
      });
    }
  }, [defaultConfig.resolution, sketchSize]);

  useEffect(() => {
    if (!canvas) return;

    const handleObjectModified = () => {
      saveState();
    };

    canvas.on('object:modified', handleObjectModified);
    canvas.on('object:added', handleObjectModified);
    canvas.on('object:removed', handleObjectModified);

    return () => {
      canvas.off('object:modified', handleObjectModified);
      canvas.off('object:added', handleObjectModified);
      canvas.off('object:removed', handleObjectModified);
    };
  }, [canvas, saveState]);

  return (
    <FabricContext.Provider
      value={{
        undo,
        syncShapeInStorage,
        shapeRef,
        selectedShapeRef,
        saveCanvas,
        redo,
        isSketch,
        isSavingCanvas: isSaving,
        isEditingRef,
        isDrawingRef,
        hasWrongCanvasSize,
        handleCanvasChange,
        handleActions,
        forceSetCanvasSize,
        fabric: fabricRef,
        deleteShapeFromStorage,
        deleteAllShapes,
        canvasRef,
        activeObjectRef,
        handleResize,
        canRedo: currentStateIndex < history.length - 1,
        canUndo:
          currentStateIndex > 0 && history.length > 0 && hasUnsavedChanges,
      }}
    >
      {children}
    </FabricContext.Provider>
  );
};
