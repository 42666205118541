export const FAQ_QUESTIONS = [
  {
    question: 'How does NEX generate images?',
    answer:
      'We use an algorithm called "diffusion" to generate images based off of your input prompts!',
  },
  {
    question: 'Is your model based on stable diffusion?',
    answer: `No, our model (Ikon-1) is an independent foundation model that we've built and trained from scratch. Our model is designed to offer you different controls to those of stable diffusion, making it easier to create images with non-text inputs.`,
  },
  {
    question: 'Can I control who can see my generated images ?',
    answer: 'Yes, all generations are privately owned by the creator.',
  },
  {
    question: 'How do I use image prompting?',
    answer: `With NEX Ikon-1, you can control your designs with text and image prompts. To do so: \n \n
      1. Click on the "Create Artboard" button on the top left of the Console. \n \n
      2. On the left action bar click on "Assets". \n \n
      3. Click on the "Choose Files" button under Upload images on the top left. \n \n
      4. Select your image and click the green "Upload Images" button on the bottom right of the modal. \n \n
      5. Find the image you just uploaded in the Assets column. \n \n
      6. Hover over the image you just uploaded and click "Use as" \n \n
      7. Click the type of control you want that asset to be. \n \n
      8. After the Asset has been successfully added as a control, type in a text prompt and click "Generate". `,
  },
  {
    question: 'How does image prompting work?',
    answer: `Image prompting, like text prompting, is just a way of giving NEX Ikon-1 information about what you want your output to look like within the bounds of the control that you select. `,
  },
  {
    question: 'What types of images can I generate with NEX ?',
    answer: `NEX is versatile in generating various types of images, including landscapes, portraits, abstract art, and specific scenes based on your prompts. Ikon-1's strength lies in its ability to interpret image and text prompts together, offering a wide range of creative possibilities`,
  },
  {
    question: 'Can NEX create animated images or videos?',
    answer:
      'Currently, NEX focuses on generating static images. We are exploring the possibility of expanding into animated images and videos in future updates, aiming to provide a comprehensive creative experience.',
  },
  {
    question: 'Is there a limit to the number of images I can generate?',
    answer:
      'Yes, there is a limit of 10 images per day for the free tier. If you would like to generate more images, visit the Pricing Page',
  },
  {
    question: 'How can I improve the accuracy of the images generated by NEX ?',
    answer: `To improve accuracy, provide clear and detailed prompts as well as high quality image inputs. Utilizing the image prompting feature by uploading reference images will enhance Ikon-1's understanding of your desired output`,
  },
  {
    question:
      'Are there any content restrictions for the images generated using NEX ?',
    answer: `Yes, we adhere to strict ethical guidelines. Prohibited content includes explicit imagery, hate speech, and anything that violates privacy or copyright laws. We ensure a safe and respectful creative environment for all users.`,
  },
];
