import Pusher from 'pusher-js';
import * as config from '@lib/config';
import Cookies from 'js-cookie';

const createEndpoint = (path: string) =>
  new URL(path, config.API_URL.replace('v1', '')).toString();

const getBearerToken = () => `Bearer ${Cookies.get('__session')}`;

export const pusher = new Pusher(config.PUSHER_API_KEY, {
  cluster: config.PUSHER_CLUSTER,
  channelAuthorization: {
    endpoint: createEndpoint('/v2/realtime/auth/channel'),
    transport: 'ajax',
    headers: {
      Authorization: getBearerToken(),
    },
  },
  userAuthentication: {
    endpoint: createEndpoint('/v2/realtime/auth/user'),
    transport: 'ajax',
    headers: {
      Authorization: getBearerToken(),
    },
  },
});

if (process.env.NODE_ENV !== 'production') {
  Pusher.logToConsole = true;
}
