import { useOrganization } from '@clerk/nextjs';
import { useGetCompositionsInProgressQuery } from '..';
import { useEffect, useMemo } from 'react';
import { useArtboardStore, useUserStore } from '@/state/useStore';
import { CompositionStatus } from '@nex/types/sketch';
import { queryClient } from '@lib/query-client';

type PublicMetadata = {
  externalId: string;
};

export const useProcessingComposition = (compositionId: string) => {
  const { profile } = useUserStore();

  const { setRequests, artboard, requests, collaborators } = useArtboardStore();

  const { data: compositionsInProgress, isLoading } =
    useGetCompositionsInProgressQuery(
      {
        workspaceId: profile?.organizationId,
        artboardId: artboard?.id,
      },
      {
        enabled: !!artboard?.id && !!profile?.organizationId,
        refetchInterval:
          !!requests.length || collaborators.length > 1 ? 5000 : false,
      }
    );

  useEffect(() => {
    setRequests(
      Object.keys(compositionsInProgress?.compositions || {}).map((key) => ({
        id: key,
        status: CompositionStatus.PROCESSING,
      })),
      {
        override: true,
      }
    );
  }, [compositionsInProgress]);

  return Boolean(compositionsInProgress?.compositions?.[compositionId]);
};
