import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Avatar,
  CallToAction,
  Flex,
  Heading,
  Img,
  Modal,
  Popover,
  Text,
  camelCaseToCleanString,
  cleanCompositions,
  extractImage,
  getDataIcons,
  pluralize,
} from '@nex/labs';
import Link from 'next/link';
import Router from 'next/router';
import classNames from 'classnames';
import { Composition, Image, type Blocks } from '@nex/types/lib/artboard';
import { motion } from 'framer-motion';
import ReadMore from '@/components/misc/read-more';

import Clock from '@nex/icons/svg/misc/clock.svg';
import Reuse from '@nex/icons/svg/blocks/reload.svg';

import posthog from 'posthog-js';

import { CreateCollectionModal } from '@/features/console';
import { formatDistanceToNow } from 'date-fns';
import { Vote } from '@/components/layout';
import { useArtboardStore } from '@/state/useStore';

import { ASSETS_CONSTANTS, aspectRatioMap } from '../../../utils/constants';

import styles from './generation-info.module.scss';
import { UseAsControl } from '@/components/misc/use-control';
const ITEMS_PER_PAGE = 10;

export const GenerationPaneModal = ({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) => {
  const {
    currentGeneration: _curr,
    artboard,
    setCurrentGeneration,
  } = useArtboardStore();

  const allGenerations = cleanCompositions(artboard?.compositions) ?? [];

  const currentData = useMemo(() => {
    return _curr;
  }, [_curr]);

  useEffect(() => {
    posthog.capture('viewed_artboard_generation', {
      generation_id: _curr?.id,
    });
  }, [_curr?.id]);

  const currParent = useMemo(() => {
    return currentData?.parent ?? {};
  }, [currentData?.parent]);

  return (
    <Modal in={show} onClose={onClose} size={'xxlg'} title={undefined}>
      <GenerationInfo
        currParent={currParent}
        currentData={currentData}
        onClose={onClose}
        allGenerations={allGenerations}
        setCurrentGeneration={setCurrentGeneration}
      />
    </Modal>
  );
};

export const GenerationInfo = memo(
  ({
    currParent,
    currentData,
    allGenerations = [],
    onClose,
    setCurrentGeneration,
  }: {
    currParent: Composition;
    currentData: Image;
    onClose: () => void;
    setCurrentGeneration: (data: Blocks) => void;
    allGenerations?: Composition[];
  }) => {
    const { setBlock, reuseBlock, defaultConfig, setActiveTab } =
      useArtboardStore();
    const [current, setCurrent] = React.useState<number | null>(0);
    const parentIndex = Math.max(
      allGenerations?.findIndex((gen) => gen.id === currParent?.id),
      0
    );

    const [visibleGenerations, setVisibleGenerations] = useState<Composition[]>(
      []
    );
    const [currentPage, setCurrentPage] = useState(1);

    const generationRef = React.useRef(null);

    useEffect(() => {
      const startIndex = Math.max(0, parentIndex - ITEMS_PER_PAGE / 2);
      const endIndex = startIndex + ITEMS_PER_PAGE;
      setVisibleGenerations(allGenerations.slice(startIndex, endIndex));
      setCurrentPage(Math.floor(startIndex / ITEMS_PER_PAGE) + 1);
    }, [allGenerations, parentIndex]);

    const loadMoreGenerations = useCallback(() => {
      const startIndex = currentPage * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;
      const newGenerations = allGenerations.slice(startIndex, endIndex);
      setVisibleGenerations((prev) => [...prev, ...newGenerations]);
      setCurrentPage((prev) => prev + 1);
    }, [allGenerations, currentPage]);

    const parent = useMemo(() => {
      if (!allGenerations?.length) return currParent?.images;
      const _curr = allGenerations?.find((gen) => gen.id === currParent?.id);
      return _curr?.images;
    }, [allGenerations, currParent]);

    const generation = parent?.[current ?? 0];

    const move = useCallback(
      (direction: 'left' | 'right') => {
        if (direction === 'left') {
          const allGen = allGenerations[parentIndex - 1]
            ?.images as unknown as Composition[];
          if (current! > 0) {
            setCurrent((prev) => Math.max(prev! - 1, 0));
          } else if (parentIndex >= 0) {
            const lastItem = allGen?.length - 1;

            if (allGenerations[parentIndex - 1]) {
              setCurrentGeneration({
                ...allGen[lastItem],
                parent: allGenerations[parentIndex - 1],
              } as any);
            }

            if (lastItem) setCurrent(lastItem);
          }
        }

        if (direction === 'right') {
          const allGen = allGenerations[parentIndex + 1]
            ?.images as unknown as Composition[];
          if (!current || current < parent?.length! - 1) {
            setCurrent((prev) => Math.min(prev! + 1, parent?.length! - 1));
          } else if (parentIndex <= allGenerations.length - 1) {
            if (allGenerations?.[parentIndex + 1]) {
              return setCurrentGeneration({
                ...allGen[0],
                parent: allGenerations[parentIndex + 1],
              } as any);
            }

            setCurrent(0);
            setCurrentGeneration({
              ...(allGenerations as any)[0].images[0],
              parent: allGenerations[0],
            } as any);
          }
        }
      },
      [
        setCurrent,
        parent,
        allGenerations,
        parentIndex,
        setCurrentGeneration,
        current,
      ]
    );

    useEffect(() => {
      // use arrow left and right to navigate
      const handleKeyPress = (e: any) => {
        if (e.key === 'ArrowLeft') {
          move('left');
        }

        if (e.key === 'ArrowRight') {
          move('right');
        }
      };

      window.addEventListener('keydown', handleKeyPress);

      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }, [parent, move]);

    useEffect(() => {
      setCurrent(parent?.findIndex((d: any) => d.id === currentData?.id)!);
      const genRef = generationRef.current as any;
      if (genRef) {
        const allGenerations = genRef.querySelectorAll(
          'button[data-generation-active=true]'
        );

        if (allGenerations[0]) {
          genRef.scrollTo({
            behavior: 'smooth',
            left: allGenerations[0].offsetLeft - 160,
          });
        }
      }
    }, [parent, currentData?.id]);

    const extractSecondaryProperties = {
      size: 'Number of Generations',
      aspectRatio: 'Size',
      raw: 'Raw Mode',
      model: 'Model',
    };

    const extractPrimaryProperties = {
      ...Object.keys(currParent?.blocks || {}).reduce((acc, key) => {
        if ((['preset', 'model'] as string[]).includes(key)) return acc;
        return {
          ...acc,
          [`blocks.${key}`]: key,
        };
      }, {}),
      'blocks.preset.name': 'Preset',
    };

    function extractData(
      properties: Record<string, string> = {},
      data = {
        ...currParent,
        ...currentData,
      }
    ) {
      const result: Record<string, any> = {};
      Object.entries(properties).forEach(([propertyPath, newKey]) => {
        const pathSegments = propertyPath.split('.');
        let tempData = data as unknown as Record<string, any> | null;

        for (const segment of pathSegments) {
          if (tempData?.[segment] !== undefined) {
            tempData = tempData[segment];
          } else {
            tempData = null;
            break;
          }
        }

        if (tempData) {
          result[newKey as keyof typeof result] = tempData;
        }
      });

      return result;
    }

    const [collectionOpen, setCollectionOpen] = React.useState(false);

    return (
      <Flex gap={24} flexWrap="wrap">
        <Flex.Column gap={14} className={styles.GenerationImages}>
          <div className="relative">
            <motion.div
              key={current}
              initial={{ opacity: 0, x: -4 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 4 }}
              className={styles.GenerationImage}
            >
              <Img
                src={extractImage(generation)}
                alt="generation"
                className="w-full h-full max-h-[800px] max-w-[100%] object-contain mx-auto rounded-md"
              />
            </motion.div>
            <div className={styles.VoteOverlay}>
              <Vote data={currentData} />
            </div>
          </div>
          <div className={styles.GenerationActionPreviewBar}>
            <Flex
              flexWrap="wrap"
              gap={8}
              alignItems="center"
              scrollable
              className="max-w-[80%] m-auto relative z-10 lg:max-w-[90%]"
              justifyContent="flex-start"
              ref={generationRef}
            >
              {(visibleGenerations.length
                ? visibleGenerations
                : [{ ...currParent }]
              ).map((generation: any) => (
                <Fragment key={generation?.id}>
                  {generation?.images?.map((gen: any) => (
                    <button
                      key={gen?.id}
                      onClick={() => {
                        if (setCurrentGeneration) {
                          return setCurrentGeneration({
                            ...gen,
                            parent: generation,
                          });
                        }

                        setCurrent(
                          generation?.images?.findIndex(
                            (d: any) => d.id === gen?.id
                          )
                        );
                      }}
                      data-generation-active={generation?.id === currParent?.id}
                      className={classNames([
                        styles.ResultsImageSlateButton,
                        generation?.id === currParent?.id && styles.active,
                        'w-[40px] h-[40px] flex-none',
                      ])}
                    >
                      <Img
                        style={{
                          border:
                            gen?.key === parent?.[current as any]?.key
                              ? '2px solid var(--primary-theme)'
                              : '',
                        }}
                        src={extractImage(gen)}
                        alt={gen?.caption}
                        className="w-full h-full rounded-[6px] object-cover"
                      />
                    </button>
                  ))}
                </Fragment>
              ))}
            </Flex>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              gap={12}
              flexWrap="wrap"
              className={styles.ResultsActions}
            >
              <CallToAction.button
                size="sm"
                variant={
                  current! === 0 && parentIndex > 0 ? 'primary' : 'secondary'
                }
                onClick={() => {
                  return move('left');
                }}
                disabled={current === 0 && parentIndex === 0}
                style={{
                  padding: '10px 6px',
                }}
              >
                <img
                  src={getDataIcons('arrow-thin-down', '#000')}
                  alt="arrow"
                  className="rotate-90"
                  width={16}
                />
              </CallToAction.button>

              <CallToAction.button
                size="sm"
                variant={
                  current! < parent?.length! - 1 ||
                  parentIndex === allGenerations.length - 1
                    ? 'secondary'
                    : 'primary'
                }
                onClick={() => move('right')}
                disabled={
                  current === parent?.length! - 1 &&
                  parentIndex === allGenerations.length - 1
                }
                style={{
                  padding: '10px 6px',
                }}
              >
                <img
                  src={getDataIcons('arrow-thin-down', '#000')}
                  alt="arrow"
                  className="-rotate-90"
                  width={16}
                />
              </CallToAction.button>
            </Flex>
          </div>
        </Flex.Column>

        <>
          <Flex.Column gap={8} className="mt-0 min-h-[450px] flex-1">
            <Flex.Column className="mb-4">
              <Flex gap={8} alignItems="center" className="mb-7">
                <Avatar
                  src={currParent?.creator?.photo}
                  alt={currParent?.creator?.name}
                  size={30}
                />

                <Text weight={700}>{currParent?.creator?.name}</Text>
              </Flex>

              <Flex alignItems="center" gap={8} justifyContent="space-between">
                <Flex.Column gap={4}>
                  <Heading.h6 weight={800}>Generation Info</Heading.h6>

                  <Text className="opacity-80 flex gap-[8px] flex-wrap">
                    Built with{' '}
                    {pluralize(
                      'block',
                      Object.keys(currParent?.blocks!)?.length
                    )}{' '}
                    <span className="flex gap-[4px]">
                      <Clock width={12} />
                      {currParent?.createdAt &&
                        formatDistanceToNow(
                          new Date(currParent?.updatedAt!)
                        )}{' '}
                      ago
                    </span>
                  </Text>
                </Flex.Column>

                <CallToAction.button
                  size="xs"
                  onClick={() => {
                    reuseBlock(currParent);
                    onClose();
                  }}
                  variant="secondary"
                  leadingIcon={<Reuse width={10} />}
                  className="flex gap-[8px] items-center"
                >
                  Remix
                </CallToAction.button>
              </Flex>
            </Flex.Column>

            <Flex flexWrap="wrap" gap={12}>
              {Object.entries(extractData(extractPrimaryProperties)).map(
                ([key, value]: [any, any], index) => {
                  return (
                    <Flex.Column gap={4} key={index} className="basis-[47%]">
                      <Text weight={800} casing="capitalize">
                        {camelCaseToCleanString(
                          key?.replace(/[^a-zA-Z ]/g, ' ')
                        )}
                        :{' '}
                      </Text>

                      {typeof value === 'object' ? (
                        <>
                          {Object.keys(value as any).map(
                            (_value: any, index: number) => {
                              const hasImage =
                                typeof value[_value] === 'string' &&
                                value[_value]?.startsWith('http');
                              if (['key', 'type'].includes(_value)) return null;

                              if (_value === 'id') {
                                return (
                                  <Link
                                    onClick={() => {
                                      setActiveTab('sketch');
                                    }}
                                    href={`${Router.asPath}?sketchId=${value[_value]}`}
                                    key={index}
                                  >
                                    <Text
                                      weight={700}
                                      color="var(--primary-theme)"
                                      casing="capitalize"
                                      decoration="underline"
                                    >
                                      View Sketch
                                    </Text>
                                  </Link>
                                );
                              }
                              return (
                                <Flex
                                  flexWrap="wrap"
                                  gap={10}
                                  alignItems="center"
                                  className={`mt-2
                                    ${
                                      hasImage
                                        ? 'cursor-pointer bg-[var(--primary-gray)] rounded-md p-1 w-fit'
                                        : 'cursor-text'
                                    }
                                `}
                                  key={index}
                                >
                                  <Text
                                    weight={500}
                                    casing="capitalize"
                                    className={hasImage ? 'hidden' : ''}
                                  >
                                    {_value
                                      ?.replace(/[^a-zA-Z ]/g, ' ')
                                      .replace(/^src/g, 'Image')}
                                  </Text>
                                  {hasImage ? (
                                    <a
                                      href={value[_value]}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        src={value[_value]}
                                        alt="image"
                                        key={index}
                                        className="w-[40px] h-[40px] object-cover rounded-md"
                                      />
                                    </a>
                                  ) : (
                                    <Text key={index} weight={800}>
                                      {value[_value]}
                                    </Text>
                                  )}
                                </Flex>
                              );
                            }
                          )}
                        </>
                      ) : value?.match(/\.(jpeg|jpg|gif|png|webp)$/) ? (
                        <a href={value} target="_blank" rel="noreferrer">
                          <img
                            src={value}
                            className="w-[80px] h-[80px] object-cover rounded-md"
                          />
                        </a>
                      ) : (
                        <ReadMore text={value} limit={100} />
                      )}
                    </Flex.Column>
                  );
                }
              )}
              <Flex.Column gap={4} className="basis-[100%] mt-4" fullWidth>
                <Text
                  weight={700}
                  casing="uppercase"
                  fontSize="11px"
                  className="mb-3 opacity-70"
                >
                  Advanced Metadata
                </Text>
                <Flex flexWrap="wrap" gap={12}>
                  {Object.entries(
                    extractData(extractSecondaryProperties, {
                      ...currParent.metadata,
                      model: currParent?.model?.name,
                    } as any)
                  ).map(([key, value], index) => (
                    <Flex.Column
                      gap={4}
                      key={`${index}:${key}`}
                      className="basis-[47%]"
                    >
                      <Text weight={800}>{key}: </Text>

                      {Array.isArray(value) ? (
                        <Text fontSize="14px" className="opacity-80">
                          {(aspectRatioMap as any)?.[value.join('x')] ||
                            value.join(', ')}
                        </Text>
                      ) : ['object', 'boolean'].includes(typeof value) ? (
                        <Text>{JSON.stringify(value)}</Text>
                      ) : (
                        <ReadMore text={value as string} limit={100} />
                      )}
                    </Flex.Column>
                  ))}
                </Flex>
              </Flex.Column>
            </Flex>
            <Flex.Column className="mt-auto relative" gap={8}>
              <Flex.Row
                gap={12}
                alignItems="center"
                {...((!reuseBlock && {
                  justifyContent: 'space-between',
                  fullWidth: true,
                }) ||
                  {})}
                justifyContent="space-between"
              >
                <CallToAction.a
                  size="xs"
                  outline
                  className="flex-1"
                  href={`/enhance?img=${encodeURIComponent(
                    extractImage(generation)
                  )}`}
                >
                  Enhance
                </CallToAction.a>
                <>
                  <Popover size="sm">
                    <Popover.Trigger>
                      <CallToAction.button size="xs" outline variant="clear">
                        Use as
                      </CallToAction.button>
                    </Popover.Trigger>
                    <Popover.Content isDropdown>
                      <Text align="left" fontSize="13px" color="#000">
                        Use this image as
                      </Text>

                      <UseAsControl
                        imageURL={extractImage(generation)}
                        imageKey={generation?.key!}
                      />
                    </Popover.Content>
                  </Popover>
                </>

                <Flex gap={12}>
                  <CallToAction.button
                    size="xs"
                    variant="secondary"
                    download={extractImage(generation)}
                  >
                    Download
                  </CallToAction.button>
                </Flex>
              </Flex.Row>
              <CallToAction.button
                size="xs"
                onClick={() => {
                  setCollectionOpen(true);
                }}
                className="w-full"
              >
                Save to collection
              </CallToAction.button>
            </Flex.Column>
          </Flex.Column>
          <CreateCollectionModal
            data={currentData}
            show={collectionOpen}
            onClose={() => {
              setCollectionOpen(false);
            }}
          />
        </>
      </Flex>
    );
  }
);

GenerationInfo.displayName = 'GenerationInfo';
