import React, { useState } from 'react';
import {
  CallToAction,
  Empty,
  Field,
  Flex,
  Img,
  Popover,
  Spacer,
  Spinner,
  Tabs,
  Text,
  capitalize,
  extractImage,
  useDebounce,
  useFeedback,
  getDataIcons,
  Tooltip,
} from '@nex/labs';
import { useOrganization } from '@clerk/nextjs';

import classNames from 'classnames';
import SortIcon from '@nex/icons/svg/misc/sort.svg';
import UploadIcon from '@nex/icons/svg/blocks/upload.svg';

import { motion } from 'framer-motion';
import { ASSETS_CONSTANTS } from '../utils/constants';
import { WeightSlider } from '../components/misc/weight-slider';
import { useGetUserAssetsQuery } from '@/state/query/block';
import { useGlobalStore, useUserStore } from '@/state/useStore';

import { isBlockInView } from '@/utils';
import { useArtboardStore } from '@/state/useStore';
import { useFileUpload } from '@/components/upload/useUpload';

import styles from './views.module.scss';
import {
  useGetCollectionByIdQuery,
  useGetCollectionQuery,
} from '@/state/query/prompt';
import { formatDistance } from 'date-fns';
import { UseAsControl } from '@/components/misc/use-control';

export const ArtboardAssetsBlock = ({
  onAssetSelect,
  inSketch = false,
}: {
  onAssetSelect?: (asset: any, key?: string) => void;
  inSketch?: boolean;
}) => {
  const [activeTab, setActiveTab] = useState('uploaded');

  return (
    <Flex.Column gap={18}>
      <Text weight={600} className="-mb-2">
        Assets{' '}
        <Tooltip content="Upload assets and add them as controls in your generations">
          <img
            src={getDataIcons('help')}
            className="inline w-[12px] -mt-[2px]"
          />
        </Tooltip>
      </Text>
      {!inSketch && (
        <Tabs
          defaultTab="uploaded"
          size="sm"
          centered={true}
          fullWidth
          onChangeTab={(tab) => setActiveTab(tab)}
        >
          <Tabs.Tab title="Uploaded" dataKey="uploaded"></Tabs.Tab>
          <Tabs.Tab title="Collections" dataKey="collections" />
        </Tabs>
      )}
      <>
        {activeTab === 'uploaded' && (
          <AssetBlock onAssetSelect={onAssetSelect} inSketch={inSketch} />
        )}
        {activeTab === 'collections' && (
          <CollectionsBlock onAssetSelect={onAssetSelect} inSketch={inSketch} />
        )}
      </>
    </Flex.Column>
  );
};

const CollectionsBlock = ({
  onAssetSelect,
  inSketch = false,
}: {
  onAssetSelect?: (asset: any, key?: string) => void;
  inSketch?: boolean;
}) => {
  const [query, setQuery] = useState<string | null>(null);

  const searchValue = useDebounce(query, 500);
  const { profile } = useUserStore();

  const [collectionInView, setCollectionInView] = useState<any>(null);

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useGetCollectionQuery({
      search: searchValue as string,
      limit: 15,
      workspaceId: profile?.organizationId,
    });

  return (
    <Flex.Column gap={18}>
      {collectionInView ? (
        <>
          <Flex.Row gap={8} alignItems="center">
            <button
              onClick={() => setCollectionInView(null)}
              className="flex items-center gap-2 p-[8px] rounded-[8px] bg-[var(--primary-gray)]"
            >
              <img
                src={getDataIcons('arrow-thin-right')}
                className="w-[12px] h-[12px] rotate-180"
              />
            </button>
            <Text weight={600} noOfLines={1}>
              {collectionInView.name}
            </Text>
          </Flex.Row>
        </>
      ) : (
        <>
          <Field.Input
            value={query || ''}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search in assets"
            leadingIcon={<img src={getDataIcons('search', '#667085')} />}
          />
        </>
      )}

      {collectionInView ? (
        <CollectionByID
          id={collectionInView.id}
          onAssetSelect={onAssetSelect}
          inSketch={inSketch}
        />
      ) : (
        <>
          <Flex.Column gap={18}>
            <Flex.Row gap={12}>
              {data?.collections?.map((collection: any, i: number) => {
                const imageURL = extractImage(collection);

                return (
                  <button
                    className={classNames([
                      styles.AssetsButton,
                      collection.id === collectionInView?.id && styles.active,
                    ])}
                    key={i}
                    onClick={() => {
                      setCollectionInView(collection);
                    }}
                  >
                    <Img
                      src={imageURL}
                      alt={collection?.title}
                      className={styles.AssetButtonImg}
                    />
                    <Flex.Column fullWidth className="mr-auto">
                      <Text
                        weight={600}
                        fontSize="var(--font-p)"
                        noOfLines={1}
                        align="left"
                        className="flex-1 mt-2"
                      >
                        {collection?.name}
                      </Text>

                      <Flex.Column gap={1}>
                        <Text
                          className="mt-[2px]"
                          fontSize="11px"
                          align="left"
                          weight={600}
                        >
                          {collection?.images?.length} images
                        </Text>

                        <Text
                          className="opacity-70"
                          fontSize="10px"
                          align="left"
                        >
                          {formatDistance(
                            new Date(),
                            new Date(collection.updatedAt),
                            {}
                          )}{' '}
                          ago
                        </Text>
                      </Flex.Column>
                    </Flex.Column>
                  </button>
                );
              })}
            </Flex.Row>

            {(isLoading || isFetching) && (
              <Spinner spinner="logo" center size={24} />
            )}

            {hasNextPage && (
              <CallToAction.button
                size="sm"
                variant="secondary"
                className="mt-4 mx-auto basis-[100%]"
                isLoading={isLoading}
                onClick={() => fetchNextPage()}
              >
                Load More
              </CallToAction.button>
            )}
          </Flex.Column>
          {!isLoading && !data?.collections?.length && (
            <Empty message="You have no collections yet" size="sm" />
          )}
        </>
      )}
    </Flex.Column>
  );
};

const AssetBlock = ({
  onAssetSelect,
  inSketch = false,
}: {
  onAssetSelect?: (asset: any, key?: string) => void;
  inSketch?: boolean;
}) => {
  const [selectedAsset, setSelectedAsset] = useState<any>(null);
  const { organization } = useOrganization();
  const { onFileUpload } = useFileUpload();

  const [query, setSearchValue] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState('all');

  const searchValue = useDebounce(query, 800);

  const selectAsset = async (key: string, url: string) => {
    setSelectedAsset(key);
    if (onAssetSelect) await onAssetSelect(url, key);
    setSelectedAsset(null);
  };

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    useGetUserAssetsQuery(
      {
        id: organization?.publicMetadata?.externalId,
        creator: sortBy === 'my-assets' ? 'me' : undefined,
        search: searchValue as string,
      },
      {
        enabled: !!organization,
      }
    );

  const sortMap = {
    all: 'All Assets',
    'my-assets': 'My Assets',
    shared: 'Shared Assets',
  };

  return (
    <Flex.Column gap={18}>
      <>
        {!inSketch && (
          <div className={styles.AssetUpload}>
            <Flex.Column gap={8} alignItems="center">
              <UploadIcon />
              <Text weight={700}>Upload images</Text>
              <Text>upload up to 10mb PNG/JPEG</Text>
            </Flex.Column>
            <CallToAction.input
              size="sm"
              variant="secondary"
              className="w-full"
              onFileUpload={onFileUpload}
              multiple
              defaultBehavior={false}
              leadingIcon={
                <img
                  src={getDataIcons('add', '#000')}
                  className="w-[12px] h-[12px]"
                />
              }
            >
              Choose File
            </CallToAction.input>
          </div>
        )}
        <Field.Input
          value={query || ''}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search in assets"
          leadingIcon={<img src={getDataIcons('search', '#667085')} />}
        />

        <Flex.Row
          justifyContent="space-between"
          alignItems="center"
          gap={12}
          className="w-full"
        >
          <Text weight={700}>
            {sortMap[sortBy as keyof typeof sortMap] || 'All Assets'}
          </Text>
          <Popover hug>
            <Popover.Trigger>
              <button className="flex items-center gap-1">
                <SortIcon width={14} color="var(--text-black)" />
                <Text fontSize="var(--font-accent)" weight={600}>
                  Sort By
                </Text>
              </button>
            </Popover.Trigger>
            <Popover.Content isDropdown>
              <Text align="left" fontSize="13px" color="#000">
                Sort by
              </Text>
              <button
                onClick={() => {
                  setSortBy('all');
                }}
              >
                All Assets
              </button>
              <button
                onClick={() => {
                  setSortBy('my-assets');
                }}
              >
                My Assets
              </button>
              <button
                onClick={() => {
                  setSortBy('shared');
                }}
              >
                Shared Assets
              </button>
            </Popover.Content>
          </Popover>
        </Flex.Row>

        <Flex.Column gap={18}>
          <Flex.Row gap={12}>
            {data?.assets?.map((asset: any, i: number) => {
              const imageURL = extractImage(asset);
              const imageKey = asset?.images[0]?.key;

              return (
                <div
                  className={styles.AssetsButton}
                  key={i}
                  {...(onAssetSelect && {
                    onClick: async () => {
                      return selectAsset(imageKey, imageURL);
                    },
                    role: 'button',
                    onKeyDown: (e: any) => {
                      if (e.key === 'Enter') selectAsset(imageKey, imageURL);
                    },
                  })}
                  data-selected={selectedAsset === imageKey}
                >
                  <Img
                    src={imageURL}
                    alt={asset?.name}
                    className={styles.AssetButtonImg}
                  />
                  {!inSketch && (
                    <Popover size="sm" closeOnInteraction>
                      <Popover.Trigger>
                        <div className={styles.AssetsButtonSelect}>Use as</div>
                      </Popover.Trigger>
                      <Popover.Content isDropdown>
                        <Text align="left" fontSize="13px" color="#000">
                          Use this image as
                        </Text>

                        <UseAsControl imageURL={imageURL} imageKey={imageKey} />
                      </Popover.Content>
                    </Popover>
                  )}
                </div>
              );
            })}
          </Flex.Row>

          {(isLoading || isFetching) && (
            <Spinner spinner="logo" center size={24} />
          )}

          {hasNextPage && (
            <CallToAction.button
              size="sm"
              variant="secondary"
              className="mt-4 mx-auto basis-[100%]"
              isLoading={isLoading}
              onClick={() => fetchNextPage()}
            >
              Load More
            </CallToAction.button>
          )}
        </Flex.Column>
        {!isLoading && !data?.assets?.length && (
          <Empty message="No assets uploaded yet" size="sm" />
        )}
      </>
    </Flex.Column>
  );
};

const CollectionByID = ({
  id,
  onAssetSelect,
  inSketch,
}: {
  id: string;
  inSketch?: boolean;
  onAssetSelect?: (asset: any, key?: string) => void;
}) => {
  const [selectedAsset, setSelectedAsset] = useState<any>(null);
  const { profile } = useUserStore();
  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    useGetCollectionByIdQuery(
      {
        id,
        workspaceId: profile?.organizationId,
      },
      {
        enabled: id !== undefined && !!id,
      }
    );

  const selectAsset = async (key: string, url: string) => {
    setSelectedAsset(key);
    if (onAssetSelect) await onAssetSelect(url, key);
    setSelectedAsset(null);
  };

  return (
    <>
      <Flex.Column gap={18}>
        <Flex.Row gap={12}>
          {data?.collection?.images?.map((collection: any, i: number) => {
            const imageURL = extractImage(collection);
            const imageKey = collection?.key;

            return (
              <div
                className={styles.AssetsButton}
                key={i}
                {...(onAssetSelect && {
                  onClick: async () => {
                    return selectAsset(imageKey, imageURL);
                  },
                  role: 'button',
                  onKeyDown: (e: any) => {
                    if (e.key === 'Enter') selectAsset(imageKey, imageURL);
                  },
                })}
                data-selected={selectedAsset === imageKey}
              >
                <Img
                  src={imageURL}
                  alt={collection?.name}
                  className={styles.AssetButtonImg}
                />
                {!inSketch && (
                  <Popover size="sm" closeOnInteraction>
                    <Popover.Trigger>
                      <div className={styles.AssetsButtonSelect}>Use as</div>
                    </Popover.Trigger>
                    <Popover.Content isDropdown>
                      <Text align="left" fontSize="13px" color="#000">
                        Use this image as
                      </Text>

                      <UseAsControl imageURL={imageURL} imageKey={imageKey} />
                    </Popover.Content>
                  </Popover>
                )}
              </div>
            );
          })}
        </Flex.Row>

        {(isLoading || isFetching) && (
          <Spinner spinner="logo" center size={24} />
        )}

        {hasNextPage && (
          <CallToAction.button
            size="sm"
            variant="secondary"
            className="mt-4 mx-auto basis-[100%]"
            isLoading={isLoading}
            onClick={() => fetchNextPage()}
          >
            Load More
          </CallToAction.button>
        )}
      </Flex.Column>
      {!isLoading && !data?.collection?.images?.length && (
        <Empty message="No images in this collection" size="sm" />
      )}
    </>
  );
};
const PreviewAccordion = ({ title, children }: any) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Flex.Column gap={8}>
      <button className="w-full text-left" onClick={() => setOpen(!isOpen)}>
        <Flex.Row gap={8} justifyContent="space-between" alignItems="center">
          <Text weight={600}>{title}</Text>
          <img
            className="w-3 h-3"
            style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(-90deg)' }}
            src={getDataIcons('arrow-thin-down', '#667085')}
          />
        </Flex.Row>
      </button>
      {isOpen && (
        <motion.div
          className={styles.PreviewAccordionContent}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
        >
          {children}
        </motion.div>
      )}
    </Flex.Column>
  );
};
