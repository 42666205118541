import { MyStateCreator } from './store';

export type GlobalSlice = {
  overlayLoading: {
    loading: boolean;
    message?: string;
  };
  modal: {
    type: string;
    props?: unknown;
  };
  assets: Array<{
    [key: string]: any;
  }>;
  actions: {
    setOverlayLoading: (
      options: { loading: boolean; message?: string } | boolean
    ) => void;
    setModal: (options: { type: string; props?: unknown }) => void;
    openAssetsModal: () => void;
    setAssets: (assets?: GlobalSlice['assets']) => void;
    closeModal: () => void;
  };
};

export const createGlobalSlice: MyStateCreator<GlobalSlice> = (set) => ({
  overlayLoading: {
    loading: false,
    message: undefined,
  },
  modal: {
    type: '',
    props: undefined,
  },
  assets: [],
  actions: {
    setOverlayLoading: (options) => {
      const loading = typeof options === 'boolean' ? options : options.loading;
      const message = typeof options === 'boolean' ? '' : options.message;

      set((state) => {
        state.global.overlayLoading = {
          loading,
          message,
        };
      });
    },

    setModal: (options) => {
      set((state) => {
        state.global.modal = {
          type: options?.type || '',
          props: options?.props || {},
        };
      });
    },

    openAssetsModal: () => {
      set((state) => {
        state.global.modal = {
          type: 'assets',
          props: {
            title: 'Upload Images',
            size: 'lg',
          },
        };
      });
    },

    setAssets: (assets) => {
      if (!assets) {
        return;
      }
      set((state) => {
        state.global.assets = assets;
      });
    },

    closeModal: () => {
      set((state) => {
        state.global.modal = {
          type: '',
          props: {},
        };
      });
    },
  },
});
