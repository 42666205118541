import React, { useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import queryString from 'query-string';
import { Collections } from '../library/views/collections';

import Link from 'next/link';

import { Field, Flex, Heading, Spacer, Tabs, Text } from '@nex/labs';
import SearchIcon from '@nex/icons/svg/misc/search.svg';

import { Assets } from '../library/views/assets';
import { ArtBoards } from '../library/views/artboards';

import styles from './search.module.scss';

export const Search = ({ query }: { query: string }) => {
  const [currentTab, setCurrentTab] = React.useState('all');
  const [searchQuery, setSearchQuery] = React.useState(query);
  const router = useRouter();

  const getHref = (tab: string) => {
    return queryString.stringifyUrl(
      {
        url: '/search',
        query: {
          tab,
          q: query,
        },
      },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );
  };

  useEffect(() => {
    const { tab } = router.query;
    if (tab) {
      setCurrentTab(tab as string);
    }
  }, [router.query]);

  return (
    <div className={styles.SearchWrapper}>
      <div className={styles.SearchHero}>
        <div className={styles.SearchIntroSlateBG} />
        <div className={styles.SearchIntroSlate}>
          <Heading.h4
            fontSize="28px"
            className="my-2 mb-2"
            style={{
              letterSpacing: '-0.5px',
            }}
            weight={700}
          >
            {searchQuery ? (
              <>
                Results matching{' '}
                <Text.span color="var(--primary-theme)" inheritFont>
                  &quot;{searchQuery}&quot;
                </Text.span>
              </>
            ) : (
              <> Search for anything</>
            )}
          </Heading.h4>

          <Flex
            gap={14}
            alignItems="center"
            className="mt-4 max-w-[600px] w-full"
          >
            <Field.Input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              leadingIcon={<SearchIcon />}
              placeholder="Search in collections, artboards and history, etc."
              className="w-full"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  Router.replace(
                    queryString.stringifyUrl(
                      {
                        url: '/search',
                        query: {
                          tab: currentTab,
                          q: searchQuery,
                        },
                      },
                      {
                        skipEmptyString: true,
                        skipNull: true,
                      }
                    )
                  );
                }
              }}
            />
          </Flex>
        </div>
      </div>

      <Tabs selectedKey={currentTab} defaultTab="all">
        <Tabs.Tab dataKey="all" title="All" href={getHref('all')} />
        <Tabs.Tab
          dataKey="collections"
          title="Collections"
          href={getHref('collections')}
        />
        <Tabs.Tab dataKey="assets" title="Assets" href={getHref('assets')} />
        <Tabs.Tab
          dataKey="artboards"
          title="Artboards"
          href={getHref('artboards')}
        />
      </Tabs>

      <Spacer size={16} direction="vertical" />

      {currentTab === 'all' && (
        <Flex.Column gap={48}>
          <div>
            <Flex
              gap={16}
              flexWrap="wrap"
              justifyContent="space-between"
              className="mb-4"
            >
              <Heading.h5 weight={800}>Assets </Heading.h5>
              <Link href={getHref('assets')}>
                <Text.span color="var(--primary-theme)" weight={700}>
                  View All
                </Text.span>
              </Link>
            </Flex>
            <Assets query={searchQuery} bare limit={8} />
          </div>
          <div>
            <Flex
              gap={16}
              flexWrap="wrap"
              justifyContent="space-between"
              className="mb-4"
            >
              <Heading.h5 weight={800}>Artboards </Heading.h5>
              <Link href={getHref('artboards')}>
                <Text.span color="var(--primary-theme)" weight={700}>
                  View All
                </Text.span>
              </Link>
            </Flex>
            <ArtBoards query={searchQuery} bare limit={6} />
          </div>
          <div>
            <Flex
              gap={16}
              flexWrap="wrap"
              justifyContent="space-between"
              className="mb-4"
            >
              <Heading.h5 weight={800}>Collections </Heading.h5>
              <Link href={getHref('collections')}>
                <Text.span color="var(--primary-theme)" weight={700}>
                  View All
                </Text.span>
              </Link>
            </Flex>
            <Collections query={searchQuery} bare limit={8} />
          </div>
        </Flex.Column>
      )}

      {currentTab === 'collections' && (
        <Collections query={searchQuery} isSearch />
      )}

      {currentTab === 'assets' && <Assets query={searchQuery} isSearch />}
      {currentTab === 'artboards' && <ArtBoards query={searchQuery} isSearch />}
    </div>
  );
};
